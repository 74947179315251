export const listingFieldTypes = [
  {option: 'not-specified',label: 'Not Specified',parentSubCategory: ['toys','baby-gear','small-animal-supplies','bird-supplies','fish-aquariums','cat-supplies','dog-supplies','beauty','health-care','crafting','games','memorabilia','antiques','art','handbags-bags', 'jewelry', 'accessories',  'shoes',  'mens-clothing',  'womens-clothing',  'watch-parts-tools',  'watch-accessories',  'watches',  'furniture',  'cleaning-tools-supplies',  'storage-organisation',  'home-appliances',  'home-decor',  'phone-accessories',  'kitchen-dining',  'smart-home',  'accessories',],
  },
  {option: 'phone-cases-covers',label: 'Phone Cases & Covers',parentSubCategory: ['phone-accessories'],
  },
  {option: 'chargers-docks',label: 'Chargers & Docks',parentSubCategory: ['phone-accessories'],
  },
  {option: 'cables-adapters',label: 'Cables & Adapters',parentSubCategory: ['phone-accessories'],
  },
  {option: 'mounts-holders',label: 'Mounts & Holders',parentSubCategory: ['phone-accessories'],
  },
  {option: 'headsets',label: 'Headsets',parentSubCategory: ['phone-accessories'],
  },
  {option: 'armbands',label: 'Armbands',parentSubCategory: ['phone-accessories'],
  },
  {option: 'styluses',label: 'Styluses',parentSubCategory: ['phone-accessories'],
  },
  {option: 'memory-card-readers',label: 'Memory Card Readers',parentSubCategory: ['phone-accessories'],
  },
  {option: 'signal-boosters',label: 'Signal Boosters',parentSubCategory: ['phone-accessories'],
  },
  {option: 'repair-kits',label: 'Repair Kits',parentSubCategory: ['phone-accessories'],
  },
  {option: 'fm-transmitters',label: 'FM Transmitters',parentSubCategory: ['phone-accessories'],
  },
  {option: 'selfie-sticks',label: 'Selfie Sticks',parentSubCategory: ['phone-accessories'],
  },
  {option: 'phone-charms',label: 'Phone Charms',parentSubCategory: ['phone-accessories'],
  },
  {option: 'replacement-parts',label: 'Replacement Parts',parentSubCategory: ['phone-accessories'],
  },
  {option: 'mobile-phone-lenses',label: 'Mobile Phone Lenses',parentSubCategory: ['phone-accessories'],
  },
  {option: 'phone-straps',label: 'Phone Straps',parentSubCategory: ['phone-accessories'],
  },
  {option: 'sim-card-tools',label: 'SIM Card Tools',parentSubCategory: ['phone-accessories'],
  },
  {option: 'cookware',label: 'Cookware',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'bakeware',label: 'Bakeware',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-tools-&-gadgets',label: 'Kitchen Tools & Gadgets',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-storage-&-organization',label: 'Kitchen Storage & Organization',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'tableware',label: 'Tableware',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'drinkware',label: 'Drinkware',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'dinnerware-&-serving-dishes',label: 'Dinnerware & Serving Dishes',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-linens-&-textiles',label: 'Kitchen Linens & Textiles',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'small-kitchen-appliances',label: 'Small Kitchen Appliances',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-sinks-&-taps',label: 'Kitchen Sinks & Taps',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-furniture',label: 'Kitchen Furniture',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-lighting',label: 'Kitchen Lighting',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-décor',label: 'Kitchen Décor',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-cleaning-&-organization',label: 'Kitchen Cleaning & Organization',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-electricals',label: 'Kitchen Electricals',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-utensils-&-sets',label: 'Kitchen Utensils & Sets',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-accessories',label: 'Kitchen Accessories',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-knives-&-cutlery',label: 'Kitchen Knives & Cutlery',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-chopping-boards-&-blocks',label: 'Kitchen Chopping Boards & Blocks',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'kitchen-food-preparation-&-tools',label: 'Kitchen Food Preparation & Tools',parentSubCategory: ['kitchen-dining', 'smart-home'],
  },
  {option: 'wall-art-&-decor',label: 'Wall Art & Decor',parentSubCategory: ['home-decor'],
  },
  {option: 'mirrors',label: 'Mirrors',parentSubCategory: ['home-decor'],
  },
  {option: 'clocks',label: 'Clocks',parentSubCategory: ['home-decor'],
  },
  {option: 'decorative-cushions-&-pillows',label: 'Decorative Cushions & Pillows',parentSubCategory: ['home-decor'],
  },
  {option: 'rugs-&-carpets',label: 'Rugs & Carpets',parentSubCategory: ['home-decor'],
  },
  {option: 'curtains,-drapes-&-valances',label: 'Curtains, Drapes & Valances',parentSubCategory: ['home-decor'],
  },
  {option: 'window-blinds-&-shades',label: 'Window Blinds & Shades',parentSubCategory: ['home-decor'],
  },
  {option: 'decorative-throws-&-blankets',label: 'Decorative Throws & Blankets',parentSubCategory: ['home-decor'],
  },
  {option: 'decorative-vases,-bowls-&-jars',label: 'Decorative Vases, Bowls & Jars',parentSubCategory: ['home-decor'],
  },
  {option: 'artificial-plants-&-flowers',label: 'Artificial Plants & Flowers',parentSubCategory: ['home-decor'],
  },
  {option: 'candles-&-candle-holders',label: 'Candles & Candle Holders',parentSubCategory: ['home-decor'],
  },
  {option: 'decorative-figurines-&-sculptures',label: 'Decorative Figurines & Sculptures',parentSubCategory: ['home-decor'],
  },
  {option: 'decorative-trays-&-platters',label: 'Decorative Trays & Platters',parentSubCategory: ['home-decor'],
  },
  {option: 'decorative-boxes-&-baskets',label: 'Decorative Boxes & Baskets',parentSubCategory: ['home-decor'],
  },
  {option: 'photo-frames-&-albums',label: 'Photo Frames & Albums',parentSubCategory: ['home-decor'],
  },
  {option: 'wall-shelves-&-ledges',label: 'Wall Shelves & Ledges',parentSubCategory: ['home-decor'],
  },
  {option: 'decorative-bookends',label: 'Decorative Bookends',parentSubCategory: ['home-decor'],
  },
  {option: 'decorative-signs-&-plaques',label: 'Decorative Signs & Plaques',parentSubCategory: ['home-decor'],
  },
  {option: 'decorative-ornaments-&-accents',label: 'Decorative Ornaments & Accents',parentSubCategory: ['home-decor'],
  },
  {option: 'seasonal-decorations',label: 'Seasonal Decorations',parentSubCategory: ['home-decor'],
  },
  {option: 'kitchen-appliances',label: 'Kitchen Appliances',parentSubCategory: ['home-appliances'],
  },
  {option: 'laundry-appliances',label: 'Laundry Appliances',parentSubCategory: ['home-appliances'],
  },
  {option: 'refrigerators-&-freezers',label: 'Refrigerators & Freezers',parentSubCategory: ['home-appliances'],
  },
  {option: 'dishwashers',label: 'Dishwashers',parentSubCategory: ['home-appliances'],
  },
  {option: 'cooking-appliances',label: 'Cooking Appliances',parentSubCategory: ['home-appliances'],
  },
  {option: 'vacuum-cleaners',label: 'Vacuum Cleaners',parentSubCategory: ['home-appliances'],
  },
  {option: 'air-purifiers-&-humidifiers',label: 'Air Purifiers & Humidifiers',parentSubCategory: ['home-appliances'],
  },
  {option: 'irons-&-ironing-boards',label: 'Irons & Ironing Boards',parentSubCategory: ['home-appliances'],
  },
  {option: 'small-kitchen-appliances',label: 'Small Kitchen Appliances',parentSubCategory: ['home-appliances'],
  },
  {option: 'food-processors-&-blenders',label: 'Food Processors & Blenders',parentSubCategory: ['home-appliances'],
  },
  {option: 'coffee-machines-&-accessories',label: 'Coffee Machines & Accessories',parentSubCategory: ['home-appliances'],
  },
  {option: 'toasters-&-toaster-ovens',label: 'Toasters & Toaster Ovens',parentSubCategory: ['home-appliances'],
  },
  {option: 'kettles-&-hot-water-dispensers',label: 'Kettles & Hot Water Dispensers',parentSubCategory: ['home-appliances'],
  },
  {option: 'microwave-ovens',label: 'Microwave Ovens',parentSubCategory: ['home-appliances'],
  },
  {option: 'slow-cookers-&-pressure-cookers',label: 'Slow Cookers & Pressure Cookers',parentSubCategory: ['home-appliances'],
  },
  {option: 'juicers-&-fruit-extractors',label: 'Juicers & Fruit Extractors',parentSubCategory: ['home-appliances'],
  },
  {option: 'bread-makers',label: 'Bread Makers',parentSubCategory: ['home-appliances'],
  },
  {option: 'ice-cream-&-frozen-yoghurt-makers',label: 'Ice Cream & Frozen Yoghurt Makers',parentSubCategory: ['home-appliances'],
  },
  {option: 'deep-fryers',label: 'Deep Fryers',parentSubCategory: ['home-appliances'],
  },
  {option: 'rice-cookers-&-steamers',label: 'Rice Cookers & Steamers',parentSubCategory: ['home-appliances'],
  },
  {option: 'storage-boxes-&-bins',label: 'Storage Boxes & Bins',parentSubCategory: ['storage-organisation'],
  },
  {option: 'closet-organizers-&-garment-racks',label: 'Closet Organizers & Garment Racks',parentSubCategory: ['storage-organisation'],
  },
  {option: 'shelving-units-&-storage-racks',label: 'Shelving Units & Storage Racks',parentSubCategory: ['storage-organisation'],
  },
  {option: 'storage-bags',label: 'Storage Bags',parentSubCategory: ['storage-organisation'],
  },
  {option: 'storage-baskets-&-hampers',label: 'Storage Baskets & Hampers',parentSubCategory: ['storage-organisation'],
  },
  {option: 'storage-drawers-&-carts',label: 'Storage Drawers & Carts',parentSubCategory: ['storage-organisation'],
  },
  {option: 'storage-cabinets-&-cupboards',label: 'Storage Cabinets & Cupboards',parentSubCategory: ['storage-organisation'],
  },
  {option: 'storage-trunks-&-chests',label: 'Storage Trunks & Chests',parentSubCategory: ['storage-organisation'],
  },
  {option: 'storage-benches-&-ottomans',label: 'Storage Benches & Ottomans',parentSubCategory: ['storage-organisation'],
  },
  {option: 'kitchen-storage-&-organization',label: 'Kitchen Storage & Organization',parentSubCategory: ['storage-organisation'],
  },
  {option: 'bathroom-storage-&-organization',label: 'Bathroom Storage & Organization',parentSubCategory: ['storage-organisation'],
  },
  {option: 'office-storage-&-organization',label: 'Office Storage & Organization',parentSubCategory: ['storage-organisation'],
  },
  {option: 'garage-storage-&-organization',label: 'Garage Storage & Organization',parentSubCategory: ['storage-organisation'],
  },
  {option: 'laundry-storage-&-organization',label: 'Laundry Storage & Organization',parentSubCategory: ['storage-organisation'],
  },
  {option: "kids'-storage-&-organization",label: "Kids' Storage & Organization",parentSubCategory: ['storage-organisation'],
  },
  {option: 'toy-boxes-&-organizers',label: 'Toy Boxes & Organizers',parentSubCategory: ['storage-organisation'],
  },
  {option: 'craft-storage-&-organization',label: 'Craft Storage & Organization',parentSubCategory: ['storage-organisation'],
  },
  {option: 'shoe-storage-&-organizers',label: 'Shoe Storage & Organizers',parentSubCategory: ['storage-organisation'],
  },
  {option: 'closet-systems-&-sets',label: 'Closet Systems & Sets',parentSubCategory: ['storage-organisation'],
  },
  {option: 'pantry-storage-&-organization',label: 'Pantry Storage & Organization',parentSubCategory: ['storage-organisation'],
  },
  {option: 'cleaning-brushes',label: 'Cleaning Brushes',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'mops-&-brooms',label: 'Mops & Brooms',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'vacuum-cleaners',label: 'Vacuum Cleaners',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'cleaning-cloths-&-wipes',label: 'Cleaning Cloths & Wipes',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'cleaning-gloves',label: 'Cleaning Gloves',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'sponges-&-scouring-pads',label: 'Sponges & Scouring Pads',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'cleaning-buckets-&-pails',label: 'Cleaning Buckets & Pails',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'cleaning-caddies-&-totes',label: 'Cleaning Caddies & Totes',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'cleaning-sprays-&-solutions',label: 'Cleaning Sprays & Solutions',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'floor-cleaners-&-polish',label: 'Floor Cleaners & Polish',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'bathroom-cleaners',label: 'Bathroom Cleaners',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'kitchen-cleaners',label: 'Kitchen Cleaners',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'glass-cleaners',label: 'Glass Cleaners',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'all-purpose-cleaners',label: 'All-Purpose Cleaners',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'disinfectants-&-sanitizers',label: 'Disinfectants & Sanitizers',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'odor-eliminators-&-air-fresheners',label: 'Odor Eliminators & Air Fresheners',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'drain-cleaners-&-unblockers',label: 'Drain Cleaners & Unblockers',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'dusters-&-dusting-supplies',label: 'Dusters & Dusting Supplies',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'cleaning-tools-&-tool-sets',label: 'Cleaning Tools & Tool Sets',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'cleaning-accessories-&-attachments',label: 'Cleaning Accessories & Attachments',parentSubCategory: ['cleaning-tools-supplies'],
  },
  {option: 'sofas,-armchairs-&-suites',label: 'Sofas, Armchairs & Suites',parentSubCategory: ['furniture'],
  },
  {option: 'tables',label: 'Tables',parentSubCategory: ['furniture'],
  },
  {option: 'chairs',label: 'Chairs',parentSubCategory: ['furniture'],
  },
  {option: 'beds-&-mattresses',label: 'Beds & Mattresses',parentSubCategory: ['furniture'],
  },
  {option: 'wardrobes',label: 'Wardrobes',parentSubCategory: ['furniture'],
  },
  {option: 'cabinets-&-cupboards',label: 'Cabinets & Cupboards',parentSubCategory: ['furniture'],
  },
  {option: 'dressers-&-chests-of-drawers',label: 'Dressers & Chests of Drawers',parentSubCategory: ['furniture'],
  },
  {option: 'bookcases,-shelving-&-storage',label: 'Bookcases, Shelving & Storage',parentSubCategory: ['furniture'],
  },
  {option: 'desks-&-computer-furniture',label: 'Desks & Computer Furniture',parentSubCategory: ['furniture'],
  },
  {option: 'dining-furniture-sets',label: 'Dining Furniture Sets',parentSubCategory: ['furniture'],
  },
  {option: 'sideboards-&-buffets',label: 'Sideboards & Buffets',parentSubCategory: ['furniture'],
  },
  {option: 'tv-stands-&-entertainment-units',label: 'TV Stands & Entertainment Units',parentSubCategory: ['furniture'],
  },
  {option: 'ottomans,-footstools-&-poufs',label: 'Ottomans, Footstools & Poufs',parentSubCategory: ['furniture'],
  },
  {option: 'benches',label: 'Benches',parentSubCategory: ['furniture'],
  },
  {option: 'room-dividers-&-screens',label: 'Room Dividers & Screens',parentSubCategory: ['furniture'],
  },
  {option: 'vanity-units-&-sets',label: 'Vanity Units & Sets',parentSubCategory: ['furniture'],
  },
  {option: 'bar-stools-&-breakfast-bars',label: 'Bar Stools & Breakfast Bars',parentSubCategory: ['furniture'],
  },
  {option: 'bathroom-cabinets-&-storage',label: 'Bathroom Cabinets & Storage',parentSubCategory: ['furniture'],
  },
  {option: 'garden-&-patio-furniture',label: 'Garden & Patio Furniture',parentSubCategory: ['furniture'],
  },
  {option: "children's-furniture",label: "Children's Furniture",parentSubCategory: ['furniture'],
  },
  {option: 'wristwatches',label: 'Wristwatches',parentSubCategory: ['watches'],
  },
  {option: 'smartwatches',label: 'Smartwatches',parentSubCategory: ['watches'],
  },
  {option: 'pocket-watches',label: 'Pocket Watches',parentSubCategory: ['watches'],
  },
  {option: 'watch-bands',label: 'Watch Bands',parentSubCategory: ['watches'],
  },
  {option: 'watch-boxes,-cases-&-winders',label: 'Watch Boxes, Cases & Winders',parentSubCategory: ['watches'],
  },
  {option: 'watch-parts,-tools-&-guides',label: 'Watch Parts, Tools & Guides',parentSubCategory: ['watches'],
  },
  {option: 'vintage-watches',label: 'Vintage Watches',parentSubCategory: ['watches'],
  },
  {option: 'luxury-watches',label: 'Luxury Watches',parentSubCategory: ['watches'],
  },
  {option: 'sports-watches',label: 'Sports Watches',parentSubCategory: ['watches'],
  },
  {option: 'fashion-watches',label: 'Fashion Watches',parentSubCategory: ['watches'],
  },
  {option: 'casual-watches',label: 'Casual Watches',parentSubCategory: ['watches'],
  },
  {option: 'dress-watches',label: 'Dress Watches',parentSubCategory: ['watches'],
  },
  {option: 'military-watches',label: 'Military Watches',parentSubCategory: ['watches'],
  },
  {option: 'diving-watches',label: 'Diving Watches',parentSubCategory: ['watches'],
  },
  {option: 'fitness-trackers',label: 'Fitness Trackers',parentSubCategory: ['watches'],
  },
  {option: 'gps-&-running-watches',label: 'GPS & Running Watches',parentSubCategory: ['watches'],
  },
  {option: 'chronograph-watches',label: 'Chronograph Watches',parentSubCategory: ['watches'],
  },
  {option: 'automatic-watches',label: 'Automatic Watches',parentSubCategory: ['watches'],
  },
  {option: 'quartz-watches',label: 'Quartz Watches',parentSubCategory: ['watches'],
  },
  {option: 'mechanical-watches',label: 'Mechanical Watches',parentSubCategory: ['watches'],
  },
  {option: 'watch-bands',label: 'Watch Bands',parentSubCategory: ['watch-accessories'],
  },
  {option: 'watch-straps',label: 'Watch Straps',parentSubCategory: ['watch-accessories'],
  },
  {option: 'watch-bezels',label: 'Watch Bezels',parentSubCategory: ['watch-accessories'],
  },
  {option: 'watch-buckles-&-clasps',label: 'Watch Buckles & Clasps',parentSubCategory: ['watch-accessories'],
  },
  {option: 'watch-cases-&-boxes',label: 'Watch Cases & Boxes',parentSubCategory: ['watch-accessories'],
  },
  {option: 'watch-winders',label: 'Watch Winders',parentSubCategory: ['watch-accessories'],
  },
  {option: 'watch-display-stands',label: 'Watch Display Stands',parentSubCategory: ['watch-accessories'],
  },
  {option: 'watch-repair-tools',label: 'Watch Repair Tools',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-movements',label: 'Watch Movements',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-crystals',label: 'Watch Crystals',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-hands',label: 'Watch Hands',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-bezels',label: 'Watch Bezels',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-crowns',label: 'Watch Crowns',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-case-openers',label: 'Watch Case Openers',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-band-tools',label: 'Watch Band Tools',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-spring-bars',label: 'Watch Spring Bars',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-gaskets',label: 'Watch Gaskets',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-pins-&-screws',label: 'Watch Pins & Screws',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-oil-&-lubricants',label: 'Watch Oil & Lubricants',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-testing-&-measuring-tools',label: 'Watch Testing & Measuring Tools',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-cleaning-kits',label: 'Watch Cleaning Kits',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-parts-assortments',label: 'Watch Parts Assortments',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-dial-refinishing-kits',label: 'Watch Dial Refinishing Kits',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-polishing-&-buffing-tools',label: 'Watch Polishing & Buffing Tools',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-demagnetizers',label: 'Watch Demagnetizers',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-hairsprings',label: 'Watch Hairsprings',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'watch-balance-wheels',label: 'Watch Balance Wheels',parentSubCategory: ['watch-parts-tools'],
  },
  {option: 'dresses',label: 'Dresses',parentSubCategory: ['womens-clothing'],
  },
  {option: 'tops-&-blouses',label: 'Tops & Blouses',parentSubCategory: ['womens-clothing'],
  },
  {option: 'trousers',label: 'Trousers',parentSubCategory: ['womens-clothing'],
  },
  {option: 'skirts',label: 'Skirts',parentSubCategory: ['womens-clothing'],
  },
  {option: 'jeans',label: 'Jeans',parentSubCategory: ['womens-clothing'],
  },
  {option: 'jumpsuits-&-playsuits',label: 'Jumpsuits & Playsuits',parentSubCategory: ['womens-clothing'],
  },
  {option: 'coats-&-jackets',label: 'Coats & Jackets',parentSubCategory: ['womens-clothing'],
  },
  {option: 'blazers-&-suit-jackets',label: 'Blazers & Suit Jackets',parentSubCategory: ['womens-clothing'],
  },
  {option: 'knitwear',label: 'Knitwear',parentSubCategory: ['womens-clothing'],
  },
  {option: 'hoodies-&-sweatshirts',label: 'Hoodies & Sweatshirts',parentSubCategory: ['womens-clothing'],
  },
  {option: 'swimwear',label: 'Swimwear',parentSubCategory: ['womens-clothing'],
  },
  {option: 'lingerie-&-underwear',label: 'Lingerie & Underwear',parentSubCategory: ['womens-clothing'],
  },
  {option: 'maternity-clothing',label: 'Maternity Clothing',parentSubCategory: ['womens-clothing'],
  },
  {option: 'plus-size-clothing',label: 'Plus Size Clothing',parentSubCategory: ['womens-clothing'],
  },
  {option: 'petite-clothing',label: 'Petite Clothing',parentSubCategory: ['womens-clothing'],
  },
  {option: 'activewear-&-sportswear',label: 'Activewear & Sportswear',parentSubCategory: ['womens-clothing'],
  },
  {option: 'workwear-&-office-attire',label: 'Workwear & Office Attire',parentSubCategory: ['womens-clothing'],
  },
  {option: 'partywear-&-evening-dresses',label: 'Partywear & Evening Dresses',parentSubCategory: ['womens-clothing'],
  },
  {option: 'wedding-&-bridal-wear',label: 'Wedding & Bridal Wear',parentSubCategory: ['womens-clothing'],
  },
  {option: 'ethnic-&-cultural-clothing',label: 'Ethnic & Cultural Clothing',parentSubCategory: ['womens-clothing'],
  },
  {option: 't-shirts-&-tops',label: 'T-Shirts & Tops',parentSubCategory: ['mens-clothing'],
  },
  {option: 'shirts',label: 'Shirts',parentSubCategory: ['mens-clothing'],
  },
  {option: 'trousers',label: 'Trousers',parentSubCategory: ['mens-clothing'],
  },
  {option: 'jeans',label: 'Jeans',parentSubCategory: ['mens-clothing'],
  },
  {option: 'jumpers-&-cardigans',label: 'Jumpers & Cardigans',parentSubCategory: ['mens-clothing'],
  },
  {option: 'coats-&-jackets',label: 'Coats & Jackets',parentSubCategory: ['mens-clothing'],
  },
  {option: 'blazers-&-suits',label: 'Blazers & Suits',parentSubCategory: ['mens-clothing'],
  },
  {option: 'hoodies-&-sweatshirts',label: 'Hoodies & Sweatshirts',parentSubCategory: ['mens-clothing'],
  },
  {option: 'shorts',label: 'Shorts',parentSubCategory: ['mens-clothing'],
  },
  {option: 'swimwear',label: 'Swimwear',parentSubCategory: ['mens-clothing'],
  },
  {option: 'underwear',label: 'Underwear',parentSubCategory: ['mens-clothing'],
  },
  {option: 'socks',label: 'Socks',parentSubCategory: ['mens-clothing'],
  },
  {option: 'activewear-&-sportswear',label: 'Activewear & Sportswear',parentSubCategory: ['mens-clothing'],
  },
  {option: 'sleepwear-&-pyjamas',label: 'Sleepwear & Pyjamas',parentSubCategory: ['mens-clothing'],
  },
  {option: 'big-&-tall-clothing',label: 'Big & Tall Clothing',parentSubCategory: ['mens-clothing'],
  },
  {option: 'vintage-clothing',label: 'Vintage Clothing',parentSubCategory: ['mens-clothing'],
  },
  {option: 'workwear-&-safety-clothing',label: 'Workwear & Safety Clothing',parentSubCategory: ['mens-clothing'],
  },
  {option: 'formalwear-&-occasion-wear',label: 'Formalwear & Occasion Wear',parentSubCategory: ['mens-clothing'],
  },
  {option: 'ethnic-&-cultural-clothing',label: 'Ethnic & Cultural Clothing',parentSubCategory: ['mens-clothing'],
  },
  {option: 'accessories',label: 'Accessories',parentSubCategory: ['mens-clothing'],
  },
  {option: "men's-shoes",label: "Men's Shoes",parentSubCategory: ['shoes'],
  },
  {option: "women's-shoes",label: "Women's Shoes",parentSubCategory: ['shoes'],
  },
  {option: "kids'-shoes",label: "Kids' Shoes",parentSubCategory: ['shoes'],
  },
  {option: 'athletic-shoes',label: 'Athletic Shoes',parentSubCategory: ['shoes'],
  },
  {option: 'casual-shoes',label: 'Casual Shoes',parentSubCategory: ['shoes'],
  },
  {option: 'boots',label: 'Boots',parentSubCategory: ['shoes'],
  },
  {option: 'sandals-&-flip-flops',label: 'Sandals & Flip Flops',parentSubCategory: ['shoes'],
  },
  {option: 'slippers',label: 'Slippers',parentSubCategory: ['shoes'],
  },
  {option: 'dress-shoes',label: 'Dress Shoes',parentSubCategory: ['shoes'],
  },
  {option: 'sneakers',label: 'Sneakers',parentSubCategory: ['shoes'],
  },
  {option: 'trainers',label: 'Trainers',parentSubCategory: ['shoes'],
  },
  {option: 'loafers-&-moccasins',label: 'Loafers & Moccasins',parentSubCategory: ['shoes'],
  },
  {option: 'flats',label: 'Flats',parentSubCategory: ['shoes'],
  },
  {option: 'heels',label: 'Heels',parentSubCategory: ['shoes'],
  },
  {option: 'wedges',label: 'Wedges',parentSubCategory: ['shoes'],
  },
  {option: 'espadrilles',label: 'Espadrilles',parentSubCategory: ['shoes'],
  },
  {option: 'work-&-safety-shoes',label: 'Work & Safety Shoes',parentSubCategory: ['shoes'],
  },
  {option: 'dance-shoes',label: 'Dance Shoes',parentSubCategory: ['shoes'],
  },
  {option: 'golf-shoes',label: 'Golf Shoes',parentSubCategory: ['shoes'],
  },
  {option: 'skate-shoes',label: 'Skate Shoes',parentSubCategory: ['shoes'],
  },
  {option: 'wallets',label: 'Wallets',parentSubCategory: ['accessories'],
  },
  {option: 'belts',label: 'Belts',parentSubCategory: ['accessories'],
  },
  {option: 'hats-&-caps',label: 'Hats & Caps',parentSubCategory: ['accessories'],
  },
  {option: 'scarves-&-shawls',label: 'Scarves & Shawls',parentSubCategory: ['accessories'],
  },
  {option: 'gloves',label: 'Gloves',parentSubCategory: ['accessories'],
  },
  {option: 'sunglasses',label: 'Sunglasses',parentSubCategory: ['accessories'],
  },
  {option: 'umbrellas',label: 'Umbrellas',parentSubCategory: ['accessories'],
  },
  {option: 'hair-accessories',label: 'Hair Accessories',parentSubCategory: ['accessories'],
  },
  {option: 'ties-&-cravats',label: 'Ties & Cravats',parentSubCategory: ['accessories'],
  },
  {option: 'bow-ties-&-cummerbunds',label: 'Bow Ties & Cummerbunds',parentSubCategory: ['accessories'],
  },
  {option: 'suspenders-&-braces',label: 'Suspenders & Braces',parentSubCategory: ['accessories'],
  },
  {option: 'pocket-squares',label: 'Pocket Squares',parentSubCategory: ['accessories'],
  },
  {option: 'keychains-&-keyrings',label: 'Keychains & Keyrings',parentSubCategory: ['accessories'],
  },
  {option: 'cufflinks-&-tie-clips',label: 'Cufflinks & Tie Clips',parentSubCategory: ['accessories'],
  },
  {option: 'handkerchiefs',label: 'Handkerchiefs',parentSubCategory: ['accessories'],
  },
  {option: 'bandanas',label: 'Bandanas',parentSubCategory: ['accessories'],
  },
  {option: 'ear-muffs',label: 'Ear Muffs',parentSubCategory: ['accessories'],
  },
  {option: 'face-masks-&-face-coverings',label: 'Face Masks & Face Coverings',parentSubCategory: ['accessories'],
  },
  {"option": "rings", "label": "Rings",parentSubCategory: ['jewelry']},
  {"option": "necklaces-pendants", "label": "Necklaces & Pendants",parentSubCategory: ['jewelry']},
  {"option": "earrings", "label": "Earrings",parentSubCategory: ['jewelry']},
  {"option": "bracelets", "label": "Bracelets",parentSubCategory: ['jewelry']},
  {"option": "watches", "label": "Watches",parentSubCategory: ['jewelry']},
  {"option": "brooches-pins", "label": "Brooches & Pins",parentSubCategory: ['jewelry']},
  {"option": "charms-charm-bracelets", "label": "Charms & Charm Bracelets",parentSubCategory: ['jewelry']},
  {"option": "body-jewelry", "label": "Body Jewelry",parentSubCategory: ['jewelry']},
  {"option": "jewelry-sets", "label": "Jewelry Sets",parentSubCategory: ['jewelry']},
  {"option": "loose-gemstones", "label": "Loose Gemstones",parentSubCategory: ['jewelry']},
  {"option": "engagement-wedding-jewelry", "label": "Engagement & Wedding Jewelry",parentSubCategory: ['jewelry']},
  {"option": "fashion-jewelry", "label": "Fashion Jewelry",parentSubCategory: ['jewelry']},
  {"option": "fine-jewelry", "label": "Fine Jewelry",parentSubCategory: ['jewelry']},
  {"option": "vintage-antique-jewelry", "label": "Vintage & Antique Jewelry",parentSubCategory: ['jewelry']},
  {"option": "mens-jewelry", "label": "Men's Jewelry",parentSubCategory: ['jewelry']},
  {"option": "childrens-jewelry", "label": "Children's Jewelry",parentSubCategory: ['jewelry']},
  {"option": "religious-jewelry", "label": "Religious Jewelry",parentSubCategory: ['jewelry']},
  {"option": "personalized-jewelry", "label": "Personalized Jewelry",parentSubCategory: ['jewelry']},
  {"option": "handmade-jewelry", "label": "Handmade Jewelry",parentSubCategory: ['jewelry']},
  {"option": "costume-jewelry", "label": "Costume Jewelry",parentSubCategory: ['jewelry']},
  {"option": "tote-bags", "label": "Tote Bags",parentSubCategory: ['handbags-bags']},
  {"option": "shoulder-bags", "label": "Shoulder Bags",parentSubCategory: ['handbags-bags']},
  {"option": "crossbody-bags", "label": "Crossbody Bags",parentSubCategory: ['handbags-bags']},
  {"option": "clutch-bags", "label": "Clutch Bags",parentSubCategory: ['handbags-bags']},
  {"option": "backpacks", "label": "Backpacks",parentSubCategory: ['handbags-bags']},
  {"option": "messenger-bags", "label": "Messenger Bags",parentSubCategory: ['handbags-bags']},
  {"option": "satchel-bags", "label": "Satchel Bags",parentSubCategory: ['handbags-bags']},
  {"option": "hobo-bags", "label": "Hobo Bags",parentSubCategory: ['handbags-bags']},
  {"option": "evening-bags", "label": "Evening Bags",parentSubCategory: ['handbags-bags']},
  {"option": "wallets-purses", "label": "Wallets & Purses",parentSubCategory: ['handbags-bags']},
  {"option": "travel-bags-luggage", "label": "Travel Bags & Luggage",parentSubCategory: ['handbags-bags']},
  {"option": "duffel-bags", "label": "Duffel Bags",parentSubCategory: ['handbags-bags']},
  {"option": "gym-bags", "label": "Gym Bags",parentSubCategory: ['handbags-bags']},
  {"option": "laptop-bags-cases", "label": "Laptop Bags & Cases",parentSubCategory: ['handbags-bags']},
  {"option": "briefcases", "label": "Briefcases",parentSubCategory: ['handbags-bags']},
  {"option": "waist-packs-belt-bags", "label": "Waist Packs & Belt Bags",parentSubCategory: ['handbags-bags']},
  {"option": "beach-bags", "label": "Beach Bags",parentSubCategory: ['handbags-bags']},
  {"option": "drawstring-bags", "label": "Drawstring Bags",parentSubCategory: ['handbags-bags']},
  {"option": "shopping-bags", "label": "Shopping Bags",parentSubCategory: ['handbags-bags']},
    {"option": "paintings", "label": "Paintings",parentSubCategory: ['art']},
    {"option": "prints", "label": "Prints",parentSubCategory: ['art']},
    {"option": "photographs", "label": "Photographs",parentSubCategory: ['art']},
    {"option": "drawings", "label": "Drawings",parentSubCategory: ['art']},
    {"option": "sculptures", "label": "Sculptures",parentSubCategory: ['art']},
    {"option": "mixed-media-art", "label": "Mixed Media Art",parentSubCategory: ['art']},
    {"option": "collage-art", "label": "Collage Art",parentSubCategory: ['art']},
    {"option": "digital-art", "label": "Digital Art",parentSubCategory: ['art']},
    {"option": "folk-art", "label": "Folk Art",parentSubCategory: ['art']},
    {"option": "outsider-art", "label": "Outsider Art",parentSubCategory: ['art']},
    {"option": "street-art", "label": "Street Art",parentSubCategory: ['art']},
    {"option": "abstract-art", "label": "Abstract Art",parentSubCategory: ['art']},
    {"option": "landscape-art", "label": "Landscape Art",parentSubCategory: ['art']},
    {"option": "portrait-art", "label": "Portrait Art",parentSubCategory: ['art']},
    {"option": "still-life-art", "label": "Still Life Art",parentSubCategory: ['art']},
    {"option": "contemporary-art", "label": "Contemporary Art",parentSubCategory: ['art']},
    {"option": "modern-art", "label": "Modern Art",parentSubCategory: ['art']},
    {"option": "traditional-art", "label": "Traditional Art",parentSubCategory: ['art']},
    {"option": "illustrations", "label": "Illustrations",parentSubCategory: ['art']},
    {"option": "art-supplies", "label": "Art Supplies",parentSubCategory: ['art']},
    {"option": "furniture", "label": "Furniture",parentSubCategory: ['antiques']},
    {"option": "decorative-arts", "label": "Decorative Arts",parentSubCategory: ['antiques']},
    {"option": "ceramics", "label": "Ceramics",parentSubCategory: ['antiques']},
    {"option": "glass", "label": "Glass",parentSubCategory: ['antiques']},
    {"option": "silver", "label": "Silver",parentSubCategory: ['antiques']},
    {"option": "jewelry", "label": "Jewelry",parentSubCategory: ['antiques']},
    {"option": "clocks", "label": "Clocks",parentSubCategory: ['antiques']},
    {"option": "lighting", "label": "Lighting",parentSubCategory: ['antiques']},
    {"option": "textiles-linens", "label": "Textiles & Linens",parentSubCategory: ['antiques']},
    {"option": "metalware", "label": "Metalware",parentSubCategory: ['antiques']},
    {"option": "maps", "label": "Maps",parentSubCategory: ['antiques']},
    {"option": "manuscripts", "label": "Manuscripts",parentSubCategory: ['antiques']},
    {"option": "books", "label": "Books",parentSubCategory: ['antiques']},
    {"option": "coins", "label": "Coins",parentSubCategory: ['antiques']},
    {"option": "stamps", "label": "Stamps",parentSubCategory: ['antiques']},
    {"option": "toys-games", "label": "Toys & Games",parentSubCategory: ['antiques']},
    {"option": "militaria", "label": "Militaria",parentSubCategory: ['antiques']},
    {"option": "scientific-instruments", "label": "Scientific Instruments",parentSubCategory: ['antiques']},
    {"option": "ethnographic", "label": "Ethnographic",parentSubCategory: ['antiques']},
    {"option": "archaeological", "label": "Archaeological",parentSubCategory: ['antiques']},
    {"option": "sports-memorabilia", "label": "Sports Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "entertainment-memorabilia", "label": "Entertainment Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "music-memorabilia", "label": "Music Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "movie-memorabilia", "label": "Movie Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "tv-memorabilia", "label": "TV Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "celebrity-memorabilia", "label": "Celebrity Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "historical-memorabilia", "label": "Historical Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "autographs", "label": "Autographs",parentSubCategory: ['memorabilia']},
    {"option": "political-memorabilia", "label": "Political Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "militaria", "label": "Militaria",parentSubCategory: ['memorabilia']},
    {"option": "collectible-advertising", "label": "Collectible Advertising",parentSubCategory: ['memorabilia']},
    {"option": "comic-books-&-memorabilia", "label": "Comic Books & Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "animation-art-&-characters", "label": "Animation Art & Characters",parentSubCategory: ['memorabilia']},
    {"option": "collectible-cards", "label": "Collectible Cards",parentSubCategory: ['memorabilia']},
    {"option": "collectible-figurines", "label": "Collectible Figurines",parentSubCategory: ['memorabilia']},
    {"option": "collectible-pins-&-buttons", "label": "Collectible Pins & Buttons",parentSubCategory: ['memorabilia']},
    {"option": "vintage-electronics-memorabilia", "label": "Vintage Electronics Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "space-memorabilia", "label": "Space Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "transportation-memorabilia", "label": "Transportation Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "circus-memorabilia", "label": "Circus Memorabilia",parentSubCategory: ['memorabilia']},
    {"option": "board-traditional-games", "label": "Board & Traditional Games",parentSubCategory: ['games']},
    {"option": "card-games-poker", "label": "Card Games & Poker",parentSubCategory: ['games']},
    {"option": "electronic-games", "label": "Electronic Games",parentSubCategory: ['games']},
    {"option": "miniatures-war-games", "label": "Miniatures & War Games",parentSubCategory: ['games']},
    {"option": "role-playing-games-rpgs", "label": "Role Playing Games (RPGs)",parentSubCategory: ['games']},
    {"option": "tile-games", "label": "Tile Games",parentSubCategory: ['games']},
    {"option": "family-games", "label": "Family Games",parentSubCategory: ['games']},
    {"option": "strategy-games", "label": "Strategy Games",parentSubCategory: ['games']},
    {"option": "party-games", "label": "Party Games",parentSubCategory: ['games']},
    {"option": "educational-games", "label": "Educational Games",parentSubCategory: ['games']},
    {"option": "mystery-deduction-games", "label": "Mystery & Deduction Games",parentSubCategory: ['games']},
    {"option": "word-games", "label": "Word Games",parentSubCategory: ['games']},
    {"option": "trivia-games", "label": "Trivia Games",parentSubCategory: ['games']},
    {"option": "cooperative-games", "label": "Cooperative Games",parentSubCategory: ['games']},
    {"option": "kids-games", "label": "Kids' Games",parentSubCategory: ['games']},
    {"option": "adult-games", "label": "Adult Games",parentSubCategory: ['games']},
    {"option": "abstract-strategy-games", "label": "Abstract Strategy Games",parentSubCategory: ['games']},
    {"option": "deck-building-games", "label": "Deck Building Games",parentSubCategory: ['games']},
    {"option": "collectible-card-games", "label": "Collectible Card Games",parentSubCategory: ['games']},
    {"option": "scrapbooking-paper-crafts", "label": "Scrapbooking & Paper Crafts",parentSubCategory: ['crafting']},
    {"option": "sewing", "label": "Sewing",parentSubCategory: ['crafting']},
    {"option": "knitting-crochet", "label": "Knitting & Crochet",parentSubCategory: ['crafting']},
    {"option": "beading-jewelry-making", "label": "Beading & Jewelry Making",parentSubCategory: ['crafting']},
    {"option": "painting-drawing-art-supplies", "label": "Painting, Drawing & Art Supplies",parentSubCategory: ['crafting']},
    {"option": "fabric", "label": "Fabric",parentSubCategory: ['crafting']},
    {"option": "yarn", "label": "Yarn",parentSubCategory: ['crafting']},
    {"option": "needlecrafts-yarn", "label": "Needlecrafts & Yarn",parentSubCategory: ['crafting']},
    {"option": "woodworking", "label": "Woodworking",parentSubCategory: ['crafting']},
    {"option": "floral-crafts", "label": "Floral Crafts",parentSubCategory: ['crafting']},
    {"option": "candle-making-soap-making", "label": "Candle Making & Soap Making",parentSubCategory: ['crafting']},
    {"option": "leathercraft", "label": "Leathercraft",parentSubCategory: ['crafting']},
    {"option": "glass-mosaics", "label": "Glass & Mosaics",parentSubCategory: ['crafting']},
    {"option": "multi-purpose-craft-supplies", "label": "Multi-Purpose Craft Supplies",parentSubCategory: ['crafting']},
    {"option": "kids-crafts", "label": "Kids' Crafts",parentSubCategory: ['crafting']},
    {"option": "stamping-embossing", "label": "Stamping & Embossing",parentSubCategory: ['crafting']},
    {"option": "ceramics-pottery", "label": "Ceramics & Pottery",parentSubCategory: ['crafting']},
    {"option": "dolls-bears-making", "label": "Dolls & Bears Making",parentSubCategory: ['crafting']},
    {"option": "metalworking", "label": "Metalworking",parentSubCategory: ['crafting']},
    {"option": "basketry", "label": "Basketry",parentSubCategory: ['crafting']},
    {"option": "health-monitors", "label": "Health Monitors",parentSubCategory: ['health-care']},
    {"option": "medical-equipment", "label": "Medical Equipment",parentSubCategory: ['health-care']},
    {"option": "mobility-disability-equipment", "label": "Mobility & Disability Equipment",parentSubCategory: ['health-care']},
    {"option": "medical-lab-dental-supplies", "label": "Medical, Lab & Dental Supplies",parentSubCategory: ['health-care']},
    {"option": "massage-equipment-accessories", "label": "Massage Equipment & Accessories",parentSubCategory: ['health-care']},
    {"option": "orthopedics-supports", "label": "Orthopedics & Supports",parentSubCategory: ['health-care']},
    {"option": "first-aid", "label": "First Aid",parentSubCategory: ['health-care']},
    {"option": "vision-care", "label": "Vision Care",parentSubCategory: ['health-care']},
    {"option": "dental-care", "label": "Dental Care",parentSubCategory: ['health-care']},
    {"option": "medical-furniture-mobility", "label": "Medical Furniture & Mobility",parentSubCategory: ['health-care']},
    {"option": "daily-living-aids", "label": "Daily Living Aids",parentSubCategory: ['health-care']},
    {"option": "homeopathic-remedies", "label": "Homeopathic Remedies",parentSubCategory: ['health-care']},
    {"option": "incontinence-aids", "label": "Incontinence Aids",parentSubCategory: ['health-care']},
    {"option": "mobility-furniture-fixtures", "label": "Mobility Furniture & Fixtures",parentSubCategory: ['health-care']},
    {"option": "pill-boxes-pill-cases", "label": "Pill Boxes, Pill Cases",parentSubCategory: ['health-care']},
    {"option": "hearing-assistance", "label": "Hearing Assistance",parentSubCategory: ['health-care']},
    {"option": "testing-sample-collection", "label": "Testing & Sample Collection",parentSubCategory: ['health-care']},
    {"option": "thermometers", "label": "Thermometers",parentSubCategory: ['health-care']},
    {"option": "braces-supports", "label": "Braces & Supports",parentSubCategory: ['health-care']},
    {"option": "stairlifts-home-lifts", "label": "Stairlifts & Home Lifts",parentSubCategory: ['health-care']},
    {"option": "skincare", "label": "Skincare",parentSubCategory: ['beauty']},
    {"option": "makeup", "label": "Makeup",parentSubCategory: ['beauty']},
    {"option": "hair-care", "label": "Hair Care",parentSubCategory: ['beauty']},
    {"option": "fragrances", "label": "Fragrances",parentSubCategory: ['beauty']},
    {"option": "bath-body", "label": "Bath & Body",parentSubCategory: ['beauty']},
    {"option": "nail-care-manicure-pedicure", "label": "Nail Care, Manicure & Pedicure",parentSubCategory: ['beauty']},
    {"option": "beauty-tools-accessories", "label": "Beauty Tools & Accessories",parentSubCategory: ['beauty']},
    {"option": "oral-care", "label": "Oral Care",parentSubCategory: ['beauty']},
    {"option": "sun-care-tanning", "label": "Sun Care & Tanning",parentSubCategory: ['beauty']},
    {"option": "personal-care-hygiene", "label": "Personal Care & Hygiene",parentSubCategory: ['beauty']},
    {"option": "mens-grooming", "label": "Men's Grooming",parentSubCategory: ['beauty']},
    {"option": "beauty-supplements-vitamins", "label": "Beauty Supplements & Vitamins",parentSubCategory: ['beauty']},
    {"option": "beauty-travel-cases-bags", "label": "Beauty Travel Cases & Bags",parentSubCategory: ['beauty']},
    {"option": "beauty-mirrors-magnifiers", "label": "Beauty Mirrors & Magnifiers",parentSubCategory: ['beauty']},
    {"option": "dog-food-treats", "label": "Dog Food & Treats",parentSubCategory: ['dog-supplies']},
    {"option": "dog-beds", "label": "Dog Beds",parentSubCategory: ['dog-supplies']},
    {"option": "dog-collars-harnesses-leashes", "label": "Dog Collars, Harnesses & Leashes",parentSubCategory: ['dog-supplies']},
    {"option": "dog-toys", "label": "Dog Toys",parentSubCategory: ['dog-supplies']},
    {"option": "dog-grooming-supplies", "label": "Dog Grooming Supplies",parentSubCategory: ['dog-supplies']},
    {"option": "dog-health-care-supplies", "label": "Dog Health Care Supplies",parentSubCategory: ['dog-supplies']},
    {"option": "dog-training-obedience-supplies", "label": "Dog Training & Obedience Supplies",parentSubCategory: ['dog-supplies']},
    {"option": "dog-apparel", "label": "Dog Apparel",parentSubCategory: ['dog-supplies']},
    {"option": "dog-feeding-watering-supplies", "label": "Dog Feeding & Watering Supplies",parentSubCategory: ['dog-supplies']},
    {"option": "dog-carriers-travel-products", "label": "Dog Carriers & Travel Products",parentSubCategory: ['dog-supplies']},
    {"option": "dog-doors-flaps", "label": "Dog Doors & Flaps",parentSubCategory: ['dog-supplies']},
    {"option": "dog-crates-kennels-pens", "label": "Dog Crates, Kennels & Pens",parentSubCategory: ['dog-supplies']},
    {"option": "dog-flea-tick-remedies", "label": "Dog Flea & Tick Remedies",parentSubCategory: ['dog-supplies']},
    {"option": "dog-pooper-scoopers-bags", "label": "Dog Pooper Scoopers & Bags",parentSubCategory: ['dog-supplies']},
    {"option": "dog-strollers", "label": "Dog Strollers",parentSubCategory: ['dog-supplies']},
    {"option": "dog-dental-care-products", "label": "Dog Dental Care Products",parentSubCategory: ['dog-supplies']},
    {"option": "dog-housebreaking-supplies", "label": "Dog Housebreaking Supplies",parentSubCategory: ['dog-supplies']},
    {"option": "dog-id-tags", "label": "Dog ID Tags",parentSubCategory: ['dog-supplies']},
    {"option": "dog-memorials", "label": "Dog Memorials",parentSubCategory: ['dog-supplies']},
    {"option": "dog-agility-training-equipment", "label": "Dog Agility Training Equipment",parentSubCategory: ['dog-supplies']},
    {"option": "cat-food-treats", "label": "Cat Food & Treats",parentSubCategory: ['cat-supplies']},
    {"option": "cat-beds-furniture", "label": "Cat Beds & Furniture",parentSubCategory: ['cat-supplies']},
    {"option": "cat-litter-boxes-accessories", "label": "Cat Litter Boxes & Accessories",parentSubCategory: ['cat-supplies']},
    {"option": "cat-toys", "label": "Cat Toys",parentSubCategory: ['cat-supplies']},
    {"option": "cat-collars-harnesses-leashes", "label": "Cat Collars, Harnesses & Leashes",parentSubCategory: ['cat-supplies']},
    {"option": "cat-grooming-supplies", "label": "Cat Grooming Supplies",parentSubCategory: ['cat-supplies']},
    {"option": "cat-health-care-supplies", "label": "Cat Health Care Supplies",parentSubCategory: ['cat-supplies']},
    {"option": "cat-scratching-posts-furniture", "label": "Cat Scratching Posts & Furniture",parentSubCategory: ['cat-supplies']},
    {"option": "cat-carriers-travel-products", "label": "Cat Carriers & Travel Products",parentSubCategory: ['cat-supplies']},
    {"option": "cat-feeding-watering-supplies", "label": "Cat Feeding & Watering Supplies",parentSubCategory: ['cat-supplies']},
    {"option": "cat-doors-flaps-steps", "label": "Cat Doors, Flaps & Steps",parentSubCategory: ['cat-supplies']},
    {"option": "cat-flea-tick-remedies", "label": "Cat Flea & Tick Remedies",parentSubCategory: ['cat-supplies']},
    {"option": "cat-dental-care-products", "label": "Cat Dental Care Products",parentSubCategory: ['cat-supplies']},
    {"option": "cat-memorials", "label": "Cat Memorials",parentSubCategory: ['cat-supplies']},
    {"option": "cat-id-tags", "label": "Cat ID Tags",parentSubCategory: ['cat-supplies']},
    {"option": "cat-litter", "label": "Cat Litter",parentSubCategory: ['cat-supplies']},
    {"option": "cat-supplements-vitamins", "label": "Cat Supplements & Vitamins",parentSubCategory: ['cat-supplies']},
    {"option": "cat-training-behavior-aids", "label": "Cat Training & Behavior Aids",parentSubCategory: ['cat-supplies']},
    {"option": "cat-apparel", "label": "Cat Apparel",parentSubCategory: ['cat-supplies']},
    {"option": "cat-relaxation-aids", "label": "Cat Relaxation Aids",parentSubCategory: ['cat-supplies']},
    {"option": "aquariums-tanks", "label": "Aquariums & Tanks",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-filters", "label": "Aquarium Filters",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-lighting-bulbs", "label": "Aquarium Lighting & Bulbs",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-decorations", "label": "Aquarium Decorations",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-heaters-chillers", "label": "Aquarium Heaters & Chillers",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-water-pumps", "label": "Aquarium Water Pumps",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-cleaning-maintenance", "label": "Aquarium Cleaning & Maintenance",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-test-kits", "label": "Aquarium Test Kits",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-gravel-substrate", "label": "Aquarium Gravel & Substrate",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-air-stones-diffusers", "label": "Aquarium Air Stones & Diffusers",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-backgrounds", "label": "Aquarium Backgrounds",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-co2-equipment", "label": "Aquarium CO2 Equipment",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-thermometers", "label": "Aquarium Thermometers",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-protein-skimmers", "label": "Aquarium Protein Skimmers",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-uv-sterilizers", "label": "Aquarium UV Sterilizers",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-fish-plant-food", "label": "Aquarium Fish & Plant Food",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-water-conditioners-additives", "label": "Aquarium Water Conditioners & Additives",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-breeding-equipment", "label": "Aquarium Breeding Equipment",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-nets-scoops", "label": "Aquarium Nets & Scoops",parentSubCategory: ['fish-aquariums']},
    {"option": "aquarium-plant-care-supplies", "label": "Aquarium Plant Care Supplies",parentSubCategory: ['fish-aquariums']},
    {"option": "bird-cages-enclosures", "label": "Bird Cages & Enclosures",parentSubCategory: ['bird-supplies']},
    {"option": "bird-toys", "label": "Bird Toys",parentSubCategory: ['bird-supplies']},
    {"option": "bird-food-treats", "label": "Bird Food & Treats",parentSubCategory: ['bird-supplies']},
    {"option": "bird-perches", "label": "Bird Perches",parentSubCategory: ['bird-supplies']},
    {"option": "bird-cage-accessories", "label": "Bird Cage Accessories",parentSubCategory: ['bird-supplies']},
    {"option": "bird-feeders-waterers", "label": "Bird Feeders & Waterers",parentSubCategory: ['bird-supplies']},
    {"option": "bird-health-care-supplies", "label": "Bird Health Care Supplies",parentSubCategory: ['bird-supplies']},
    {"option": "bird-nesting-breeding-supplies", "label": "Bird Nesting & Breeding Supplies",parentSubCategory: ['bird-supplies']},
    {"option": "bird-baths", "label": "Bird Baths",parentSubCategory: ['bird-supplies']},
    {"option": "bird-travel-carriers", "label": "Bird Travel Carriers",parentSubCategory: ['bird-supplies']},
    {"option": "bird-cage-stands", "label": "Bird Cage Stands",parentSubCategory: ['bird-supplies']},
    {"option": "bird-cage-covers", "label": "Bird Cage Covers",parentSubCategory: ['bird-supplies']},
    {"option": "bird-harnesses-leashes", "label": "Bird Harnesses & Leashes",parentSubCategory: ['bird-supplies']},
    {"option": "bird-ladders", "label": "Bird Ladders",parentSubCategory: ['bird-supplies']},
    {"option": "bird-swings", "label": "Bird Swings",parentSubCategory: ['bird-supplies']},
    {"option": "bird-grooming-supplies", "label": "Bird Grooming Supplies",parentSubCategory: ['bird-supplies']},
    {"option": "bird-cuttlebones-mineral-blocks", "label": "Bird Cuttlebones & Mineral Blocks",parentSubCategory: ['bird-supplies']},
    {"option": "bird-training-supplies", "label": "Bird Training Supplies",parentSubCategory: ['bird-supplies']},
    {"option": "bird-seeds-grains", "label": "Bird Seeds & Grains",parentSubCategory: ['bird-supplies']},
    {"option": "bird-supplements-vitamins", "label": "Bird Supplements & Vitamins",parentSubCategory: ['bird-supplies']},
    {"option": "small-animal-cages-enclosures", "label": "Small Animal Cages & Enclosures",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-bedding", "label": "Small Animal Bedding",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-food-treats", "label": "Small Animal Food & Treats",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-toys", "label": "Small Animal Toys",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-grooming-supplies", "label": "Small Animal Grooming Supplies",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-health-care-supplies", "label": "Small Animal Health Care Supplies",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-carriers-travel-products", "label": "Small Animal Carriers & Travel Products",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-feeding-watering-supplies", "label": "Small Animal Feeding & Watering Supplies",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-harnesses-leashes", "label": "Small Animal Harnesses & Leashes",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-litter-litter-boxes", "label": "Small Animal Litter & Litter Boxes",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-habitat-accessories", "label": "Small Animal Habitat Accessories",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-exercise-wheels", "label": "Small Animal Exercise Wheels",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-tunnels-hideouts", "label": "Small Animal Tunnels & Hideouts",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-treatments-remedies", "label": "Small Animal Treatments & Remedies",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-accessories", "label": "Small Animal Accessories",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-chews", "label": "Small Animal Chews",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-hay-grass", "label": "Small Animal Hay & Grass",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-supplements-vitamins", "label": "Small Animal Supplements & Vitamins",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-cage-stands", "label": "Small Animal Cage Stands",parentSubCategory: ['small-animal-supplies']},
    {"option": "small-animal-bottles-feeders", "label": "Small Animal Bottles & Feeders",parentSubCategory: ['small-animal-supplies']},
    {"option": "strollers-prams", "label": "Strollers & Prams",parentSubCategory: ['baby-gear']},
    {"option": "car-seats-accessories", "label": "Car Seats & Accessories",parentSubCategory: ['baby-gear']},
    {"option": "baby-carriers-slings-backpacks", "label": "Baby Carriers, Slings & Backpacks",parentSubCategory: ['baby-gear']},
    {"option": "baby-activity-centers-entertainers", "label": "Baby Activity Centers & Entertainers",parentSubCategory: ['baby-gear']},
    {"option": "baby-bouncers-rockers-swings", "label": "Baby Bouncers, Rockers & Swings",parentSubCategory: ['baby-gear']},
    {"option": "baby-walkers", "label": "Baby Walkers",parentSubCategory: ['baby-gear']},
    {"option": "baby-high-chairs-booster-seats", "label": "Baby High Chairs & Booster Seats",parentSubCategory: ['baby-gear']},
    {"option": "baby-playpens-play-yards", "label": "Baby Playpens & Play Yards",parentSubCategory: ['baby-gear']},
    {"option": "baby-jumpers-bouncers", "label": "Baby Jumpers & Bouncers",parentSubCategory: ['baby-gear']},
    {"option": "baby-travel-systems", "label": "Baby Travel Systems",parentSubCategory: ['baby-gear']},
    {"option": "baby-changing-tables-units", "label": "Baby Changing Tables & Units",parentSubCategory: ['baby-gear']},
    {"option": "baby-diaper-bags", "label": "Baby Diaper Bags",parentSubCategory: ['baby-gear']},
    {"option": "baby-monitors", "label": "Baby Monitors",parentSubCategory: ['baby-gear']},
    {"option": "baby-feeding-supplies", "label": "Baby Feeding Supplies",parentSubCategory: ['baby-gear']},
    {"option": "baby-safety-gates-stair-gates", "label": "Baby Safety Gates & Stair Gates",parentSubCategory: ['baby-gear']},
    {"option": "baby-bathtubs-bathing-accessories", "label": "Baby Bathtubs & Bathing Accessories",parentSubCategory: ['baby-gear']},
    {"option": "baby-humidifiers-vaporizers", "label": "Baby Humidifiers & Vaporizers",parentSubCategory: ['baby-gear']},
    {"option": "baby-thermometers", "label": "Baby Thermometers",parentSubCategory: ['baby-gear']},
    {"option": "baby-night-lights", "label": "Baby Night Lights",parentSubCategory: ['baby-gear']},
    {"option": "baby-door-bouncers-jumpers", "label": "Baby Door Bouncers & Jumpers",parentSubCategory: ['baby-gear']},
    {"option": "baby-rattles-and-shakers", "label": "Baby Rattles & Shakers",parentSubCategory: ['toys']},
    {"option": "baby-mobiles", "label": "Baby Mobiles",parentSubCategory: ['toys']},
    {"option": "baby-activity-centers-and-entertainers", "label": "Baby Activity Centers & Entertainers",parentSubCategory: ['toys']},
    {"option": "baby-play-mats-and-gyms", "label": "Baby Play Mats & Gyms",parentSubCategory: ['toys']},
    {"option": "baby-soft-toys-and-plushies", "label": "Baby Soft Toys & Plushies",parentSubCategory: ['toys']},
    {"option": "baby-musical-toys", "label": "Baby Musical Toys",parentSubCategory: ['toys']},
    {"option": "baby-teething-toys", "label": "Baby Teething Toys",parentSubCategory: ['toys']},
    {"option": "baby-stuffed-animals-and-teddy-bears", "label": "Baby Stuffed Animals & Teddy Bears",parentSubCategory: ['toys']},
    {"option": "baby-developmental-toys", "label": "Baby Developmental Toys",parentSubCategory: ['toys']},
    {"option": "baby-shape-sorters-and-stackers", "label": "Baby Shape Sorters & Stackers",parentSubCategory: ['toys']},
    {"option": "baby-blocks-and-building-toys", "label": "Baby Blocks & Building Toys",parentSubCategory: ['toys']},
    {"option": "baby-sensory-toys", "label": "Baby Sensory Toys",parentSubCategory: ['toys']},
    {"option": "baby-bath-toys", "label": "Baby Bath Toys",parentSubCategory: ['toys']},
    {"option": "baby-push-and-pull-toys", "label": "Baby Push & Pull Toys",parentSubCategory: ['toys']},
    {"option": "baby-books-and-soft-books", "label": "Baby Books & Soft Books",parentSubCategory: ['toys']},
    {"option": "baby-activity-toys", "label": "Baby Activity Toys",parentSubCategory: ['toys']},
    {"option": "baby-crib-toys-and-attachments", "label": "Baby Crib Toys & Attachments",parentSubCategory: ['toys']},
    {"option": "baby-light-up-toys", "label": "Baby Light-Up Toys",parentSubCategory: ['toys']},
    {"option": "baby-toy-cars-and-trucks", "label": "Baby Toy Cars & Trucks",parentSubCategory: ['toys']},
    {"option": "baby-educational-toys", "label": "Baby Educational Toys",parentSubCategory: ['toys']},
    {"option": "maternity-dresses", "label": "Maternity Dresses",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-tops-blouses", "label": "Maternity Tops & Blouses",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-trousers-jeans", "label": "Maternity Trousers & Jeans",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-skirts", "label": "Maternity Skirts",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-lingerie-underwear", "label": "Maternity Lingerie & Underwear",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-swimwear", "label": "Maternity Swimwear",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-activewear-sportswear", "label": "Maternity Activewear & Sportswear",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-outerwear-coats", "label": "Maternity Outerwear & Coats",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-sleepwear-nightwear", "label": "Maternity Sleepwear & Nightwear",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-jumpsuits-playsuits", "label": "Maternity Jumpsuits & Playsuits",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-workwear-office-attire", "label": "Maternity Workwear & Office Attire",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-nursing-bras-tops", "label": "Maternity Nursing Bras & Tops",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-bras-support-bands", "label": "Maternity Bras & Support Bands",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-compression-support-garments", "label": "Maternity Compression & Support Garments",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-leggings-tights", "label": "Maternity Leggings & Tights",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-shorts", "label": "Maternity Shorts",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-socks-hosiery", "label": "Maternity Socks & Hosiery",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-belts-belly-bands", "label": "Maternity Belts & Belly Bands",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-dresses-for-special-occasions", "label": "Maternity Dresses for Special Occasions",parentSubCategory: ['maternity-clothing']},
    {"option": "maternity-clothing-bundles-sets", "label": "Maternity Clothing Bundles & Sets",parentSubCategory: ['maternity-clothing']},
    {"option": "pampers", "label": "Pampers",parentSubCategory: ['maternity-clothing']},
    {"option": "huggies", "label": "Huggies",parentSubCategory: ['maternity-clothing']},
    {"option": "babymoov", "label": "Babymoov",parentSubCategory: ['maternity-clothing']},
    {"option": "evenflo", "label": "Evenflo",parentSubCategory: ['maternity-clothing']},
    {"option": "bumbo", "label": "Bumbo",parentSubCategory: ['maternity-clothing']},
    {"option": "baby-einstein", "label": "Baby Einstein",parentSubCategory: ['maternity-clothing']},
    {"option": "the-gro-company", "label": "The Gro Company",parentSubCategory: ['maternity-clothing']},
    {"option": "dr-browns", "label": "Dr. Brown's",parentSubCategory: ['maternity-clothing']},
    {"option": "ameda", "label": "Ameda",parentSubCategory: ['maternity-clothing']},
    {"option": "playtex", "label": "Playtex",parentSubCategory: ['maternity-clothing']},

];
