import { fetchPurchaseVouchers } from '../../util/api';
// import { storableError } from '../util/errors';

// ================ Action types ================ //

// GET request action types
export const SIMPLE_GET_REQUEST = 'app/simpleGetRequest/SIMPLE_GET_REQUEST';
export const SIMPLE_GET_SUCCESS = 'app/simpleGetRequest/SIMPLE_GET_SUCCESS';
export const SIMPLE_GET_ERROR = 'app/simpleGetRequest/SIMPLE_GET_ERROR';

// POST request action types
export const PURCHASE_POST_REQUEST = 'app/simplePostRequest/SIMPLE_POST_REQUEST';
export const PURCHASE_POST_SUCCESS = 'app/simplePostRequest/SIMPLE_POST_SUCCESS';
export const PURCHASE_POST_ERROR = 'app/simplePostRequest/SIMPLE_POST_ERROR';

// ================ Reducer ================ //

const initialState = {
  data: null,
  fetchInProgress: false,
  fetchError: null,
  postInProgress: false,
  postError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    // GET request cases
    case SIMPLE_GET_REQUEST:
      return { ...state, fetchInProgress: true, fetchError: null };
    case SIMPLE_GET_SUCCESS:
      return { ...state, fetchInProgress: false, data: payload };
    case SIMPLE_GET_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchError: payload };

    // POST request cases
    case PURCHASE_POST_REQUEST:
      return { ...state, postInProgress: true, postError: null };
    case PURCHASE_POST_SUCCESS:
      return { ...state, postInProgress: false, data: payload };
    case PURCHASE_POST_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, postInProgress: false, postError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const getData = state => state.simpleGetRequest.data;
export const isFetchInProgress = state => state.simpleGetRequest.fetchInProgress;
export const getFetchError = state => state.simpleGetRequest.fetchError;

export const isPostInProgress = state => state.simpleGetRequest.postInProgress;
export const getPostError = state => state.simpleGetRequest.postError;

// ================ Action creators ================ //

// GET request action creators
export const simpleGetRequest = () => ({ type: SIMPLE_GET_REQUEST });
export const simpleGetSuccess = data => ({ type: SIMPLE_GET_SUCCESS, payload: data });
export const simpleGetError = e => ({ type: SIMPLE_GET_ERROR, payload: e, error: true });

// POST request action creators
export const purchasePostRequest = () => ({ type: PURCHASE_POST_REQUEST });
export const purchasePostSuccess = data => ({ type: PURCHASE_POST_SUCCESS, payload: data });
export const purchasePostError = e => ({ type: PURCHASE_POST_ERROR, payload: e, error: true });

// ================ Thunks ================ //

// GET request thunk
export const fetchData = endpoint => (dispatch, getState, sdk) => {
  dispatch(simpleGetRequest());

  return sdk.endpoint
    .get(endpoint)
    .then(response => {
      dispatch(simpleGetSuccess(response.data));
    })
    .catch(e => {
      dispatch(simpleGetError(e));
    });
};

// POST request thunk
export const postPurchaseData = data => (dispatch, getState, sdk) => {
  dispatch(purchasePostRequest());

  return fetchPurchaseVouchers(data)
    .then(response => {
      dispatch(purchasePostSuccess(response));
    })
    .catch(e => {
      dispatch(purchasePostError(e));
    });
};
