export const listingBrands = [
  {
    option: 'not-specified',
    label: 'Not Specified',
    parentSubCategory: [
      'fitness-trackers',
      'pC-gaming',
      'accessories',
      'tablets-E-Readers',
      'networking-connectivity',
      'monitors-screens',
      'gaming-pCs',
      'batteries',
      'outdoor-sports',
      'smartphones',
      'screen-protectors',
      'computer-accessories',
      'bath',
      'computers-laptops',
      'smart-watches',
      'smart-watch-accessories',
      'headphones',
      'mP3-players',
      'camcorders',
      'digital-cameras',
      'storage-organisation',
      'digital-cameras',
      'film-polaroid-cameras',
      'blu-ray',
      'cd',
      'watches',
      'smart-home',
      'streaming-devices',
      'dvrs',
      'gadgets',
      'audio-accessories',
      'dvd',
      'bluetooth-speakers',
      'docking-stations',
      'portable-stereos-boomboxes',
      'radios',
      'studio-recording-equipment',
      ,
      'team-sports',
      'fitness',
      'baby-clothing',
      'bedding',
      'televisions',
      'kitchen-dining',
      'collectibles',
      'home-appliances',
    ],
  },
  {
    option: 'samsung',
    label: 'Samsung',
    parentSubCategory: [
      'fitness-trackers',
      'monitors-screens',
      'batteries',
      'smartphones',
      'computer-accessories',
      'computers-laptops',
      'gaming-pCs',
      'smart-watches',
      'mP3-players',
    ],
  },
  {
    option: 'lg',
    label: 'LG',
    parentSubCategory: [
      'fitness-trackers',
      'monitors-screens',
      'batteries',
      'smartphones',
      'computers-laptops',
      'gaming-pCs',
      'smart-watches',
    ],
  },
  { option: 'cameron-sino', label: 'Cameron Sino', parentSubCategory: ['batteries'] },
  {
    option: 'nokia',
    label: 'Nokia',
    parentSubCategory: ['batteries', 'smartphones', 'computers-laptops', 'smart-watches'],
  },

  {
    option: 'apple',
    label: 'Apple',
    parentSubCategory: [
      'batteries',
      'smartphones',
      'computer-accessories',
      'computers-laptops',
      'gaming-pCs',
      'smart-watches',
      'camcorders',
    ],
  },
  {
    option: 'xiaomi',
    label: 'Xiaomi',
    parentSubCategory: [
      'fitness-trackers',
      'batteries',
      'smartphones',
      'computers-laptops',
      'smart-watches',
    ],
  },
  { option: 'sony-ericsson', label: 'Sony Ericsson', parentSubCategory: ['batteries'] },
  {
    option: 'motorola',
    label: 'Motorola',
    parentSubCategory: ['batteries', 'computers-laptops', 'smart-watches'],
  },
  { option: 'zte', label: 'ZTE', parentSubCategory: ['batteries', 'smartphones', 'smart-watches'] },

  {
    option: 'htc',
    label: 'HTC',
    parentSubCategory: ['batteries', 'smartphones', 'computers-laptops'],
  },
  {
    option: 'alcatel',
    label: 'Alcatel',
    parentSubCategory: ['batteries', 'smartphones', 'smart-watches'],
  },
  {
    option: 'sony',
    label: 'Sony',
    parentSubCategory: [
      'fitness-trackers',
      'monitors-screens',
      'batteries',
      'smartphones',
      'computer-accessories',
      'computers-laptops',
      'gaming-pCs',
      'smart-watches',
      'headphones',
      'mP3-players',
    ],
  },
  {
    option: 'asus',
    label: 'ASUS',
    parentSubCategory: [
      'pC-gaming',
      'networking-connectivity',
      'monitors-screens',
      'batteries',
      'smartphones',
      'computer-accessories',
      'computers-laptops',
      'gaming-pCs',
      'smart-watches',
    ],
  },
  { option: 'mugen-power', label: 'Mugen Power', parentSubCategory: ['batteries'] },

  { option: 'blackberry', label: 'Blackberry', parentSubCategory: ['batteries', 'smartphones'] },
  { option: 'oneplus', label: 'OnePlus', parentSubCategory: ['batteries'] },
  { option: 'kyocera', label: 'Kyocera', parentSubCategory: ['batteries'] },
  {
    option: 'microsoft',
    label: 'Microsoft',
    parentSubCategory: [
      'fitness-trackers',
      'batteries',
      'computer-accessories',
      'computers-laptops',
      'gaming-pCs',
      'smart-watches',
    ],
  },
  {
    option: 'huawei',
    label: 'Huawei',
    parentSubCategory: [
      'fitness-trackers',
      'networking-connectivity',
      'batteries',
      'smartphones',
      'computers-laptops',
      'smart-watches',
    ],
  },

  { option: 'redmi', label: 'Redmi', parentSubCategory: ['smartphones'] },
  { option: 'oppo', label: 'OPPO', parentSubCategory: ['smartphones'] },
  { option: 'verizon', label: 'Verizon', parentSubCategory: ['smartphones'] },
  {
    option: 'spigen',
    label: 'Spigen',
    parentSubCategory: ['screen-protectors', 'smart-watch-accessories'],
  },

  { option: 'otterbox', label: 'OtterBox', parentSubCategory: ['screen-protectors'] },
  { option: 'anker', label: 'Anker', parentSubCategory: ['screen-protectors'] },
  {
    option: 'belkin',
    label: 'Belkin',
    parentSubCategory: ['networking-connectivity', 'screen-protectors', 'smart-watches'],
  },
  {
    option: 'jetech',
    label: 'JETech',
    parentSubCategory: ['screen-protectors', 'smart-watch-accessories'],
  },

  { option: 'ugreen', label: 'UGREEN', parentSubCategory: ['screen-protectors'] },
  { option: 'tech21', label: 'Tech21', parentSubCategory: ['screen-protectors'] },
  { option: 'esr', label: 'ESR', parentSubCategory: ['screen-protectors'] },
  { option: 'olixar', label: 'Olixar', parentSubCategory: ['screen-protectors'] },
  { option: 'ravpower', label: 'RAVPower', parentSubCategory: ['screen-protectors'] },

  {
    option: 'hp',
    label: 'HP',
    parentSubCategory: [
      'monitors-screens',
      'computer-accessories',
      'computers-laptops',
      'gaming-pCs',
    ],
  },
  {
    option: 'dell',
    label: 'Dell',
    parentSubCategory: [
      'monitors-screens',
      'computer-accessories',
      'computers-laptops',
      'gaming-pCs',
    ],
  },
  {
    option: 'lenovo',
    label: 'Lenovo',
    parentSubCategory: [
      'monitors-screens',
      'computer-accessories',
      'computers-laptops',
      'gaming-pCs',
      'smart-watches',
    ],
  },
  {
    option: 'acer',
    label: 'Acer',
    parentSubCategory: [
      'pC-gaming',
      'monitors-screens',
      'computer-accessories',
      'computers-laptops',
      'gaming-pCs',
    ],
  },

  {
    option: 'toshiba',
    label: 'Toshiba',
    parentSubCategory: ['computer-accessories', 'computers-laptops'],
  },
  {
    option: 'logitech',
    label: 'Logitech',
    parentSubCategory: ['pC-gaming', 'computer-accessories'],
  },
  { option: 'mosiso', label: 'Mosiso', parentSubCategory: ['computer-accessories'] },

  { option: '3m', label: '3M', parentSubCategory: ['computers-laptops'] },
  { option: '3xs-systems', label: '3XS Systems', parentSubCategory: ['computers-laptops'] },
  { option: 'advantech', label: 'Advantech', parentSubCategory: ['computers-laptops'] },
  { option: 'advent', label: 'Advent', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  {
    option: 'alienware',
    label: 'Alienware',
    parentSubCategory: ['monitors-screens', 'computers-laptops', 'gaming-pCs'],
  },
  { option: 'alldocube', label: 'ALLDOCUBE', parentSubCategory: ['computers-laptops'] },

  {
    option: 'amazon',
    label: 'Amazon',
    parentSubCategory: ['fitness-trackers', 'computers-laptops'],
  },
  {
    option: 'amd',
    label: 'AMD',
    parentSubCategory: ['pC-gaming', 'computers-laptops', 'gaming-pCs'],
  },
  { option: 'aorus', label: 'AORUS', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  { option: 'ast-ascentia', label: 'AST Ascentia', parentSubCategory: ['computers-laptops'] },
  { option: 'avita', label: 'AVITA', parentSubCategory: ['computers-laptops'] },
  {
    option: 'casio',
    label: 'Casio',
    parentSubCategory: ['fitness-trackers', 'computers-laptops', 'smart-watches', 'camcorders'],
  },

  { option: 'chuwi', label: 'Chuwi', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  { option: 'clevo', label: 'CLEVO', parentSubCategory: ['computers-laptops'] },
  { option: 'coda', label: 'CODA', parentSubCategory: ['computers-laptops'] },
  { option: 'compaq', label: 'Compaq', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  {
    option: 'corsair',
    label: 'Corsair',
    parentSubCategory: ['pC-gaming', 'computers-laptops', 'gaming-pCs'],
  },
  { option: 'creative', label: 'Creative', parentSubCategory: ['computers-laptops'] },

  { option: 'ctl', label: 'CTL', parentSubCategory: ['computers-laptops'] },
  {
    option: 'custom-whitebox',
    label: 'Custom, Whitebox',
    parentSubCategory: ['computers-laptops', 'gaming-pCs'],
  },
  {
    option: 'cyberpowerPC',
    label: 'CyberPowerPC',
    parentSubCategory: ['computers-laptops', 'gaming-pCs'],
  },
  { option: 'delta', label: 'Delta', parentSubCategory: ['computers-laptops'] },
  { option: 'dynabook', label: 'dynabook', parentSubCategory: ['computers-laptops'] },
  { option: 'elitegroup', label: 'Elitegroup', parentSubCategory: ['computers-laptops'] },

  {
    option: 'eMachines',
    label: 'eMachines',
    parentSubCategory: ['computers-laptops', 'gaming-pCs'],
  },
  { option: 'epson', label: 'Epson', parentSubCategory: ['computers-laptops'] },
  { option: 'ergo', label: 'Ergo', parentSubCategory: ['computers-laptops'] },
  { option: 'evesham', label: 'Evesham', parentSubCategory: ['computers-laptops'] },
  {
    option: 'evga',
    label: 'EVGA',
    parentSubCategory: ['pC-gaming', 'computers-laptops', 'gaming-pCs'],
  },
  { option: 'evoo', label: 'Evoo', parentSubCategory: ['computers-laptops'] },

  {
    option: 'excelvan',
    label: 'Excelvan',
    parentSubCategory: ['computers-laptops', 'smart-watches'],
  },
  { option: 'fujifilm', label: 'Fujifilm', parentSubCategory: ['computers-laptops'] },
  { option: 'fujitsu', label: 'Fujitsu', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  {
    option: 'fujitsu-siemens',
    label: 'Fujitsu Siemens',
    parentSubCategory: ['computers-laptops', 'gaming-pCs'],
  },
  { option: 'fusion5', label: 'Fusion5', parentSubCategory: ['computers-laptops'] },
  {
    option: 'garmin',
    label: 'Garmin',
    parentSubCategory: ['fitness-trackers', 'computers-laptops', 'smart-watches'],
  },

  { option: 'gateway', label: 'Gateway', parentSubCategory: ['computers-laptops'] },
  { option: 'geo', label: 'Geo', parentSubCategory: ['computers-laptops'] },
  { option: 'geo-computers', label: 'Geo Computers', parentSubCategory: ['computers-laptops'] },
  { option: 'GERICOM', label: 'GERICOM', parentSubCategory: ['computers-laptops'] },
  { option: 'getac', label: 'Getac', parentSubCategory: ['computers-laptops'] },
  { option: 'GIGABYTE', label: 'GIGABYTE', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },

  { option: 'google', label: 'Google', parentSubCategory: ['computers-laptops'] },
  { option: 'hannspree', label: 'Hannspree', parentSubCategory: ['computers-laptops'] },
  { option: 'hipstreet', label: 'Hipstreet', parentSubCategory: ['computers-laptops'] },
  { option: 'hitachi', label: 'Hitachi', parentSubCategory: ['computers-laptops'] },
  { option: 'honor', label: 'HONOR', parentSubCategory: ['computers-laptops'] },
  { option: 'ibm', label: 'IBM', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },

  {
    option: 'intel',
    label: 'Intel',
    parentSubCategory: ['pC-gaming', 'computers-laptops', 'gaming-pCs'],
  },
  { option: 'iota', label: 'IOTA', parentSubCategory: ['computers-laptops'] },
  { option: 'itronix', label: 'Itronix', parentSubCategory: ['computers-laptops'] },
  { option: 'jumper', label: 'Jumper', parentSubCategory: ['computers-laptops'] },
  { option: 'jvc', label: 'JVC', parentSubCategory: ['computers-laptops'] },
  { option: 'kano', label: 'Kano', parentSubCategory: ['computers-laptops'] },

  { option: 'kocaso', label: 'KOCASO', parentSubCategory: ['computers-laptops'] },
  { option: 'maxdata', label: 'MAXDATA', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  { option: 'medion', label: 'MEDION', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  { option: 'micron', label: 'Micron', parentSubCategory: ['computers-laptops'] },
  { option: 'miTAC', label: 'MiTAC', parentSubCategory: ['computers-laptops'] },
  { option: 'motion', label: 'Motion', parentSubCategory: ['computers-laptops'] },

  {
    option: 'msi',
    label: 'MSI',
    parentSubCategory: ['pC-gaming', 'monitors-screens', 'computers-laptops', 'gaming-pCs'],
  },
  { option: 'nec', label: 'NEC', parentSubCategory: ['monitors-screens', 'computers-laptops'] },
  { option: 'netac', label: 'Netac', parentSubCategory: ['computers-laptops'] },
  { option: 'nextbook', label: 'Nextbook', parentSubCategory: ['computers-laptops'] },
  { option: 'novatech', label: 'Novatech', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  {
    option: 'nvidia',
    label: 'NVIDIA',
    parentSubCategory: ['pC-gaming', 'computers-laptops', 'gaming-pCs'],
  },

  { option: 'olidata', label: 'Olidata', parentSubCategory: ['computers-laptops'] },
  { option: 'olpc', label: 'Olpc', parentSubCategory: ['computers-laptops'] },
  { option: 'one-netbook', label: 'One-Netbook', parentSubCategory: ['computers-laptops'] },
  { option: 'origin-pc', label: 'ORIGIN PC', parentSubCategory: ['computers-laptops'] },
  {
    option: 'packard-bell',
    label: 'Packard Bell',
    parentSubCategory: ['computers-laptops', 'gaming-pCs'],
  },
  { option: 'panasonic', label: 'Panasonic', parentSubCategory: ['computers-laptops'] },

  { option: 'pavilion', label: 'Pavilion', parentSubCategory: ['computers-laptops'] },
  {
    option: 'pc-specialist',
    label: 'PC Specialist',
    parentSubCategory: ['computers-laptops', 'gaming-pCs'],
  },
  { option: 'peaq', label: 'PEAQ', parentSubCategory: ['computers-laptops'] },
  {
    option: 'razer',
    label: 'Razer',
    parentSubCategory: ['pC-gaming', 'computers-laptops', 'gaming-pCs', 'smart-watches'],
  },
  { option: 'roda', label: 'Roda', parentSubCategory: ['computers-laptops'] },
  { option: 'sandisk', label: 'SanDisk', parentSubCategory: ['computers-laptops', 'mP3-players'] },

  { option: 'sharp', label: 'Sharp', parentSubCategory: ['computers-laptops'] },
  { option: 'siemens', label: 'Siemens', parentSubCategory: ['computers-laptops'] },
  { option: 'SYLVANIA', label: 'SYLVANIA', parentSubCategory: ['computers-laptops'] },
  { option: 'razer', label: 'synology', parentSubCategory: ['computers-laptops'] },
  { option: 'targa', label: 'Targa', parentSubCategory: ['computers-laptops'] },
  { option: 'teclast', label: 'Teclast', parentSubCategory: ['computers-laptops'] },

  { option: 'terra', label: 'Terra', parentSubCategory: ['computers-laptops'] },
  { option: 'thomson', label: 'Thomson', parentSubCategory: ['computers-laptops'] },
  { option: 'TREKSTOR', label: 'TREKSTOR', parentSubCategory: ['computers-laptops'] },
  { option: 'twinHead', label: 'TwinHead', parentSubCategory: ['computers-laptops'] },
  { option: 'ultra', label: 'Ultra', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  { option: 'V7', label: 'V7', parentSubCategory: ['computers-laptops'] },

  { option: 'viewsonic', label: 'ViewSonic', parentSubCategory: ['computers-laptops'] },
  { option: 'VOYO', label: 'VOYO', parentSubCategory: ['computers-laptops'] },
  { option: 'wacom', label: 'Wacom', parentSubCategory: ['computers-laptops'] },
  { option: 'X2', label: 'X2', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },
  { option: 'XMG', label: 'XMG', parentSubCategory: ['computers-laptops'] },
  { option: 'XPLORE', label: 'XPLORE', parentSubCategory: ['computers-laptops'] },

  { option: 'YEPO', label: 'YEPO', parentSubCategory: ['computers-laptops'] },
  { option: 'zaith', label: 'Zaith', parentSubCategory: ['computers-laptops'] },
  { option: 'zoostorm', label: 'Zoostorm', parentSubCategory: ['computers-laptops', 'gaming-pCs'] },

  { option: '3Com', label: '3Com', parentSubCategory: ['gaming-pCs'] },
  { option: 'acemagic', label: 'Acemagic', parentSubCategory: ['gaming-pCs'] },
  { option: 'ACS', label: 'ACS', parentSubCategory: ['gaming-pCs'] },

  { option: 'AMS', label: 'AMS', parentSubCategory: ['gaming-pCs'] },
  { option: 'antec', label: 'Antec', parentSubCategory: ['gaming-pCs'] },
  { option: 'AOPEN', label: 'AOPEN', parentSubCategory: ['gaming-pCs'] },

  { option: 'aSRock', label: 'ASRock', parentSubCategory: ['gaming-pCs'] },
  { option: 'AWOW', label: 'AWOW', parentSubCategory: ['gaming-pCs'] },
  { option: 'benQ', label: 'BenQ', parentSubCategory: ['monitors-screens', 'gaming-pCs'] },

  { option: 'be-quiet!', label: 'be quiet!', parentSubCategory: ['gaming-pCs'] },
  { option: 'bitFenix', label: 'BitFenix', parentSubCategory: ['gaming-pCs'] },
  { option: 'CCL Computers', label: 'CCL Computers', parentSubCategory: ['gaming-pCs'] },

  { option: 'chillblast', label: 'Chillblast', parentSubCategory: ['gaming-pCs'] },
  { option: 'CiT', label: 'CiT', parentSubCategory: ['gaming-pCs'] },
  { option: 'CLX', label: 'CLX', parentSubCategory: ['gaming-pCs'] },

  { option: 'compatible', label: 'Compatible', parentSubCategory: ['gaming-pCs'] },
  {
    option: 'computers-by-design',
    label: 'Computers by Design',
    parentSubCategory: ['gaming-pCs'],
  },
  { option: 'cube', label: 'Cube', parentSubCategory: ['gaming-pCs'] },

  { option: 'cyberPower', label: 'CyberPower', parentSubCategory: ['gaming-pCs'] },
  { option: 'deepcool', label: 'Deepcool', parentSubCategory: ['gaming-pCs'] },
  { option: 'ebuyer', label: 'Ebuyer', parentSubCategory: ['gaming-pCs'] },

  { option: 'eZQuest', label: 'EZQuest', parentSubCategory: ['gaming-pCs'] },
  { option: 'FIC', label: 'FIC', parentSubCategory: ['gaming-pCs'] },
  { option: 'foxconn', label: 'Foxconn', parentSubCategory: ['gaming-pCs'] },

  { option: 'fractal-design', label: 'Fractal Design', parentSubCategory: ['gaming-pCs'] },
  { option: 'fusion', label: 'Fusion', parentSubCategory: ['gaming-pCs'] },
  { option: 'G. SKILL', label: 'G. SKILL', parentSubCategory: ['gaming-pCs'] },

  { option: 'GAMDIAS', label: 'GAMDIAS', parentSubCategory: ['gaming-pCs'] },
  { option: 'general', label: 'General', parentSubCategory: ['gaming-pCs'] },
  { option: 'ginger6', label: 'Ginger6', parentSubCategory: ['gaming-pCs'] },

  { option: 'GMK', label: 'GMK', parentSubCategory: ['gaming-pCs'] },
  { option: 'hurricane', label: 'Hurricane', parentSubCategory: ['gaming-pCs'] },
  { option: 'iBuyPower', label: 'iBuyPower', parentSubCategory: ['gaming-pCs'] },

  { option: 'I will', label: 'I will', parentSubCategory: ['gaming-pCs'] },
  { option: 'KGM', label: 'KGM', parentSubCategory: ['gaming-pCs'] },
  { option: 'McAfee', label: 'McAfee', parentSubCategory: ['gaming-pCs'] },

  { option: 'mesh', label: 'Mesh', parentSubCategory: ['gaming-pCs'] },
  { option: 'MINISFORUM', label: 'MINISFORUM', parentSubCategory: ['gaming-pCs'] },
  { option: 'MINIX', label: 'MINIX', parentSubCategory: ['gaming-pCs'] },

  { option: 'NCR', label: 'NCR', parentSubCategory: ['gaming-pCs'] },
  { option: 'noctua', label: 'Noctua', parentSubCategory: ['gaming-pCs'] },
  { option: 'McAfee', label: 'McAfee', parentSubCategory: ['gaming-pCs'] },

  { option: 'NZXT', label: 'NZXT', parentSubCategory: ['pC-gaming', 'gaming-pCs'] },
  { option: 'OCHW', label: 'OCHW', parentSubCategory: ['gaming-pCs'] },
  { option: 'olivetti', label: 'Olivetti', parentSubCategory: ['gaming-pCs'] },

  { option: 'OMEN', label: 'OMEN', parentSubCategory: ['gaming-pCs'] },
  { option: 'onyx', label: 'Onyx', parentSubCategory: ['gaming-pCs'] },
  { option: 'orion', label: 'Orion', parentSubCategory: ['gaming-pCs'] },

  {
    option: 'philips',
    label: 'Philips',
    parentSubCategory: ['monitors-screens', 'gaming-pCs', 'smart-watches'],
  },
  { option: 'PINE Technology', label: 'PINE Technology', parentSubCategory: ['gaming-pCs'] },
  { option: 'PNY', label: 'PNY', parentSubCategory: ['gaming-pCs'] },

  { option: 'quantum', label: 'Quantum', parentSubCategory: ['gaming-pCs'] },
  { option: 'R&M', label: 'R&M', parentSubCategory: ['gaming-pCs'] },
  { option: 'Raspberry Pi', label: 'Raspberry Pi', parentSubCategory: ['gaming-pCs'] },

  { option: 'SAPPHIRE', label: 'SAPPHIRE', parentSubCategory: ['gaming-pCs'] },
  { option: 'shuttle', label: 'Shuttle', parentSubCategory: ['gaming-pCs'] },
  { option: 'silverstone', label: 'Silverstone', parentSubCategory: ['gaming-pCs'] },

  { option: 'supermicro', label: 'Supermicro', parentSubCategory: ['gaming-pCs'] },
  { option: 'thermaltake', label: 'Thermaltake', parentSubCategory: ['gaming-pCs'] },
  { option: 'tiny', label: 'Tiny', parentSubCategory: ['gaming-pCs'] },

  { option: 'tower', label: 'Tower', parentSubCategory: ['gaming-pCs'] },
  { option: 'twin', label: 'Twin', parentSubCategory: ['gaming-pCs'] },
  { option: 'ultimate', label: 'Ultimate', parentSubCategory: ['gaming-pCs'] },

  { option: 'vibox', label: 'Vibox', parentSubCategory: ['gaming-pCs'] },
  { option: 'winBook', label: 'WinBook', parentSubCategory: ['gaming-pCs'] },
  { option: 'zalman', label: 'Zalman', parentSubCategory: ['gaming-pCs'] },
  { option: 'ZOTAC', label: 'ZOTAC', parentSubCategory: ['gaming-pCs'] },

  { option: 'AOC', label: 'AOC', parentSubCategory: ['pC-gaming', 'monitors-screens'] },
  { option: 'VIewSonic', label: 'VIewSonic', parentSubCategory: ['monitors-screens'] },
  { option: 'iiyama', label: 'iiyama', parentSubCategory: ['monitors-screens'] },
  { option: 'dell-ultrasharp', label: 'Dell Ultrasharp', parentSubCategory: ['monitors-screens'] },
  { option: 'EIZO', label: 'EIZO', parentSubCategory: ['monitors-screens'] },

  { option: 'TP-Link', label: 'TP-Link', parentSubCategory: ['networking-connectivity'] },
  { option: 'NETGEAR', label: 'NETGEAR', parentSubCategory: ['networking-connectivity'] },
  { option: 'cisco', label: 'Cisco', parentSubCategory: ['networking-connectivity', 'camcorders'] },
  { option: 'linksys', label: 'Linksys', parentSubCategory: ['networking-connectivity'] },

  { option: 'D-Link', label: 'D-Link', parentSubCategory: ['networking-connectivity'] },
  {
    option: 'ubiquiti-networks',
    label: 'Ubiquiti Networks',
    parentSubCategory: ['networking-connectivity'],
  },
  { option: 'drayTek', label: 'DrayTek', parentSubCategory: ['networking-connectivity'] },
  { option: 'mikroTek', label: 'MikroTek', parentSubCategory: ['networking-connectivity'] },
  { option: 'zyxel', label: 'Zyxel', parentSubCategory: ['networking-connectivity'] },

  { option: 'synology', label: 'Synology', parentSubCategory: ['networking-connectivity'] },
  { option: 'tenda', label: 'Tenda', parentSubCategory: ['networking-connectivity'] },
  { option: 'buffalo', label: 'Buffalo', parentSubCategory: ['networking-connectivity'] },

  { option: 'apple-iPad', label: 'Apple iPad', parentSubCategory: ['tablets-E-Readers'] },
  {
    option: 'samsung-galaxy-tab',
    label: 'Samsung Galaxy Tab',
    parentSubCategory: ['tablets-E-Readers'],
  },
  { option: 'amazon-kindle', label: 'Amazon Kindle', parentSubCategory: ['tablets-E-Readers'] },
  {
    option: 'microsoft-surface',
    label: 'Microsoft Surface',
    parentSubCategory: ['tablets-E-Readers'],
  },
  { option: 'huawei-mediaPad', label: 'Huawei MediaPad', parentSubCategory: ['tablets-E-Readers'] },
  { option: 'ASUS-zenPad', label: 'ASUS ZenPad', parentSubCategory: ['tablets-E-Readers'] },
  {
    option: 'sony-xperia-tablet',
    label: 'Sony Xperia Tablet',
    parentSubCategory: ['tablets-E-Readers'],
  },
  {
    option: 'google-pixel-slate',
    label: 'Google Pixel Slate',
    parentSubCategory: ['tablets-E-Readers'],
  },
  { option: 'xiaomi-mi-pad', label: 'Xiaomi Mi Pad', parentSubCategory: ['tablets-E-Readers'] },
  { option: 'acer-iconia', label: 'Acer Iconia', parentSubCategory: ['tablets-E-Readers'] },
  {
    option: 'barnes-noble-nook',
    label: 'Barnes & Noble Nook',
    parentSubCategory: ['tablets-E-Readers'],
  },
  { option: 'kobo', label: 'Kobo', parentSubCategory: ['tablets-E-Readers'] },
  {
    option: 'alcatel-oneTouch',
    label: 'Alcatel OneTouch',
    parentSubCategory: ['tablets-E-Readers'],
  },
  { option: 'LG G Pad', label: 'LG G Pad', parentSubCategory: ['tablets-E-Readers'] },
  { option: 'toshiba-excite', label: 'Toshiba Excite', parentSubCategory: ['tablets-E-Readers'] },
  { option: 'onePlus-pad', label: 'OnePlus Pad', parentSubCategory: ['tablets-E-Readers'] },

  {
    option: 'cases-covers-keyboard-folios',
    label: 'Cases, Covers & Keyboard Folios',
    parentSubCategory: ['accessories'],
  },
  { option: 'screen-protectors', label: 'Screen Protectors', parentSubCategory: ['accessories'] },
  { option: 'styluses', label: 'Styluses', parentSubCategory: ['accessories'] },
  {
    option: 'chargers-sync-cables',
    label: 'Chargers & Sync Cables',
    parentSubCategory: ['accessories'],
  },
  {
    option: 'docking-stations-cradles',
    label: 'Docking Stations & Cradles',
    parentSubCategory: ['accessories'],
  },
  {
    option: 'mounts-stands-holders',
    label: 'Mounts, Stands & Holders',
    parentSubCategory: ['accessories'],
  },
  {
    option: 'replacement-parts-tools',
    label: 'Replacement Parts & Tools',
    parentSubCategory: ['accessories'],
  },
  {
    option: 'cleaning-equipment-kits',
    label: 'Cleaning Equipment & Kits',
    parentSubCategory: ['accessories'],
  },
  {
    option: 'memory-card-readers-adapters',
    label: 'Memory Card Readers & Adapters',
    parentSubCategory: ['accessories'],
  },
  { option: 'keyboards', label: 'Keyboards', parentSubCategory: ['accessories'] },

  { option: 'steelSeries', label: 'SteelSeries', parentSubCategory: ['pC-gaming'] },
  { option: 'cooler-master', label: 'Cooler Master', parentSubCategory: ['pC-gaming'] },
  { option: 'gigabyte', label: 'Gigabyte', parentSubCategory: ['pC-gaming'] },
  { option: 'hyperX', label: 'HyperX', parentSubCategory: ['pC-gaming'] },
  { option: 'AMD Radeon', label: 'AMD Radeon', parentSubCategory: ['pC-gaming'] },
  { option: 'creative-labs', label: 'Creative Labs', parentSubCategory: ['pC-gaming'] },
  {
    option: 'western-digital-(WD)',
    label: 'Western Digital (WD)',
    parentSubCategory: ['pC-gaming'],
  },
  { option: 'seagate', label: 'Seagate', parentSubCategory: ['pC-gaming'] },

  { option: 'adidas', label: 'adidas', parentSubCategory: ['fitness-trackers'] },
  { option: 'amazfit', label: 'Amazfit', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'avon', label: 'Avon', parentSubCategory: ['fitness-trackers'] },
  { option: 'bally', label: 'Bally', parentSubCategory: ['fitness-trackers'] },
  { option: 'basis', label: 'Basis', parentSubCategory: ['fitness-trackers'] },
  { option: 'beurer', label: 'Beurer', parentSubCategory: ['fitness-trackers'] },
  { option: 'canyon', label: 'Canyon', parentSubCategory: ['fitness-trackers'] },
  { option: 'copper-fit', label: 'Copper Fit', parentSubCategory: ['fitness-trackers'] },
  { option: 'diggro', label: 'Diggro', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'ETC', label: 'ETC', parentSubCategory: ['fitness-trackers'] },
  { option: 'fitbit', label: 'Fitbit', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'fitbug', label: 'Fitbug', parentSubCategory: ['fitness-trackers'] },
  { option: 'Hi-Tec', label: 'Hi-Tec', parentSubCategory: ['fitness-trackers'] },
  { option: 'honor', label: 'Honor', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'iBitz', label: 'iBitz', parentSubCategory: ['fitness-trackers'] },
  { option: 'IOWODO', label: 'IOWODO', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'jawbone', label: 'Jawbone', parentSubCategory: ['fitness-trackers'] },
  { option: 'KINETIK', label: 'KINETIK', parentSubCategory: ['fitness-trackers'] },
  { option: 'letscom', label: 'Letscom', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'life-fitness', label: 'Life Fitness', parentSubCategory: ['fitness-trackers'] },
  { option: 'lifeline', label: 'Lifeline', parentSubCategory: ['fitness-trackers'] },
  {
    option: 'lintelek',
    label: 'Lintelek',
    parentSubCategory: ['fitness-trackers', 'smart-watches'],
  },
  { option: 'MEDISANA', label: 'MEDISANA', parentSubCategory: ['fitness-trackers'] },
  { option: 'mio', label: 'Mio', parentSubCategory: ['fitness-trackers'] },
  { option: 'misfit', label: 'Misfit', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'mpow', label: 'Mpow', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  {
    option: 'mykronoz',
    label: 'Mykronoz',
    parentSubCategory: ['fitness-trackers', 'smart-watches'],
  },
  { option: 'my-zone', label: 'My Zone', parentSubCategory: ['fitness-trackers'] },
  { option: 'NEXT', label: 'NEXT', parentSubCategory: ['fitness-trackers'] },
  { option: 'nike', label: 'Nike', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'nuband', label: 'Nuband', parentSubCategory: ['fitness-trackers'] },
  {
    option: 'oregon-scientific',
    label: 'Oregon Scientific',
    parentSubCategory: ['fitness-trackers'],
  },
  { option: 'polar', label: 'Polar', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'pro-fitness', label: 'Pro Fitness', parentSubCategory: ['fitness-trackers'] },
  { option: 'runtastic', label: 'Runtastic', parentSubCategory: ['fitness-trackers'] },
  { option: 'sigma', label: 'Sigma', parentSubCategory: ['fitness-trackers'] },
  { option: 'skagen', label: 'Skagen', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'soleus-air', label: 'Soleus Air', parentSubCategory: ['fitness-trackers'] },
  { option: 'spire', label: 'Spire', parentSubCategory: ['fitness-trackers'] },
  { option: 'sportline', label: 'Sportline', parentSubCategory: ['fitness-trackers'] },
  { option: 'suunto', label: 'Suunto', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'target-fitness', label: 'Target Fitness', parentSubCategory: ['fitness-trackers'] },
  { option: 'TomTom', label: 'TomTom', parentSubCategory: ['fitness-trackers', 'smart-watches'] },
  { option: 'trendy', label: 'Trendy', parentSubCategory: ['fitness-trackers'] },
  { option: 'under-armour', label: 'Under armour', parentSubCategory: ['fitness-trackers'] },
  { option: 'weGo', label: 'WeGo', parentSubCategory: ['fitness-trackers'] },
  { option: 'wesoo', label: 'Wesoo', parentSubCategory: ['fitness-trackers'] },
  { option: 'WILLFUL', label: 'WILLFUL', parentSubCategory: ['fitness-trackers'] },
  {
    option: 'withings',
    label: 'Withings',
    parentSubCategory: ['fitness-trackers', 'smart-watches'],
  },

  //
  { option: 'ABYX', label: 'ABYX', parentSubCategory: ['smart-watches'] },
  { option: 'AllCall', label: 'AllCall', parentSubCategory: ['smart-watches'] },
  { option: 'alpina', label: 'Alpina', parentSubCategory: ['smart-watches'] },
  { option: 'androidly', label: 'Androidly', parentSubCategory: ['smart-watches'] },
  { option: 'ANEKEN', label: 'ANEKEN', parentSubCategory: ['smart-watches'] },
  { option: 'blackview', label: 'Blackview', parentSubCategory: ['smart-watches'] },
  { option: 'burg', label: 'Burg', parentSubCategory: ['smart-watches'] },
  { option: 'chereeki', label: 'Chereeki', parentSubCategory: ['smart-watches'] },
  { option: 'citizen', label: 'Citizen', parentSubCategory: ['smart-watches'] },
  { option: 'cubot', label: 'Cubot', parentSubCategory: ['smart-watches'] },
  { option: 'delvfire', label: 'Delvfire', parentSubCategory: ['smart-watches'] },
  { option: 'diesel', label: 'Diesel', parentSubCategory: ['smart-watches'] },
  { option: 'doro', label: 'Doro', parentSubCategory: ['smart-watches'] },
  { option: 'elephone', label: 'Elephone', parentSubCategory: ['smart-watches'] },
  { option: 'emporio-armani', label: 'Emporio Armani', parentSubCategory: ['smart-watches'] },
  { option: 'fit-smart', label: 'Fit Smart', parentSubCategory: ['smart-watches'] },
  { option: 'fossil', label: 'Fossil', parentSubCategory: ['smart-watches'] },
  { option: 'GUESS', label: 'GUESS', parentSubCategory: ['smart-watches'] },
  { option: 'HOT Watch', label: 'HOT Watch', parentSubCategory: ['smart-watches'] },
  { option: 'I`m Watch', label: 'I`m Watch', parentSubCategory: ['smart-watches'] },
  {
    option: 'kate spade new york',
    label: 'kate spade new york',
    parentSubCategory: ['smart-watches'],
  },
  { option: 'kingwear', label: 'Kingwear', parentSubCategory: ['smart-watches'] },
  { option: 'kiwip', label: 'Kiwip', parentSubCategory: ['smart-watches'] },
  { option: 'kreyos', label: 'Kreyos', parentSubCategory: ['smart-watches'] },
  { option: 'kronaby', label: 'Kronaby', parentSubCategory: ['smart-watches'] },
  { option: 'kurio', label: 'Kurio', parentSubCategory: ['smart-watches'] },
  { option: 'LEMFO', label: 'LEMFO', parentSubCategory: ['smart-watches'] },
  { option: 'letsfit', label: 'Letsfit', parentSubCategory: ['smart-watches'] },
  { option: 'LOKMAT', label: 'LOKMAT', parentSubCategory: ['smart-watches'] },
  { option: 'michael-kors', label: 'Michael Kors', parentSubCategory: ['smart-watches'] },
  { option: 'mobvoi', label: 'Mobvoi', parentSubCategory: ['smart-watches'] },
  { option: 'montblanc', label: 'Montblanc', parentSubCategory: ['smart-watches'] },
  { option: 'neptune', label: 'Neptune', parentSubCategory: ['smart-watches'] },
  { option: 'new-balance', label: 'New Balance', parentSubCategory: ['smart-watches'] },
  { option: 'nixon', label: 'Nixon', parentSubCategory: ['smart-watches'] },
  { option: 'omate', label: 'Omate', parentSubCategory: ['smart-watches'] },
  { option: 'OUKITEL', label: 'OUKITEL', parentSubCategory: ['smart-watches'] },
  { option: 'PadgeNE', label: 'PadgeNE', parentSubCategory: ['smart-watches'] },
  { option: 'pebble', label: 'Pebble', parentSubCategory: ['smart-watches'] },
  { option: 'PUMA', label: 'PUMA', parentSubCategory: ['smart-watches'] },
  { option: 'qualcomm', label: 'Qualcomm', parentSubCategory: ['smart-watches'] },
  { option: 'RWATCH', label: 'RWATCH', parentSubCategory: ['smart-watches'] },
  { option: 'semaco', label: 'Semaco', parentSubCategory: ['smart-watches'] },
  { option: 'SKMEI', label: 'SKMEI', parentSubCategory: ['smart-watches'] },
  {
    option: 'TAG Heuer',
    label: 'TAG Heuer',
    parentSubCategory: ['smart-watches'],
  },
  { option: 'TECKEPIC', label: 'TECKEPIC', parentSubCategory: ['smart-watches'] },
  { option: 'The No.1', label: 'The No.1', parentSubCategory: ['smart-watches'] },
  { option: 'timex', label: 'Timex', parentSubCategory: ['smart-watches'] },
  { option: 'titan', label: 'Titan', parentSubCategory: ['smart-watches'] },
  { option: 'tommy-hilfiger', label: 'Tommy Hilfiger', parentSubCategory: ['smart-watches'] },
  { option: 'UMIDIGI', label: 'UMIDIGI', parentSubCategory: ['smart-watches'] },
  { option: 'unbranded', label: 'Unbranded', parentSubCategory: ['smart-watches'] },
  { option: 'U Watch', label: 'U Watch', parentSubCategory: ['smart-watches'] },
  { option: 'vidonn', label: 'Vidonn', parentSubCategory: ['smart-watches'] },
  { option: 'VTech', label: 'VTech', parentSubCategory: ['smart-watches'] },
  { option: 'WEE`PLUG', label: 'WEE`PLUG', parentSubCategory: ['smart-watches'] },
  { option: 'willful', label: 'Willful', parentSubCategory: ['smart-watches'] },
  { option: 'XCSOURCE', label: 'XCSOURCE', parentSubCategory: ['smart-watches'] },
  { option: 'yamay', label: 'Yamay', parentSubCategory: ['smart-watches'] },
  {
    option: 'ZEBLAZE',
    label: 'ZEBLAZE',
    parentSubCategory: ['smart-watches'],
  },
  { option: 'supcase', label: 'Supcase', parentSubCategory: ['smart-watch-accessories'] },
  { option: 'MoKo', label: 'MoKo', parentSubCategory: ['smart-watch-accessories'] },
  { option: 'fintie', label: 'Fintie', parentSubCategory: ['smart-watch-accessories'] },
  { option: 'orzly', label: 'Orzly', parentSubCategory: ['smart-watch-accessories'] },
  { option: 'raptic', label: 'Raptic', parentSubCategory: ['smart-watch-accessories'] },
  { option: 'rinoGear', label: 'RinoGear', parentSubCategory: ['smart-watch-accessories'] },
  { option: 'armorSuit', label: 'ArmorSuit', parentSubCategory: ['smart-watch-accessories'] },
  { option: 'IQShield', label: 'IQShield', parentSubCategory: ['smart-watch-accessories'] },
  { option: 'qualcomm', label: 'Qualcomm', parentSubCategory: ['smart-watch-accessories'] },
  { option: 'bose', label: 'Bose', parentSubCategory: ['headphones', 'camcorders'] },
  { option: 'sennheiser', label: 'Sennheiser', parentSubCategory: ['headphones'] },
  { option: 'Beats by Dre', label: 'Beats by Dre', parentSubCategory: ['headphones'] },
  { option: 'JBL', label: 'JBL', parentSubCategory: ['headphones'] },
  { option: 'audio-technica', label: 'Audio-Technica', parentSubCategory: ['headphones'] },
  { option: 'AKG', label: 'AKG', parentSubCategory: ['headphones'] },
  {
    option: 'Bang & Olufsen (B&O)',
    label: 'Bang & Olufsen (B&O)',
    parentSubCategory: ['headphones'],
  },
  { option: 'skullcandy', label: 'Skullcandy', parentSubCategory: ['headphones'] },
  { option: 'shure', label: 'Shure', parentSubCategory: ['headphones'] },
  { option: 'Apple (iPod)', label: 'Apple (iPod)', parentSubCategory: ['mP3-players'] },
  { option: 'AGPTEK', label: 'AGPTEK', parentSubCategory: ['mP3-players'] },
  { option: 'FiiO', label: 'FiiO', parentSubCategory: ['mP3-players'] },
  { option: 'ruizu', label: 'Ruizu', parentSubCategory: ['mP3-players'] },
  { option: 'HIFI WALKER', label: 'HIFI WALKER', parentSubCategory: ['mP3-players'] },
  { option: 'pioneer', label: 'Pioneer', parentSubCategory: ['mP3-players'] },
  { option: 'ONN', label: 'ONN', parentSubCategory: ['mP3-players'] },
  { option: 'acme', label: 'Acme', parentSubCategory: ['camcorders'] },
  { option: 'action', label: 'Action', parentSubCategory: ['camcorders'] },
  { option: 'ACTIONPRO', label: 'ACTIONPRO', parentSubCategory: ['camcorders'] },
  { option: 'activeon', label: 'Activeon', parentSubCategory: ['camcorders'] },
  { option: 'AEE', label: 'AEE', parentSubCategory: ['camcorders'] },
  { option: 'agfa', label: 'Agfa', parentSubCategory: ['camcorders'] },
  { option: 'agfaPhoto', label: 'AgfaPhoto', parentSubCategory: ['camcorders'] },
  { option: 'aiptek', label: 'Aiptek', parentSubCategory: ['camcorders'] },
  { option: 'AJA', label: 'AJA', parentSubCategory: ['camcorders'] },
  { option: 'akai', label: 'Akai', parentSubCategory: ['camcorders'] },
  { option: 'AKASO', label: 'AKASO', parentSubCategory: ['camcorders'] },
  { option: 'AMKOV', label: 'AMKOV', parentSubCategory: ['camcorders'] },
  { option: 'andoer', label: 'Andoer', parentSubCategory: ['camcorders'] },
  { option: 'APEMAN', label: 'APEMAN', parentSubCategory: ['camcorders'] },
  { option: 'archos', label: 'Archos', parentSubCategory: ['camcorders'] },
  { option: 'ARRI', label: 'ARRI', parentSubCategory: ['camcorders'] },
  { option: 'atomos', label: 'Atomos', parentSubCategory: ['camcorders'] },
  { option: 'bauer', label: 'Bauer', parentSubCategory: ['camcorders'] },
  { option: 'beachTek', label: 'BeachTek', parentSubCategory: ['camcorders'] },
  { option: 'Bell and Howell', label: 'Bell and Howell', parentSubCategory: ['camcorders'] },
  { option: 'Besteker', label: 'Besteker', parentSubCategory: ['camcorders'] },
  { option: 'blackmagic', label: 'Blackmagic', parentSubCategory: ['camcorders'] },
  { option: 'blackmagic-design', label: 'Blackmagic Design', parentSubCategory: ['camcorders'] },
  { option: 'blaupunkt', label: 'Blaupunkt', parentSubCategory: ['camcorders'] },

  //
  { option: 'boblov', label: 'Boblov', parentSubCategory: ['camcorders'] },
  { option: 'BRESSER', label: 'BRESSER', parentSubCategory: ['camcorders'] },
  { option: 'brinno', label: 'Brinno', parentSubCategory: ['camcorders'] },
  { option: 'bushnell', label: 'Bushnell', parentSubCategory: ['camcorders'] },
  { option: 'camlink', label: 'Camlink', parentSubCategory: ['camcorders'] },
  { option: 'campark', label: 'Campark', parentSubCategory: ['camcorders'] },
  { option: 'cannon', label: 'Cannon', parentSubCategory: ['camcorders'] },
  { option: 'canon', label: 'Canon', parentSubCategory: ['camcorders'] },
  { option: 'case', label: 'Case', parentSubCategory: ['camcorders'] },
  { option: 'cobra', label: 'Cobra', parentSubCategory: ['camcorders'] },
  { option: 'cofunkool', label: 'Cofunkool', parentSubCategory: ['camcorders'] },
  { option: 'contour', label: 'Contour', parentSubCategory: ['camcorders'] },

  //
  { option: 'Crosstour', label: 'Crosstour', parentSubCategory: ['camcorders'] },
  { option: 'Cuddeback', label: 'Cuddeback', parentSubCategory: ['camcorders'] },
  { option: 'DB Power', label: 'DB Power', parentSubCategory: ['camcorders'] },
  { option: 'Discovery Kids', label: 'Discovery Kids', parentSubCategory: ['camcorders'] },
  { option: 'DJI', label: 'DJI', parentSubCategory: ['camcorders'] },
  { option: 'D-Link', label: 'D-Link', parentSubCategory: ['camcorders'] },
  { option: 'Dörr', label: 'Dörr', parentSubCategory: ['camcorders'] },
  { option: 'Dragon Touch', label: 'Dragon Touch', parentSubCategory: ['camcorders'] },
  { option: 'Drift', label: 'Drift', parentSubCategory: ['camcorders'] },
  { option: 'Drift Innovation', label: 'Drift Innovation', parentSubCategory: ['camcorders'] },
  { option: 'Duracell', label: 'Duracell', parentSubCategory: ['camcorders'] },
  { option: 'Easypix', label: 'Easypix', parentSubCategory: ['camcorders'] },
  { option: 'EE', label: 'EE', parentSubCategory: ['camcorders'] },
  { option: 'Eken', label: 'Eken', parentSubCategory: ['camcorders'] },
  { option: 'Elephone', label: 'Elephone', parentSubCategory: ['camcorders'] },
  { option: 'Excelvan', label: 'Excelvan', parentSubCategory: ['camcorders'] },
  { option: 'Explorer', label: 'Explorer', parentSubCategory: ['camcorders'] },
  { option: 'FeiyuTech', label: 'FeiyuTech', parentSubCategory: ['camcorders'] },
  { option: 'Flip', label: 'Flip', parentSubCategory: ['camcorders'] },
  { option: 'Flip Video', label: 'Flip Video', parentSubCategory: ['camcorders'] },
  { option: 'Fox', label: 'Fox', parentSubCategory: ['camcorders'] },
  { option: 'Fuijifilm', label: 'Fuijifilm', parentSubCategory: ['camcorders'] },
  { option: 'Fuji', label: 'Fuji', parentSubCategory: ['camcorders'] },
  { option: 'Fujifilm', label: 'Fujifilm', parentSubCategory: ['camcorders'] },
  { option: 'Fujinon', label: 'Fujinon', parentSubCategory: ['camcorders'] },
  { option: 'Garmin', label: 'Garmin', parentSubCategory: ['camcorders'] },
  { option: 'Gembird', label: 'Gembird', parentSubCategory: ['camcorders'] },
  { option: 'Genius', label: 'Genius', parentSubCategory: ['camcorders'] },
  { option: 'Google', label: 'Google', parentSubCategory: ['camcorders'] },
  { option: 'GoPro', label: 'GoPro', parentSubCategory: ['camcorders'] },
  { option: 'Grundig', label: 'Grundig', parentSubCategory: ['camcorders'] },
  { option: 'H & H', label: 'H & H', parentSubCategory: ['camcorders'] },
  { option: 'Hama', label: 'Hama', parentSubCategory: ['camcorders'] },
  { option: 'Hitachi', label: 'Hitachi', parentSubCategory: ['camcorders'] },
  { option: 'Honda', label: 'Honda', parentSubCategory: ['camcorders'] },
  { option: 'HP', label: 'HP', parentSubCategory: ['camcorders'] },
  { option: 'Huawei', label: 'Huawei', parentSubCategory: ['camcorders'] },
  { option: 'Ikegami', label: 'Ikegami', parentSubCategory: ['camcorders'] },
  { option: 'ILFORD', label: 'ILFORD', parentSubCategory: ['camcorders'] },
  { option: 'Insta360', label: 'Insta360', parentSubCategory: ['camcorders'] },
  { option: 'ION', label: 'ION', parentSubCategory: ['camcorders'] },
  { option: 'JVC', label: 'JVC', parentSubCategory: ['camcorders'] },
  { option: 'Kaiser Baas', label: 'Kaiser Baas', parentSubCategory: ['camcorders'] },
  { option: 'Kenwood', label: 'Kenwood', parentSubCategory: ['camcorders'] },
  { option: 'Kitvision', label: 'Kitvision', parentSubCategory: ['camcorders'] },
  { option: 'Kodak', label: 'Kodak', parentSubCategory: ['camcorders'] },
  { option: 'Konig', label: 'Konig', parentSubCategory: ['camcorders'] },
  { option: 'LEGO', label: 'LEGO', parentSubCategory: ['camcorders'] },
  { option: 'Lexibook', label: 'Lexibook', parentSubCategory: ['camcorders'] },
  { option: 'Liquid Image', label: 'Liquid Image', parentSubCategory: ['camcorders'] },
  { option: 'Logitech', label: 'Logitech', parentSubCategory: ['camcorders'] },
  { option: 'Luminox', label: 'Luminox', parentSubCategory: ['camcorders'] },
  { option: 'Manfrotto', label: 'Manfrotto', parentSubCategory: ['camcorders'] },
  { option: 'Maxell', label: 'Maxell', parentSubCategory: ['camcorders'] },
  { option: 'MEDION', label: 'MEDION', parentSubCategory: ['camcorders'] },
  { option: 'Mevo', label: 'Mevo', parentSubCategory: ['camcorders'] },
  { option: 'Midland', label: 'Midland', parentSubCategory: ['camcorders'] },
  { option: 'MINI', label: 'MINI', parentSubCategory: ['camcorders'] },
  { option: 'Minolta', label: 'Minolta', parentSubCategory: ['camcorders'] },
  { option: 'MINOX', label: 'MINOX', parentSubCategory: ['camcorders'] },
  { option: 'Mitsubishi', label: 'Mitsubishi', parentSubCategory: ['camcorders'] },
  { option: 'Motorola', label: 'Motorola', parentSubCategory: ['camcorders'] },
  { option: 'Mustek', label: 'Mustek', parentSubCategory: ['camcorders'] },
  { option: 'NEC', label: 'NEC', parentSubCategory: ['camcorders'] },
  { option: 'Neewer', label: 'Neewer', parentSubCategory: ['camcorders'] },
  { option: 'NEXTBASE', label: 'NEXTBASE', parentSubCategory: ['camcorders'] },
  { option: 'Nikon', label: 'Nikon', parentSubCategory: ['camcorders'] },
  { option: 'Nilox', label: 'Nilox', parentSubCategory: ['camcorders'] },
  { option: 'Olympus', label: 'Olympus', parentSubCategory: ['camcorders'] },
  { option: 'Ordro', label: 'Ordro', parentSubCategory: ['camcorders'] },
  { option: 'Oregon Scientific', label: 'Oregon Scientific', parentSubCategory: ['camcorders'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['camcorders'] },
  { option: 'Panoramic', label: 'Panoramic', parentSubCategory: ['camcorders'] },
  { option: 'PATONA', label: 'PATONA', parentSubCategory: ['camcorders'] },
  { option: 'PENTAX', label: 'PENTAX', parentSubCategory: ['camcorders'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['camcorders'] },
  { option: 'Pinnacle', label: 'Pinnacle', parentSubCategory: ['camcorders'] },
  { option: 'Pioneer', label: 'Pioneer', parentSubCategory: ['camcorders'] },
  { option: 'Polaroid', label: 'Polaroid', parentSubCategory: ['camcorders'] },
  { option: 'Porta-Brace', label: 'Porta-Brace', parentSubCategory: ['camcorders'] },
  { option: 'PRAKTICA', label: 'PRAKTICA', parentSubCategory: ['camcorders'] },
  { option: 'PRO', label: 'PRO', parentSubCategory: ['camcorders'] },
  { option: 'Quasar', label: 'Quasar', parentSubCategory: ['camcorders'] },
  { option: 'RCA', label: 'RCA', parentSubCategory: ['camcorders'] },
  { option: 'Red', label: 'Red', parentSubCategory: ['camcorders'] },
  { option: 'Removu', label: 'Removu', parentSubCategory: ['camcorders'] },
  { option: 'Replay', label: 'Replay', parentSubCategory: ['camcorders'] },
  { option: 'Ricoh', label: 'Ricoh', parentSubCategory: ['camcorders'] },
  { option: 'Rollei', label: 'Rollei', parentSubCategory: ['camcorders'] },
  { option: 'Ross', label: 'Ross', parentSubCategory: ['camcorders'] },
  { option: 'Samsung', label: 'Samsung', parentSubCategory: ['camcorders'] },
  { option: 'Samurai', label: 'Samurai', parentSubCategory: ['camcorders'] },
  { option: 'SANYO', label: 'SANYO', parentSubCategory: ['camcorders'] },
  { option: 'SHAPE', label: 'SHAPE', parentSubCategory: ['camcorders'] },
  { option: 'Sharp', label: 'Sharp', parentSubCategory: ['camcorders'] },
  { option: 'Shimano', label: 'Shimano', parentSubCategory: ['camcorders'] },
  { option: 'Silvercrest', label: 'Silvercrest', parentSubCategory: ['camcorders'] },
  { option: 'Sima', label: 'Sima', parentSubCategory: ['camcorders'] },
  { option: 'SIRUI', label: 'SIRUI', parentSubCategory: ['camcorders'] },
  { option: 'SJCAM', label: 'SJCAM', parentSubCategory: ['camcorders'] },
  { option: 'SOLOSHOT', label: 'SOLOSHOT', parentSubCategory: ['camcorders'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['camcorders'] },
  { option: 'Sony Ericsson', label: 'Sony Ericsson', parentSubCategory: ['camcorders'] },
  { option: 'SOOCOO', label: 'SOOCOO', parentSubCategory: ['camcorders'] },
  { option: 'Speed', label: 'Speed', parentSubCategory: ['camcorders'] },
  { option: 'Spy Gear', label: 'Spy Gear', parentSubCategory: ['camcorders'] },
  { option: 'SPYPOINT', label: 'SPYPOINT', parentSubCategory: ['camcorders'] },
  { option: 'SVP', label: 'SVP', parentSubCategory: ['camcorders'] },
  { option: 'Swann', label: 'Swann', parentSubCategory: ['camcorders'] },
  { option: 'SYLVANIA', label: 'SYLVANIA', parentSubCategory: ['camcorders'] },
  { option: "T'nB", label: "T'nB", parentSubCategory: ['camcorders'] },
  { option: 'Tamrac', label: 'Tamrac', parentSubCategory: ['camcorders'] },
  { option: 'TDK', label: 'TDK', parentSubCategory: ['camcorders'] },
  { option: 'Technaxx', label: 'Technaxx', parentSubCategory: ['camcorders'] },
  { option: 'TELEFUNKEN', label: 'TELEFUNKEN', parentSubCategory: ['camcorders'] },
  { option: 'TFT', label: 'TFT', parentSubCategory: ['camcorders'] },
  { option: 'Thieye', label: 'Thieye', parentSubCategory: ['camcorders'] },
  { option: 'Thomson', label: 'Thomson', parentSubCategory: ['camcorders'] },
  { option: 'TomTom', label: 'TomTom', parentSubCategory: ['camcorders'] },
  { option: 'Toshiba', label: 'Toshiba', parentSubCategory: ['camcorders'] },
  { option: 'Transcend', label: 'Transcend', parentSubCategory: ['camcorders'] },
  { option: 'Traveler', label: 'Traveler', parentSubCategory: ['camcorders'] },
  { option: 'Trust', label: 'Trust', parentSubCategory: ['camcorders'] },
  { option: 'Ultra', label: 'Ultra', parentSubCategory: ['camcorders'] },
  { option: 'Unbranded', label: 'Unbranded', parentSubCategory: ['camcorders'] },
  { option: 'Vanguard', label: 'Vanguard', parentSubCategory: ['camcorders'] },
  { option: 'VariZoom', label: 'VariZoom', parentSubCategory: ['camcorders'] },
  { option: 'Veho', label: 'Veho', parentSubCategory: ['camcorders'] },
  { option: 'VIBE', label: 'VIBE', parentSubCategory: ['camcorders'] },
  { option: 'Victure', label: 'Victure', parentSubCategory: ['camcorders'] },
  { option: 'VIO', label: 'VIO', parentSubCategory: ['camcorders'] },
  { option: 'Vivitar', label: 'Vivitar', parentSubCategory: ['camcorders'] },
  { option: 'VMC', label: 'VMC', parentSubCategory: ['camcorders'] },
  { option: 'VTech', label: 'VTech', parentSubCategory: ['camcorders'] },
  { option: 'Walimex', label: 'Walimex', parentSubCategory: ['camcorders'] },
  { option: 'WASPcam', label: 'WASPcam', parentSubCategory: ['camcorders'] },
  { option: 'X2', label: 'X2', parentSubCategory: ['camcorders'] },
  { option: 'Xiaomi', label: 'Xiaomi', parentSubCategory: ['camcorders'] },
  { option: 'Yashica', label: 'Yashica', parentSubCategory: ['camcorders'] },
  { option: 'YI', label: 'YI', parentSubCategory: ['camcorders'] },
  { option: 'Zoom', label: 'Zoom', parentSubCategory: ['camcorders'] },
  { option: 'Manfrotto', label: 'Manfrotto', parentSubCategory: ['camera-photo-accessories'] },
  { option: 'Lowepro', label: 'Lowepro', parentSubCategory: ['camera-photo-accessories'] },
  { option: 'Joby', label: 'Joby', parentSubCategory: ['camera-photo-accessories'] },
  { option: 'Peak Design', label: 'Peak Design', parentSubCategory: ['camera-photo-accessories'] },
  {
    option: 'Think Tank Photo',
    label: 'Think Tank Photo',
    parentSubCategory: ['camera-photo-accessories'],
  },
  { option: 'Vanguard', label: 'Vanguard', parentSubCategory: ['camera-photo-accessories'] },
  { option: 'Hoya', label: 'Hoya', parentSubCategory: ['camera-photo-accessories'] },
  { option: 'LensPen', label: 'LensPen', parentSubCategory: ['camera-photo-accessories'] },
  { option: 'Neewer', label: 'Neewer', parentSubCategory: ['camera-photo-accessories'] },
  { option: 'Rode', label: 'Rode', parentSubCategory: ['camera-photo-accessories'] },
  { option: '2Chilly', label: '2Chilly', parentSubCategory: ['digital-cameras'] },
  { option: '360Fly', label: '360Fly', parentSubCategory: ['digital-cameras'] },
  { option: 'Acer', label: 'Acer', parentSubCategory: ['digital-cameras'] },
  { option: 'Acorn', label: 'Acorn', parentSubCategory: ['digital-cameras'] },
  { option: 'Action', label: 'Action', parentSubCategory: ['digital-cameras'] },
  { option: 'ACTIONPRO', label: 'ACTIONPRO', parentSubCategory: ['digital-cameras'] },
  { option: 'AEE', label: 'AEE', parentSubCategory: ['digital-cameras'] },
  { option: 'Agfa', label: 'Agfa', parentSubCategory: ['digital-cameras'] },
  { option: 'AgfaPhoto', label: 'AgfaPhoto', parentSubCategory: ['digital-cameras'] },
  { option: 'Aiptek', label: 'Aiptek', parentSubCategory: ['digital-cameras'] },
  { option: 'AKASO', label: 'AKASO', parentSubCategory: ['digital-cameras'] },
  { option: 'AmazonBasics', label: 'AmazonBasics', parentSubCategory: ['digital-cameras'] },
  { option: 'AMKOV', label: 'AMKOV', parentSubCategory: ['digital-cameras'] },
  { option: 'Andoer', label: 'Andoer', parentSubCategory: ['digital-cameras'] },
  { option: 'ANNKE', label: 'ANNKE', parentSubCategory: ['digital-cameras'] },
  { option: 'APEMAN', label: 'APEMAN', parentSubCategory: ['digital-cameras'] },
  { option: 'Apex', label: 'Apex', parentSubCategory: ['digital-cameras'] },
  { option: 'Apple', label: 'Apple', parentSubCategory: ['digital-cameras'] },
  { option: 'Aputure', label: 'Aputure', parentSubCategory: ['digital-cameras'] },
  { option: 'Argus', label: 'Argus', parentSubCategory: ['digital-cameras'] },
  { option: 'Arlo', label: 'Arlo', parentSubCategory: ['digital-cameras'] },
  { option: 'ARRI', label: 'ARRI', parentSubCategory: ['digital-cameras'] },
  { option: 'Axis', label: 'Axis', parentSubCategory: ['digital-cameras'] },
  { option: 'Bell and Howell', label: 'Bell and Howell', parentSubCategory: ['digital-cameras'] },
  { option: 'BenQ', label: 'BenQ', parentSubCategory: ['digital-cameras'] },
  { option: 'Benro', label: 'Benro', parentSubCategory: ['digital-cameras'] },
  { option: 'Blackmagic', label: 'Blackmagic', parentSubCategory: ['digital-cameras'] },
  {
    option: 'Blackmagic Design',
    label: 'Blackmagic Design',
    parentSubCategory: ['digital-cameras'],
  },
  { option: 'Bosch', label: 'Bosch', parentSubCategory: ['digital-cameras'] },
  { option: 'Bower', label: 'Bower', parentSubCategory: ['digital-cameras'] },
  { option: 'BRESSER', label: 'BRESSER', parentSubCategory: ['digital-cameras'] },
  { option: 'Brinno', label: 'Brinno', parentSubCategory: ['digital-cameras'] },
  { option: 'Brookstone', label: 'Brookstone', parentSubCategory: ['digital-cameras'] },
  { option: 'Browning', label: 'Browning', parentSubCategory: ['digital-cameras'] },
  { option: 'Bushnell', label: 'Bushnell', parentSubCategory: ['digital-cameras'] },
  { option: 'Cambo', label: 'Cambo', parentSubCategory: ['digital-cameras'] },
  { option: 'Campark', label: 'Campark', parentSubCategory: ['digital-cameras'] },
  { option: 'Canon', label: 'Canon', parentSubCategory: ['digital-cameras'] },
  { option: 'Carl Zeiss', label: 'Carl Zeiss', parentSubCategory: ['digital-cameras'] },
  { option: 'Casio', label: 'Casio', parentSubCategory: ['digital-cameras'] },
  { option: 'Chinon', label: 'Chinon', parentSubCategory: ['digital-cameras'] },
  { option: 'Cobra', label: 'Cobra', parentSubCategory: ['digital-cameras'] },
  { option: 'Concord', label: 'Concord', parentSubCategory: ['digital-cameras'] },
  { option: 'Contax', label: 'Contax', parentSubCategory: ['digital-cameras'] },
  { option: 'Contour', label: 'Contour', parentSubCategory: ['digital-cameras'] },
  { option: 'Crayola', label: 'Crayola', parentSubCategory: ['digital-cameras'] },
  { option: 'Creative', label: 'Creative', parentSubCategory: ['digital-cameras'] },
  { option: 'Crosstour', label: 'Crosstour', parentSubCategory: ['digital-cameras'] },
  { option: 'CULLMANN', label: 'CULLMANN', parentSubCategory: ['digital-cameras'] },
  { option: 'Datavideo', label: 'Datavideo', parentSubCategory: ['digital-cameras'] },
  { option: 'Defender', label: 'Defender', parentSubCategory: ['digital-cameras'] },
  { option: 'Dell', label: 'Dell', parentSubCategory: ['digital-cameras'] },
  { option: 'DENVER', label: 'DENVER', parentSubCategory: ['digital-cameras'] },
  { option: 'Digital Blue', label: 'Digital Blue', parentSubCategory: ['digital-cameras'] },
  { option: 'Digital Concepts', label: 'Digital Concepts', parentSubCategory: ['digital-cameras'] },
  { option: 'Discovery Kids', label: 'Discovery Kids', parentSubCategory: ['digital-cameras'] },
  { option: 'Disney', label: 'Disney', parentSubCategory: ['digital-cameras'] },
  { option: 'DJI', label: 'DJI', parentSubCategory: ['digital-cameras'] },
  { option: 'Dörr', label: 'Dörr', parentSubCategory: ['digital-cameras'] },
  { option: 'Dragon Touch', label: 'Dragon Touch', parentSubCategory: ['digital-cameras'] },
  { option: 'DSC', label: 'DSC', parentSubCategory: ['digital-cameras'] },
  { option: 'Duracell', label: 'Duracell', parentSubCategory: ['digital-cameras'] },
  { option: 'Dxo', label: 'Dxo', parentSubCategory: ['digital-cameras'] },
  { option: 'Easypix', label: 'Easypix', parentSubCategory: ['digital-cameras'] },
  { option: 'Elmo', label: 'Elmo', parentSubCategory: ['digital-cameras'] },
  { option: 'Energizer', label: 'Energizer', parentSubCategory: ['digital-cameras'] },
  { option: 'Energy Sistem', label: 'Energy Sistem', parentSubCategory: ['digital-cameras'] },
  { option: 'Epson', label: 'Epson', parentSubCategory: ['digital-cameras'] },
  { option: 'Excelvan', label: 'Excelvan', parentSubCategory: ['digital-cameras'] },
  { option: 'Ex-Pro', label: 'Ex-Pro', parentSubCategory: ['digital-cameras'] },
  { option: 'FeiyuTech', label: 'FeiyuTech', parentSubCategory: ['digital-cameras'] },
  { option: 'Fisher-Price', label: 'Fisher-Price', parentSubCategory: ['digital-cameras'] },
  { option: 'Flip Video', label: 'Flip Video', parentSubCategory: ['digital-cameras'] },
  { option: 'Flir', label: 'Flir', parentSubCategory: ['digital-cameras'] },
  { option: 'Floureon', label: 'Floureon', parentSubCategory: ['digital-cameras'] },
  { option: 'Focus', label: 'Focus', parentSubCategory: ['digital-cameras'] },
  { option: 'Fuji', label: 'Fuji', parentSubCategory: ['digital-cameras'] },
  { option: 'Fujifilm', label: 'Fujifilm', parentSubCategory: ['digital-cameras'] },
  { option: 'Fujinon', label: 'Fujinon', parentSubCategory: ['digital-cameras'] },
  { option: 'Fujitsu', label: 'Fujitsu', parentSubCategory: ['digital-cameras'] },
  { option: 'Gateway', label: 'Gateway', parentSubCategory: ['digital-cameras'] },
  { option: 'GE', label: 'GE', parentSubCategory: ['digital-cameras'] },
  { option: 'Gitzo', label: 'Gitzo', parentSubCategory: ['digital-cameras'] },
  { option: 'Goodmans', label: 'Goodmans', parentSubCategory: ['digital-cameras'] },
  { option: 'GoPro', label: 'GoPro', parentSubCategory: ['digital-cameras'] },
  { option: 'Hama', label: 'Hama', parentSubCategory: ['digital-cameras'] },
  { option: 'Hasselblad', label: 'Hasselblad', parentSubCategory: ['digital-cameras'] },
  { option: 'Hello Kitty', label: 'Hello Kitty', parentSubCategory: ['digital-cameras'] },
  { option: 'Hitachi', label: 'Hitachi', parentSubCategory: ['digital-cameras'] },
  { option: 'Horseman', label: 'Horseman', parentSubCategory: ['digital-cameras'] },
  { option: 'Hoya', label: 'Hoya', parentSubCategory: ['digital-cameras'] },
  { option: 'HP', label: 'HP', parentSubCategory: ['digital-cameras'] },
  { option: 'Ikelite', label: 'Ikelite', parentSubCategory: ['digital-cameras'] },
  { option: 'ILFORD', label: 'ILFORD', parentSubCategory: ['digital-cameras'] },
  { option: 'Innovage', label: 'Innovage', parentSubCategory: ['digital-cameras'] },
  { option: 'Insta360', label: 'Insta360', parentSubCategory: ['digital-cameras'] },
  { option: 'Intel', label: 'Intel', parentSubCategory: ['digital-cameras'] },
  { option: 'Intova', label: 'Intova', parentSubCategory: ['digital-cameras'] },
  { option: 'ION', label: 'ION', parentSubCategory: ['digital-cameras'] },
  { option: 'IRIS', label: 'IRIS', parentSubCategory: ['digital-cameras'] },
  { option: 'JayTech', label: 'JayTech', parentSubCategory: ['digital-cameras'] },
  { option: 'Jazz', label: 'Jazz', parentSubCategory: ['digital-cameras'] },
  { option: 'JENOPTIK', label: 'JENOPTIK', parentSubCategory: ['digital-cameras'] },
  { option: 'JOBY', label: 'JOBY', parentSubCategory: ['digital-cameras'] },
  { option: 'JVC', label: 'JVC', parentSubCategory: ['digital-cameras'] },
  { option: 'K&F Concept', label: 'K&F Concept', parentSubCategory: ['digital-cameras'] },
  { option: 'Kaiser', label: 'Kaiser', parentSubCategory: ['digital-cameras'] },
  { option: 'Keep Out', label: 'Keep Out', parentSubCategory: ['digital-cameras'] },
  { option: 'Kenko', label: 'Kenko', parentSubCategory: ['digital-cameras'] },
  { option: 'Kenwood', label: 'Kenwood', parentSubCategory: ['digital-cameras'] },
  { option: 'Kitvision', label: 'Kitvision', parentSubCategory: ['digital-cameras'] },
  { option: 'Kodak', label: 'Kodak', parentSubCategory: ['digital-cameras'] },
  { option: 'Konica Minolta', label: 'Konica Minolta', parentSubCategory: ['digital-cameras'] },
  { option: 'Kyocera', label: 'Kyocera', parentSubCategory: ['digital-cameras'] },
  { option: 'LaCie', label: 'LaCie', parentSubCategory: ['digital-cameras'] },
  { option: 'Leaf', label: 'Leaf', parentSubCategory: ['digital-cameras'] },
  { option: 'LEGO', label: 'LEGO', parentSubCategory: ['digital-cameras'] },
  { option: 'Leica', label: 'Leica', parentSubCategory: ['digital-cameras'] },
  { option: 'Lenovo', label: 'Lenovo', parentSubCategory: ['digital-cameras'] },
  { option: 'Lexibook', label: 'Lexibook', parentSubCategory: ['digital-cameras'] },
  { option: 'LG', label: 'LG', parentSubCategory: ['digital-cameras'] },
  { option: 'Liquid Image', label: 'Liquid Image', parentSubCategory: ['digital-cameras'] },
  { option: 'Little Tikes', label: 'Little Tikes', parentSubCategory: ['digital-cameras'] },
  { option: 'Logitech', label: 'Logitech', parentSubCategory: ['digital-cameras'] },
  { option: 'Lomography', label: 'Lomography', parentSubCategory: ['digital-cameras'] },
  { option: 'Lowepro', label: 'Lowepro', parentSubCategory: ['digital-cameras'] },
  { option: 'Ltl Acorn', label: 'Ltl Acorn', parentSubCategory: ['digital-cameras'] },
  { option: 'Lumix', label: 'Lumix', parentSubCategory: ['digital-cameras'] },
  { option: 'MAGINON', label: 'MAGINON', parentSubCategory: ['digital-cameras'] },
  { option: 'Mamiya', label: 'Mamiya', parentSubCategory: ['digital-cameras'] },
  { option: 'Manfrotto', label: 'Manfrotto', parentSubCategory: ['digital-cameras'] },
  { option: 'mantona', label: 'mantona', parentSubCategory: ['digital-cameras'] },
  {
    option: 'Marshall Electronics',
    label: 'Marshall Electronics',
    parentSubCategory: ['digital-cameras'],
  },
  { option: 'Mattel', label: 'Mattel', parentSubCategory: ['digital-cameras'] },
  { option: 'Maxell', label: 'Maxell', parentSubCategory: ['digital-cameras'] },
  { option: 'MEDION', label: 'MEDION', parentSubCategory: ['digital-cameras'] },
  { option: 'Mercury', label: 'Mercury', parentSubCategory: ['digital-cameras'] },
  { option: 'Metz', label: 'Metz', parentSubCategory: ['digital-cameras'] },
  { option: 'Microsoft', label: 'Microsoft', parentSubCategory: ['digital-cameras'] },
  { option: 'Microtek', label: 'Microtek', parentSubCategory: ['digital-cameras'] },
  { option: 'Midland', label: 'Midland', parentSubCategory: ['digital-cameras'] },
  { option: 'Minolta', label: 'Minolta', parentSubCategory: ['digital-cameras'] },
  { option: 'MINOX', label: 'MINOX', parentSubCategory: ['digital-cameras'] },
  { option: 'Mitutoyo', label: 'Mitutoyo', parentSubCategory: ['digital-cameras'] },
  { option: 'Moultrie', label: 'Moultrie', parentSubCategory: ['digital-cameras'] },
  { option: 'Mustek', label: 'Mustek', parentSubCategory: ['digital-cameras'] },
  { option: 'NETGEAR', label: 'NETGEAR', parentSubCategory: ['digital-cameras'] },
  { option: 'Nikkon', label: 'Nikkon', parentSubCategory: ['digital-cameras'] },
  { option: 'NIKKOR', label: 'NIKKOR', parentSubCategory: ['digital-cameras'] },
  { option: 'Nikon', label: 'Nikon', parentSubCategory: ['digital-cameras'] },
  { option: 'Nilox', label: 'Nilox', parentSubCategory: ['digital-cameras'] },
  { option: 'Nippon', label: 'Nippon', parentSubCategory: ['digital-cameras'] },
  { option: 'Nokia', label: 'Nokia', parentSubCategory: ['digital-cameras'] },
  { option: 'Novoflex', label: 'Novoflex', parentSubCategory: ['digital-cameras'] },
  { option: 'Olympus', label: 'Olympus', parentSubCategory: ['digital-cameras'] },
  { option: 'Ordro', label: 'Ordro', parentSubCategory: ['digital-cameras'] },
  {
    option: 'Oregon Scientific',
    label: 'Oregon Scientific',
    parentSubCategory: ['digital-cameras'],
  },
  { option: 'Packard Bell', label: 'Packard Bell', parentSubCategory: ['digital-cameras'] },
  { option: 'Palm', label: 'Palm', parentSubCategory: ['digital-cameras'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['digital-cameras'] },
  { option: 'Pelco', label: 'Pelco', parentSubCategory: ['digital-cameras'] },
  { option: 'Pentacon', label: 'Pentacon', parentSubCategory: ['digital-cameras'] },
  { option: 'PENTAX', label: 'PENTAX', parentSubCategory: ['digital-cameras'] },
  { option: 'Phase One', label: 'Phase One', parentSubCategory: ['digital-cameras'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['digital-cameras'] },
  { option: 'Phottix', label: 'Phottix', parentSubCategory: ['digital-cameras'] },
  { option: 'Pioneer', label: 'Pioneer', parentSubCategory: ['digital-cameras'] },
  { option: 'PocketWizard', label: 'PocketWizard', parentSubCategory: ['digital-cameras'] },
  { option: 'Polaroid', label: 'Polaroid', parentSubCategory: ['digital-cameras'] },
  { option: 'PRAKTICA', label: 'PRAKTICA', parentSubCategory: ['digital-cameras'] },
  { option: 'Premier', label: 'Premier', parentSubCategory: ['digital-cameras'] },
  { option: 'Pyle', label: 'Pyle', parentSubCategory: ['digital-cameras'] },
  { option: 'Red', label: 'Red', parentSubCategory: ['digital-cameras'] },
  { option: 'Replay', label: 'Replay', parentSubCategory: ['digital-cameras'] },
  { option: 'Ricoh', label: 'Ricoh', parentSubCategory: ['digital-cameras'] },
  { option: 'Ring', label: 'Ring', parentSubCategory: ['digital-cameras'] },
  { option: 'Rollei', label: 'Rollei', parentSubCategory: ['digital-cameras'] },
  { option: 'Sakar', label: 'Sakar', parentSubCategory: ['digital-cameras'] },
  { option: 'Samsung', label: 'Samsung', parentSubCategory: ['digital-cameras'] },
  { option: 'Samyang', label: 'Samyang', parentSubCategory: ['digital-cameras'] },
  { option: 'SanDisk', label: 'SanDisk', parentSubCategory: ['digital-cameras'] },
  { option: 'SANYO', label: 'SANYO', parentSubCategory: ['digital-cameras'] },
  { option: 'Schneider', label: 'Schneider', parentSubCategory: ['digital-cameras'] },
  { option: 'SEA&SEA', label: 'SEA&SEA', parentSubCategory: ['digital-cameras'] },
  { option: 'Sealife', label: 'Sealife', parentSubCategory: ['digital-cameras'] },
  { option: 'Sennheiser', label: 'Sennheiser', parentSubCategory: ['digital-cameras'] },
  { option: 'Sharp', label: 'Sharp', parentSubCategory: ['digital-cameras'] },
  { option: 'Sigma', label: 'Sigma', parentSubCategory: ['digital-cameras'] },
  { option: 'Sinar', label: 'Sinar', parentSubCategory: ['digital-cameras'] },
  { option: 'SiPix', label: 'SiPix', parentSubCategory: ['digital-cameras'] },
  { option: 'SIRUI', label: 'SIRUI', parentSubCategory: ['digital-cameras'] },
  { option: 'SJCAM', label: 'SJCAM', parentSubCategory: ['digital-cameras'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['digital-cameras'] },
  { option: 'Sony Ericsson', label: 'Sony Ericsson', parentSubCategory: ['digital-cameras'] },
  { option: 'SuperHeadz', label: 'SuperHeadz', parentSubCategory: ['digital-cameras'] },
  { option: "T'nB", label: "T'nB", parentSubCategory: ['digital-cameras'] },
  { option: 'Tamron', label: 'Tamron', parentSubCategory: ['digital-cameras'] },
  { option: 'Technaxx', label: 'Technaxx', parentSubCategory: ['digital-cameras'] },
  { option: 'Technika', label: 'Technika', parentSubCategory: ['digital-cameras'] },
  { option: 'Tokina', label: 'Tokina', parentSubCategory: ['digital-cameras'] },
  { option: 'Toshiba', label: 'Toshiba', parentSubCategory: ['digital-cameras'] },
  { option: 'Transcend', label: 'Transcend', parentSubCategory: ['digital-cameras'] },
  { option: 'Traveler', label: 'Traveler', parentSubCategory: ['digital-cameras'] },
  { option: 'Trust', label: 'Trust', parentSubCategory: ['digital-cameras'] },
  { option: 'Unbranded', label: 'Unbranded', parentSubCategory: ['digital-cameras'] },
  { option: 'Uniden', label: 'Uniden', parentSubCategory: ['digital-cameras'] },
  { option: 'Vanguard', label: 'Vanguard', parentSubCategory: ['digital-cameras'] },
  { option: 'Veho', label: 'Veho', parentSubCategory: ['digital-cameras'] },
  { option: 'Verbatim', label: 'Verbatim', parentSubCategory: ['digital-cameras'] },
  { option: 'VIBE', label: 'VIBE', parentSubCategory: ['digital-cameras'] },
  { option: 'Victure', label: 'Victure', parentSubCategory: ['digital-cameras'] },
  { option: 'VistaQuest', label: 'VistaQuest', parentSubCategory: ['digital-cameras'] },
  { option: 'Vivitar', label: 'Vivitar', parentSubCategory: ['digital-cameras'] },
  { option: 'Voigtländer', label: 'Voigtländer', parentSubCategory: ['digital-cameras'] },
  { option: 'VTech', label: 'VTech', parentSubCategory: ['digital-cameras'] },
  { option: 'Walimex', label: 'Walimex', parentSubCategory: ['digital-cameras'] },
  { option: 'Xiaomi', label: 'Xiaomi', parentSubCategory: ['digital-cameras'] },
  { option: 'Xtreme', label: 'Xtreme', parentSubCategory: ['digital-cameras'] },
  { option: 'Yakumo', label: 'Yakumo', parentSubCategory: ['digital-cameras'] },
  { option: 'Yamaha', label: 'Yamaha', parentSubCategory: ['digital-cameras'] },
  { option: 'Yashica', label: 'Yashica', parentSubCategory: ['digital-cameras'] },
  { option: 'YI', label: 'YI', parentSubCategory: ['digital-cameras'] },
  { option: 'YONGNUO', label: 'YONGNUO', parentSubCategory: ['digital-cameras'] },
  { option: 'Z Cam', label: 'Z Cam', parentSubCategory: ['digital-cameras'] },
  { option: 'ZEISS', label: 'ZEISS', parentSubCategory: ['digital-cameras'] },
  { option: 'Zoom', label: 'Zoom', parentSubCategory: ['digital-cameras'] },
  { option: 'Fujifilm', label: 'Fujifilm', parentSubCategory: ['digital-cameras'] },
  { option: 'Polaroid', label: 'Polaroid', parentSubCategory: ['digital-cameras'] },
  { option: 'Kodak', label: 'Kodak', parentSubCategory: ['digital-cameras'] },
  { option: 'Leica', label: 'Leica', parentSubCategory: ['digital-cameras'] },
  { option: 'Lomography', label: 'Lomography', parentSubCategory: ['digital-cameras'] },
  { option: 'Olympus', label: 'Olympus', parentSubCategory: ['digital-cameras'] },
  { option: 'Canon', label: 'Canon', parentSubCategory: ['digital-cameras'] },
  { option: 'Minolta', label: 'Minolta', parentSubCategory: ['digital-cameras'] },
  { option: 'Pentax', label: 'Pentax', parentSubCategory: ['digital-cameras'] },
  { option: 'Rollei', label: 'Rollei', parentSubCategory: ['digital-cameras'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['blu-ray'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['blu-ray'] },
  { option: 'LG', label: 'LG', parentSubCategory: ['blu-ray'] },
  { option: 'Samsung', label: 'Samsung', parentSubCategory: ['blu-ray'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['blu-ray'] },
  { option: 'Pioneer', label: 'Pioneer', parentSubCategory: ['blu-ray'] },
  { option: 'Oppo', label: 'Oppo', parentSubCategory: ['blu-ray'] },
  { option: 'Cambridge Audio', label: 'Cambridge Audio', parentSubCategory: ['blu-ray'] },
  { option: 'Yamaha', label: 'Yamaha', parentSubCategory: ['blu-ray'] },
  { option: 'Denon', label: 'Denon', parentSubCategory: ['blu-ray'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['cd'] },
  { option: 'Marantz', label: 'Marantz', parentSubCategory: ['cd'] },
  { option: 'Yamaha', label: 'Yamaha', parentSubCategory: ['cd'] },
  { option: 'Denon', label: 'Denon', parentSubCategory: ['cd'] },
  { option: 'Onkyo', label: 'Onkyo', parentSubCategory: ['cd'] },
  { option: 'Pioneer', label: 'Pioneer', parentSubCategory: ['cd'] },
  { option: 'Cambridge Audio', label: 'Cambridge Audio', parentSubCategory: ['cd'] },
  { option: 'NAD', label: 'NAD', parentSubCategory: ['cd'] },
  { option: 'TEAC', label: 'TEAC', parentSubCategory: ['cd'] },
  { option: 'Rotel', label: 'Rotel', parentSubCategory: ['cd'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['dvd'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['dvd'] },
  { option: 'LG', label: 'LG', parentSubCategory: ['dvd'] },
  { option: 'Samsung', label: 'Samsung', parentSubCategory: ['dvd'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['dvd'] },
  { option: 'Pioneer', label: 'Pioneer', parentSubCategory: ['dvd'] },
  { option: 'Toshiba', label: 'Toshiba', parentSubCategory: ['dvd'] },
  { option: 'Yamaha', label: 'Yamaha', parentSubCategory: ['dvd'] },
  { option: 'Denon', label: 'Denon', parentSubCategory: ['dvd'] },
  { option: 'Onkyo', label: 'Onkyo', parentSubCategory: ['dvd'] },
  { option: 'Bose', label: 'Bose', parentSubCategory: ['audio-accessories'] },
  { option: 'Sennheiser', label: 'Sennheiser', parentSubCategory: ['audio-accessories'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['audio-accessories'] },
  { option: 'JBL', label: 'JBL', parentSubCategory: ['audio-accessories'] },
  { option: 'Audio-Technica', label: 'Audio-Technica', parentSubCategory: ['audio-accessories'] },
  { option: 'Beats by Dre', label: 'Beats by Dre', parentSubCategory: ['audio-accessories'] },
  { option: 'AKG', label: 'AKG', parentSubCategory: ['audio-accessories'] },
  { option: 'Shure', label: 'Shure', parentSubCategory: ['audio-accessories'] },
  { option: 'Rode', label: 'Rode', parentSubCategory: ['audio-accessories'] },
  { option: 'Logitech', label: 'Logitech', parentSubCategory: ['audio-accessories'] },
  { option: 'JBL', label: 'JBL', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Bose', label: 'Bose', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Ultimate Ears', label: 'Ultimate Ears', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Anker', label: 'Anker', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Marshall', label: 'Marshall', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Harman Kardon', label: 'Harman Kardon', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Beats by Dre', label: 'Beats by Dre', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Bang & Olufsen', label: 'Bang & Olufsen', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Sonos', label: 'Sonos', parentSubCategory: ['bluetooth-speakers'] },
  { option: 'Dell', label: 'Dell', parentSubCategory: ['docking-stations'] },
  { option: 'HP', label: 'HP', parentSubCategory: ['docking-stations'] },
  { option: 'Lenovo', label: 'Lenovo', parentSubCategory: ['docking-stations'] },
  { option: 'Microsoft', label: 'Microsoft', parentSubCategory: ['docking-stations'] },
  { option: 'Kensington', label: 'Kensington', parentSubCategory: ['docking-stations'] },
  { option: 'Targus', label: 'Targus', parentSubCategory: ['docking-stations'] },
  { option: 'Anker', label: 'Anker', parentSubCategory: ['docking-stations'] },
  { option: 'Belkin', label: 'Belkin', parentSubCategory: ['docking-stations'] },
  { option: 'Plugable', label: 'Plugable', parentSubCategory: ['docking-stations'] },
  { option: 'StarTech', label: 'StarTech', parentSubCategory: ['docking-stations'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'JBL', label: 'JBL', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'Toshiba', label: 'Toshiba', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'Sharp', label: 'Sharp', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'Sylvania', label: 'Sylvania', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'Jensen', label: 'Jensen', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'Victrola', label: 'Victrola', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'Lenco', label: 'Lenco', parentSubCategory: ['portable-stereos-boomboxes'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['radios'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['radios'] },
  { option: 'Roberts Radio', label: 'Roberts Radio', parentSubCategory: ['radios'] },
  { option: 'Pure', label: 'Pure', parentSubCategory: ['radios'] },
  { option: 'Bose', label: 'Bose', parentSubCategory: ['radios'] },
  { option: 'JBL', label: 'JBL', parentSubCategory: ['radios'] },
  { option: 'Yamaha', label: 'Yamaha', parentSubCategory: ['radios'] },
  { option: 'Tivoli Audio', label: 'Tivoli Audio', parentSubCategory: ['radios'] },
  { option: 'Sangean', label: 'Sangean', parentSubCategory: ['radios'] },
  { option: 'Grundig', label: 'Grundig', parentSubCategory: ['radios'] },
  { option: 'Denon', label: 'Denon', parentSubCategory: ['radios'] },
  { option: 'Pioneer', label: 'Pioneer', parentSubCategory: ['radios'] },
  { option: 'Kenwood', label: 'Kenwood', parentSubCategory: ['radios'] },
  { option: 'Bush', label: 'Bush', parentSubCategory: ['radios'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['radios'] },
  { option: 'SONY', label: 'SONY', parentSubCategory: ['radios'] },
  { option: 'ICOM', label: 'ICOM', parentSubCategory: ['radios'] },
  { option: 'TECSUN', label: 'TECSUN', parentSubCategory: ['radios'] },
  { option: 'Midland', label: 'Midland', parentSubCategory: ['radios'] },
  { option: 'Yaesu', label: 'Yaesu', parentSubCategory: ['radios'] },
  { option: 'Focusrite', label: 'Focusrite', parentSubCategory: ['studio-recording-equipment'] },
  {
    option: 'Universal Audio',
    label: 'Universal Audio',
    parentSubCategory: ['studio-recording-equipment'],
  },
  { option: 'Behringer', label: 'Behringer', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'PreSonus', label: 'PreSonus', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'Yamaha', label: 'Yamaha', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'AKG', label: 'AKG', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'Shure', label: 'Shure', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'Sennheiser', label: 'Sennheiser', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'Rode', label: 'Rode', parentSubCategory: ['studio-recording-equipment'] },
  {
    option: 'Audio-Technica',
    label: 'Audio-Technica',
    parentSubCategory: ['studio-recording-equipment'],
  },
  { option: 'Neumann', label: 'Neumann', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'Roland', label: 'Roland', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'Mackie', label: 'Mackie', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'TASCAM', label: 'TASCAM', parentSubCategory: ['studio-recording-equipment'] },
  {
    option: 'SSL (Solid State Logic)',
    label: 'SSL (Solid State Logic)',
    parentSubCategory: ['studio-recording-equipment'],
  },
  {
    option: 'MOTU (Mark of the Unicorn)',
    label: 'MOTU (Mark of the Unicorn)',
    parentSubCategory: ['studio-recording-equipment'],
  },
  { option: 'Alesis', label: 'Alesis', parentSubCategory: ['studio-recording-equipment'] },
  { option: 'M-Audio', label: 'M-Audio', parentSubCategory: ['studio-recording-equipment'] },
  {
    option: 'KRK Systems',
    label: 'KRK Systems',
    parentSubCategory: ['studio-recording-equipment'],
  },
  { option: 'Focal', label: 'Focal', parentSubCategory: ['studio-recording-equipment'] },
  { option: '3M', label: '3M', parentSubCategory: ['televisions'] },
  { option: 'Acer', label: 'Acer', parentSubCategory: ['televisions'] },
  { option: 'Acoustic Solutions', label: 'Acoustic Solutions', parentSubCategory: ['televisions'] },
  { option: 'Aiwa', label: 'Aiwa', parentSubCategory: ['televisions'] },
  { option: 'Akai', label: 'Akai', parentSubCategory: ['televisions'] },
  { option: 'Akura', label: 'Akura', parentSubCategory: ['televisions'] },
  { option: 'Alba', label: 'Alba', parentSubCategory: ['televisions'] },
  { option: 'Alphatronics', label: 'Alphatronics', parentSubCategory: ['televisions'] },
  { option: 'Amazon', label: 'Amazon', parentSubCategory: ['televisions'] },
  { option: 'AOC', label: 'AOC', parentSubCategory: ['televisions'] },
  { option: 'Apple', label: 'Apple', parentSubCategory: ['televisions'] },
  { option: 'ASUS', label: 'ASUS', parentSubCategory: ['televisions'] },
  { option: 'Audiosonic', label: 'Audiosonic', parentSubCategory: ['televisions'] },
  { option: 'August', label: 'August', parentSubCategory: ['televisions'] },
  { option: 'Avtex', label: 'Avtex', parentSubCategory: ['televisions'] },
  { option: 'Axion', label: 'Axion', parentSubCategory: ['televisions'] },
  { option: 'Baird', label: 'Baird', parentSubCategory: ['televisions'] },
  { option: 'Bang & Olufsen', label: 'Bang & Olufsen', parentSubCategory: ['televisions'] },
  { option: 'Bauhn', label: 'Bauhn', parentSubCategory: ['televisions'] },
  { option: 'Beko', label: 'Beko', parentSubCategory: ['televisions'] },
  { option: 'Belkin', label: 'Belkin', parentSubCategory: ['televisions'] },
  { option: 'Blaupunkt', label: 'Blaupunkt', parentSubCategory: ['televisions'] },
  { option: 'BRAVIA', label: 'BRAVIA', parentSubCategory: ['televisions'] },
  { option: 'Bush', label: 'Bush', parentSubCategory: ['televisions'] },
  { option: 'Casio', label: 'Casio', parentSubCategory: ['televisions'] },
  { option: 'Celcus', label: 'Celcus', parentSubCategory: ['televisions'] },
  { option: 'Cello', label: 'Cello', parentSubCategory: ['televisions'] },
  { option: 'Cisco', label: 'Cisco', parentSubCategory: ['televisions'] },
  { option: 'Citizen', label: 'Citizen', parentSubCategory: ['televisions'] },
  { option: 'Coast', label: 'Coast', parentSubCategory: ['televisions'] },
  { option: 'Daewoo', label: 'Daewoo', parentSubCategory: ['televisions'] },
  { option: 'Dell', label: 'Dell', parentSubCategory: ['televisions'] },
  { option: 'DENVER', label: 'DENVER', parentSubCategory: ['televisions'] },
  { option: 'DGM', label: 'DGM', parentSubCategory: ['televisions'] },
  { option: 'Digihome', label: 'Digihome', parentSubCategory: ['televisions'] },
  { option: 'Digilogic', label: 'Digilogic', parentSubCategory: ['televisions'] },
  { option: 'Digitrex', label: 'Digitrex', parentSubCategory: ['televisions'] },
  { option: 'Disney', label: 'Disney', parentSubCategory: ['televisions'] },
  { option: 'DMTech', label: 'DMTech', parentSubCategory: ['televisions'] },
  { option: 'Durabrand', label: 'Durabrand', parentSubCategory: ['televisions'] },
  { option: 'ElectrIQ', label: 'ElectrIQ', parentSubCategory: ['televisions'] },
  { option: 'e-motion', label: 'e-motion', parentSubCategory: ['televisions'] },
  { option: 'E-Star', label: 'E-Star', parentSubCategory: ['televisions'] },
  { option: 'Evo', label: 'Evo', parentSubCategory: ['televisions'] },
  { option: 'Falcon', label: 'Falcon', parentSubCategory: ['televisions'] },
  { option: 'Ferguson', label: 'Ferguson', parentSubCategory: ['televisions'] },
  { option: 'Finlux', label: 'Finlux', parentSubCategory: ['televisions'] },
  { option: 'Foehn & Hirsch', label: 'Foehn & Hirsch', parentSubCategory: ['televisions'] },
  { option: 'Fujitsu', label: 'Fujitsu', parentSubCategory: ['televisions'] },
  { option: 'Funai', label: 'Funai', parentSubCategory: ['televisions'] },
  { option: 'Goodmans', label: 'Goodmans', parentSubCategory: ['televisions'] },
  { option: 'Google', label: 'Google', parentSubCategory: ['televisions'] },
  { option: 'Grundig', label: 'Grundig', parentSubCategory: ['televisions'] },
  { option: 'Haier', label: 'Haier', parentSubCategory: ['televisions'] },
  { option: 'Hannspree', label: 'Hannspree', parentSubCategory: ['televisions'] },
  { option: 'Hisense', label: 'Hisense', parentSubCategory: ['televisions'] },
  { option: 'Hitachi', label: 'Hitachi', parentSubCategory: ['televisions'] },
  { option: 'HP', label: 'HP', parentSubCategory: ['televisions'] },
  { option: 'Huawei', label: 'Huawei', parentSubCategory: ['televisions'] },
  { option: 'Humax', label: 'Humax', parentSubCategory: ['televisions'] },
  { option: 'iiyama', label: 'iiyama', parentSubCategory: ['televisions'] },
  { option: 'InFocus', label: 'InFocus', parentSubCategory: ['televisions'] },
  { option: 'Insignia', label: 'Insignia', parentSubCategory: ['televisions'] },
  { option: 'ISIS', label: 'ISIS', parentSubCategory: ['televisions'] },
  { option: 'iView', label: 'iView', parentSubCategory: ['televisions'] },
  { option: 'JMB', label: 'JMB', parentSubCategory: ['televisions'] },
  { option: 'JVC', label: 'JVC', parentSubCategory: ['televisions'] },
  { option: 'KDS', label: 'KDS', parentSubCategory: ['televisions'] },
  { option: 'KENMARK', label: 'KENMARK', parentSubCategory: ['televisions'] },
  { option: 'Kogan', label: 'Kogan', parentSubCategory: ['televisions'] },
  { option: 'Leadstar', label: 'Leadstar', parentSubCategory: ['televisions'] },
  { option: 'Lenovo', label: 'Lenovo', parentSubCategory: ['televisions'] },
  { option: 'LG', label: 'LG', parentSubCategory: ['televisions'] },
  { option: 'Linsar', label: 'Linsar', parentSubCategory: ['televisions'] },
  { option: 'Loewe', label: 'Loewe', parentSubCategory: ['televisions'] },
  { option: 'Logik', label: 'Logik', parentSubCategory: ['televisions'] },
  { option: 'Luxor', label: 'Luxor', parentSubCategory: ['televisions'] },
  { option: 'MANTA', label: 'MANTA', parentSubCategory: ['televisions'] },
  { option: 'Maplin', label: 'Maplin', parentSubCategory: ['televisions'] },
  { option: 'Matsui', label: 'Matsui', parentSubCategory: ['televisions'] },
  { option: 'Maxim', label: 'Maxim', parentSubCategory: ['televisions'] },
  { option: 'MEDION', label: 'MEDION', parentSubCategory: ['televisions'] },
  { option: 'Metz', label: 'Metz', parentSubCategory: ['televisions'] },
  { option: 'Microsoft', label: 'Microsoft', parentSubCategory: ['televisions'] },
  { option: 'Mikomi', label: 'Mikomi', parentSubCategory: ['televisions'] },
  { option: 'Mirai', label: 'Mirai', parentSubCategory: ['televisions'] },
  { option: 'Mitchell & Brown', label: 'Mitchell & Brown', parentSubCategory: ['televisions'] },
  { option: 'Mitsubishi', label: 'Mitsubishi', parentSubCategory: ['televisions'] },
  { option: 'Murphy', label: 'Murphy', parentSubCategory: ['televisions'] },
  { option: 'NEC', label: 'NEC', parentSubCategory: ['televisions'] },
  {
    option: 'NEC Display Solutions',
    label: 'NEC Display Solutions',
    parentSubCategory: ['televisions'],
  },
  { option: 'NEXTBASE', label: 'NEXTBASE', parentSubCategory: ['televisions'] },
  { option: 'Nikkai', label: 'Nikkai', parentSubCategory: ['televisions'] },
  { option: 'NordMende', label: 'NordMende', parentSubCategory: ['televisions'] },
  { option: 'One For All', label: 'One For All', parentSubCategory: ['televisions'] },
  { option: 'ONN', label: 'ONN', parentSubCategory: ['televisions'] },
  { option: 'Optoma', label: 'Optoma', parentSubCategory: ['televisions'] },
  { option: 'Orion', label: 'Orion', parentSubCategory: ['televisions'] },
  { option: 'Pacific Technology', label: 'Pacific Technology', parentSubCategory: ['televisions'] },
  { option: 'Palsonic', label: 'Palsonic', parentSubCategory: ['televisions'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['televisions'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['televisions'] },
  { option: 'Philips Sonicare', label: 'Philips Sonicare', parentSubCategory: ['televisions'] },
  { option: 'Pioneer', label: 'Pioneer', parentSubCategory: ['televisions'] },
  { option: 'Polaroid', label: 'Polaroid', parentSubCategory: ['televisions'] },
  { option: 'Polycom', label: 'Polycom', parentSubCategory: ['televisions'] },
  { option: 'Pro-Line Racing', label: 'Pro-Line Racing', parentSubCategory: ['televisions'] },
  { option: 'Promethean', label: 'Promethean', parentSubCategory: ['televisions'] },
  { option: 'ProofVision', label: 'ProofVision', parentSubCategory: ['televisions'] },
  { option: 'PROSCAN', label: 'PROSCAN', parentSubCategory: ['televisions'] },
  { option: 'RCA', label: 'RCA', parentSubCategory: ['televisions'] },
  { option: 'Relisys', label: 'Relisys', parentSubCategory: ['televisions'] },
  { option: 'Roadstar', label: 'Roadstar', parentSubCategory: ['televisions'] },
  { option: 'Roku', label: 'Roku', parentSubCategory: ['televisions'] },
  { option: 'Samsung', label: 'Samsung', parentSubCategory: ['televisions'] },
  { option: 'Sandstrom', label: 'Sandstrom', parentSubCategory: ['televisions'] },
  { option: 'SANYO', label: 'SANYO', parentSubCategory: ['televisions'] },
  { option: 'Sarason', label: 'Sarason', parentSubCategory: ['televisions'] },
  { option: 'Schneider', label: 'Schneider', parentSubCategory: ['televisions'] },
  { option: 'SEG', label: 'SEG', parentSubCategory: ['televisions'] },
  { option: 'Seiki', label: 'Seiki', parentSubCategory: ['televisions'] },
  { option: 'Seizo', label: 'Seizo', parentSubCategory: ['televisions'] },
  { option: 'Sharp', label: 'Sharp', parentSubCategory: ['televisions'] },
  { option: 'Sinclair', label: 'Sinclair', parentSubCategory: ['televisions'] },
  { option: 'Sky', label: 'Sky', parentSubCategory: ['televisions'] },
  { option: 'Smart', label: 'Smart', parentSubCategory: ['televisions'] },
  { option: 'Smart Tech', label: 'Smart Tech', parentSubCategory: ['televisions'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['televisions'] },
  { option: 'Sony Pictures', label: 'Sony Pictures', parentSubCategory: ['televisions'] },
  { option: 'Sovos', label: 'Sovos', parentSubCategory: ['televisions'] },
  { option: 'TCL', label: 'TCL', parentSubCategory: ['televisions'] },
  { option: 'TEAC', label: 'TEAC', parentSubCategory: ['televisions'] },
  { option: 'Techlink', label: 'Techlink', parentSubCategory: ['televisions'] },
  { option: 'Technika', label: 'Technika', parentSubCategory: ['televisions'] },
  { option: 'Techwood', label: 'Techwood', parentSubCategory: ['televisions'] },
  { option: 'TELEFUNKEN', label: 'TELEFUNKEN', parentSubCategory: ['televisions'] },
  { option: 'TELE System', label: 'TELE System', parentSubCategory: ['televisions'] },
  { option: 'Tevion', label: 'Tevion', parentSubCategory: ['televisions'] },
  { option: 'Thomson', label: 'Thomson', parentSubCategory: ['televisions'] },
  { option: 'Toshiba', label: 'Toshiba', parentSubCategory: ['televisions'] },
  { option: 'Triax', label: 'Triax', parentSubCategory: ['televisions'] },
  { option: 'UMC', label: 'UMC', parentSubCategory: ['televisions'] },
  { option: 'Unbranded', label: 'Unbranded', parentSubCategory: ['televisions'] },
  { option: 'Unispectra', label: 'Unispectra', parentSubCategory: ['televisions'] },
  { option: 'United', label: 'United', parentSubCategory: ['televisions'] },
  { option: 'Veltech', label: 'Veltech', parentSubCategory: ['televisions'] },
  { option: 'Venturer', label: 'Venturer', parentSubCategory: ['televisions'] },
  { option: 'Videocon', label: 'Videocon', parentSubCategory: ['televisions'] },
  { option: 'ViewSonic', label: 'ViewSonic', parentSubCategory: ['televisions'] },
  { option: 'Vision', label: 'Vision', parentSubCategory: ['televisions'] },
  { option: 'VisionPLUS', label: 'VisionPLUS', parentSubCategory: ['televisions'] },
  { option: 'Walker', label: 'Walker', parentSubCategory: ['televisions'] },
  { option: 'Westinghouse', label: 'Westinghouse', parentSubCategory: ['televisions'] },
  { option: 'Wharfedale', label: 'Wharfedale', parentSubCategory: ['televisions'] },
  { option: 'Xiaomi', label: 'Xiaomi', parentSubCategory: ['televisions'] },
  { option: 'Hikvision', label: 'Hikvision', parentSubCategory: ['dvrs'] },
  { option: 'Swann', label: 'Swann', parentSubCategory: ['dvrs'] },
  { option: 'Dahua', label: 'Dahua', parentSubCategory: ['dvrs'] },
  { option: 'ANNKE', label: 'ANNKE', parentSubCategory: ['dvrs'] },
  { option: 'Reolink', label: 'Reolink', parentSubCategory: ['dvrs'] },
  { option: 'ZOSI', label: 'ZOSI', parentSubCategory: ['dvrs'] },
  { option: 'Bosch', label: 'Bosch', parentSubCategory: ['dvrs'] },
  { option: 'Lorex', label: 'Lorex', parentSubCategory: ['dvrs'] },
  { option: 'FLIR', label: 'FLIR', parentSubCategory: ['dvrs'] },
  { option: 'Samsung', label: 'Samsung', parentSubCategory: ['dvrs'] },
  { option: 'Amcrest', label: 'Amcrest', parentSubCategory: ['dvrs'] },
  { option: 'Honeywell', label: 'Honeywell', parentSubCategory: ['dvrs'] },
  { option: 'Q-See', label: 'Q-See', parentSubCategory: ['dvrs'] },
  { option: 'AVTECH', label: 'AVTECH', parentSubCategory: ['dvrs'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['dvrs'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['dvrs'] },
  { option: 'Vivotek', label: 'Vivotek', parentSubCategory: ['dvrs'] },
  { option: 'Axis Communications', label: 'Axis Communications', parentSubCategory: ['dvrs'] },
  { option: 'Night Owl', label: 'Night Owl', parentSubCategory: ['dvrs'] },
  { option: 'Anran', label: 'Anran', parentSubCategory: ['dvrs'] },
  { option: 'Apple', label: 'Apple', parentSubCategory: ['gadgets'] },
  { option: 'Samsung', label: 'Samsung', parentSubCategory: ['gadgets'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['gadgets'] },
  { option: 'Bose', label: 'Bose', parentSubCategory: ['gadgets'] },
  { option: 'Fitbit', label: 'Fitbit', parentSubCategory: ['gadgets'] },
  { option: 'Garmin', label: 'Garmin', parentSubCategory: ['gadgets'] },
  { option: 'Huawei', label: 'Huawei', parentSubCategory: ['gadgets'] },
  { option: 'Xiaomi', label: 'Xiaomi', parentSubCategory: ['gadgets'] },
  { option: 'DJI', label: 'DJI', parentSubCategory: ['gadgets'] },
  { option: 'GoPro', label: 'GoPro', parentSubCategory: ['gadgets'] },
  { option: 'JBL', label: 'JBL', parentSubCategory: ['gadgets'] },
  { option: 'Anker', label: 'Anker', parentSubCategory: ['gadgets'] },
  { option: 'Amazon', label: 'Amazon', parentSubCategory: ['gadgets'] },
  { option: 'Microsoft', label: 'Microsoft', parentSubCategory: ['gadgets'] },
  { option: 'Oculus', label: 'Oculus', parentSubCategory: ['gadgets'] },
  { option: 'Ring', label: 'Ring', parentSubCategory: ['gadgets'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['gadgets'] },
  { option: 'Nest', label: 'Nest', parentSubCategory: ['gadgets'] },
  { option: 'Sonos', label: 'Sonos', parentSubCategory: ['gadgets'] },
  { option: 'Tile', label: 'Tile', parentSubCategory: ['gadgets'] },
  { option: 'Amazon', label: 'Amazon', parentSubCategory: ['streaming-devices'] },
  { option: 'Roku', label: 'Roku', parentSubCategory: ['streaming-devices'] },
  { option: 'Google', label: 'Google', parentSubCategory: ['streaming-devices'] },
  { option: 'Apple', label: 'Apple', parentSubCategory: ['streaming-devices'] },
  { option: 'NVIDIA', label: 'NVIDIA', parentSubCategory: ['streaming-devices'] },
  { option: 'Xiaomi', label: 'Xiaomi', parentSubCategory: ['streaming-devices'] },
  { option: 'Tivo', label: 'Tivo', parentSubCategory: ['streaming-devices'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['streaming-devices'] },
  { option: 'Sony', label: 'Sony', parentSubCategory: ['streaming-devices'] },
  { option: 'LG', label: 'LG', parentSubCategory: ['streaming-devices'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['streaming-devices'] },
  { option: 'Samsung', label: 'Samsung', parentSubCategory: ['streaming-devices'] },
  { option: 'Hisense', label: 'Hisense', parentSubCategory: ['streaming-devices'] },
  { option: 'TCL', label: 'TCL', parentSubCategory: ['streaming-devices'] },
  { option: 'Sharp', label: 'Sharp', parentSubCategory: ['streaming-devices'] },
  { option: 'Vizio', label: 'Vizio', parentSubCategory: ['streaming-devices'] },
  { option: 'Toshiba', label: 'Toshiba', parentSubCategory: ['streaming-devices'] },
  { option: 'JVC', label: 'JVC', parentSubCategory: ['streaming-devices'] },
  { option: 'Philips', label: 'Philips', parentSubCategory: ['streaming-devices'] },
  { option: 'KitchenAid', label: 'KitchenAid', parentSubCategory: ['kitchen-dining'] },
  { option: 'Le Creuset', label: 'Le Creuset', parentSubCategory: ['kitchen-dining'] },
  { option: 'Tefal', label: 'Tefal', parentSubCategory: ['kitchen-dining'] },
  { option: 'Pyrex', label: 'Pyrex', parentSubCategory: ['kitchen-dining'] },
  { option: 'Cuisinart', label: 'Cuisinart', parentSubCategory: ['kitchen-dining'] },
  { option: 'Nespresso', label: 'Nespresso', parentSubCategory: ['kitchen-dining'] },
  { option: 'Kenwood', label: 'Kenwood', parentSubCategory: ['kitchen-dining'] },
  { option: 'Bodum', label: 'Bodum', parentSubCategory: ['kitchen-dining'] },
  { option: 'WMF', label: 'WMF', parentSubCategory: ['kitchen-dining'] },
  { option: 'Joseph Joseph', label: 'Joseph Joseph', parentSubCategory: ['kitchen-dining'] },
  { option: 'Russell Hobbs', label: 'Russell Hobbs', parentSubCategory: ['kitchen-dining'] },
  { option: 'Brabantia', label: 'Brabantia', parentSubCategory: ['kitchen-dining'] },
  { option: 'Prestige', label: 'Prestige', parentSubCategory: ['kitchen-dining'] },
  { option: 'OXO', label: 'OXO', parentSubCategory: ['kitchen-dining'] },
  { option: 'Morphy Richards', label: 'Morphy Richards', parentSubCategory: ['kitchen-dining'] },
  { option: 'Denby', label: 'Denby', parentSubCategory: ['kitchen-dining'] },
  { option: 'Anchor Hocking', label: 'Anchor Hocking', parentSubCategory: ['kitchen-dining'] },
  { option: 'All-Clad', label: 'All-Clad', parentSubCategory: ['kitchen-dining'] },
  {
    option: 'Zwilling J.A. Henckels',
    label: 'Zwilling J.A. Henckels',
    parentSubCategory: ['kitchen-dining'],
  },
  { option: 'Corelle', label: 'Corelle', parentSubCategory: ['kitchen-dining'] },
  { option: 'Google Nest', label: 'Google Nest', parentSubCategory: ['smart-home'] },
  { option: 'Amazon Echo', label: 'Amazon Echo', parentSubCategory: ['smart-home'] },
  { option: 'Philips Hue', label: 'Philips Hue', parentSubCategory: ['smart-home'] },
  { option: 'Ring', label: 'Ring', parentSubCategory: ['smart-home'] },
  { option: 'TP-Link Kasa', label: 'TP-Link Kasa', parentSubCategory: ['smart-home'] },
  { option: 'Arlo', label: 'Arlo', parentSubCategory: ['smart-home'] },
  { option: 'ecobee', label: 'ecobee', parentSubCategory: ['smart-home'] },
  { option: 'August', label: 'August', parentSubCategory: ['smart-home'] },
  { option: 'Wyze', label: 'Wyze', parentSubCategory: ['smart-home'] },
  { option: 'Logitech Harmony', label: 'Logitech Harmony', parentSubCategory: ['smart-home'] },
  { option: 'SimpliSafe', label: 'SimpliSafe', parentSubCategory: ['smart-home'] },
  { option: 'Wemo', label: 'Wemo', parentSubCategory: ['smart-home'] },
  { option: 'Netatmo', label: 'Netatmo', parentSubCategory: ['smart-home'] },
  { option: 'Sonos', label: 'Sonos', parentSubCategory: ['smart-home'] },
  { option: 'Hive', label: 'Hive', parentSubCategory: ['smart-home'] },
  { option: 'LIFX', label: 'LIFX', parentSubCategory: ['smart-home'] },
  { option: 'iRobot Roomba', label: 'iRobot Roomba', parentSubCategory: ['smart-home'] },
  { option: 'Eufy', label: 'Eufy', parentSubCategory: ['smart-home'] },
  {
    option: 'Samsung SmartThings',
    label: 'Samsung SmartThings',
    parentSubCategory: ['smart-home'],
  },
  { option: 'Nanoleaf', label: 'Nanoleaf', parentSubCategory: ['smart-home'] },
  { option: 'IKEA', label: 'IKEA', parentSubCategory: ['home-decor'] },
  { option: 'Anthropologie', label: 'Anthropologie', parentSubCategory: ['home-decor'] },
  { option: 'Wayfair', label: 'Wayfair', parentSubCategory: ['home-decor'] },
  { option: 'Pottery Barn', label: 'Pottery Barn', parentSubCategory: ['home-decor'] },
  { option: 'West Elm', label: 'West Elm', parentSubCategory: ['home-decor'] },
  { option: 'Crate & Barrel', label: 'Crate & Barrel', parentSubCategory: ['home-decor'] },
  { option: 'Pier 1', label: 'Pier 1', parentSubCategory: ['home-decor'] },
  { option: 'Bed Bath & Beyond', label: 'Bed Bath & Beyond', parentSubCategory: ['home-decor'] },
  { option: 'Target', label: 'Target', parentSubCategory: ['home-decor'] },
  { option: 'HomeGoods', label: 'HomeGoods', parentSubCategory: ['home-decor'] },
  { option: 'CB2', label: 'CB2', parentSubCategory: ['home-decor'] },
  { option: 'Zara Home', label: 'Zara Home', parentSubCategory: ['home-decor'] },
  { option: 'H&M Home', label: 'H&M Home', parentSubCategory: ['home-decor'] },
  { option: 'World Market', label: 'World Market', parentSubCategory: ['home-decor'] },
  { option: 'Urban Outfitters', label: 'Urban Outfitters', parentSubCategory: ['home-decor'] },
  { option: 'John Lewis', label: 'John Lewis', parentSubCategory: ['home-decor'] },
  { option: 'The Range', label: 'The Range', parentSubCategory: ['home-decor'] },
  { option: 'Dunelm', label: 'Dunelm', parentSubCategory: ['home-decor'] },
  { option: 'Next Home', label: 'Next Home', parentSubCategory: ['home-decor'] },
  { option: 'Habitat', label: 'Habitat', parentSubCategory: ['home-decor'] },
  { option: 'Bosch', label: 'Bosch', parentSubCategory: ['home-appliances'] },
  { option: 'Samsung', label: 'Samsung', parentSubCategory: ['home-appliances'] },
  { option: 'LG', label: 'LG', parentSubCategory: ['home-appliances'] },
  { option: 'Siemens', label: 'Siemens', parentSubCategory: ['home-appliances'] },
  { option: 'Miele', label: 'Miele', parentSubCategory: ['home-appliances'] },
  { option: 'Beko', label: 'Beko', parentSubCategory: ['home-appliances'] },
  { option: 'Whirlpool', label: 'Whirlpool', parentSubCategory: ['home-appliances'] },
  { option: 'Dyson', label: 'Dyson', parentSubCategory: ['home-appliances'] },
  { option: 'Hotpoint', label: 'Hotpoint', parentSubCategory: ['home-appliances'] },
  { option: 'Hoover', label: 'Hoover', parentSubCategory: ['home-appliances'] },
  { option: 'Kenwood', label: 'Kenwood', parentSubCategory: ['home-appliances'] },
  { option: 'Indesit', label: 'Indesit', parentSubCategory: ['home-appliances'] },
  { option: 'AEG', label: 'AEG', parentSubCategory: ['home-appliances'] },
  { option: 'Russell Hobbs', label: 'Russell Hobbs', parentSubCategory: ['home-appliances'] },
  { option: 'Panasonic', label: 'Panasonic', parentSubCategory: ['home-appliances'] },
  { option: 'Electrolux', label: 'Electrolux', parentSubCategory: ['home-appliances'] },
  { option: 'Smeg', label: 'Smeg', parentSubCategory: ['home-appliances'] },
  { option: 'Shark', label: 'Shark', parentSubCategory: ['home-appliances'] },
  { option: 'Ninja', label: 'Ninja', parentSubCategory: ['home-appliances'] },
  { option: 'Instant Pot', label: 'Instant Pot', parentSubCategory: ['home-appliances'] },
  {
    option: 'Duvet Covers & Bedding Sets',
    label: 'Duvet Covers & Bedding Sets',
    parentSubCategory: ['bedding'],
  },
  {
    option: 'Bed Sheets & Pillowcases',
    label: 'Bed Sheets & Pillowcases',
    parentSubCategory: ['bedding'],
  },
  { option: 'Blankets & Throws', label: 'Blankets & Throws', parentSubCategory: ['bedding'] },
  {
    option: 'Bedspreads & Coverlets',
    label: 'Bedspreads & Coverlets',
    parentSubCategory: ['bedding'],
  },
  { option: 'Quilts & Bed Throws', label: 'Quilts & Bed Throws', parentSubCategory: ['bedding'] },
  {
    option: 'Mattress Toppers & Protectors',
    label: 'Mattress Toppers & Protectors',
    parentSubCategory: ['bedding'],
  },
  {
    option: 'Bed Skirts & Valances',
    label: 'Bed Skirts & Valances',
    parentSubCategory: ['bedding'],
  },
  { option: 'Pillow Shams', label: 'Pillow Shams', parentSubCategory: ['bedding'] },
  { option: 'Bed Runners', label: 'Bed Runners', parentSubCategory: ['bedding'] },
  {
    option: 'Decorative Cushions & Pillows',
    label: 'Decorative Cushions & Pillows',
    parentSubCategory: ['bedding'],
  },
  { option: 'Nursery Bedding Sets', label: 'Nursery Bedding Sets', parentSubCategory: ['bedding'] },
  { option: "Kids' Bedding", label: "Kids' Bedding", parentSubCategory: ['bedding'] },
  { option: 'Teen Bedding', label: 'Teen Bedding', parentSubCategory: ['bedding'] },
  {
    option: 'Bedding Collections & Sets',
    label: 'Bedding Collections & Sets',
    parentSubCategory: ['bedding'],
  },
  { option: 'Comforters & Duvets', label: 'Comforters & Duvets', parentSubCategory: ['bedding'] },
  { option: 'Electric Blankets', label: 'Electric Blankets', parentSubCategory: ['bedding'] },
  { option: 'Weighted Blankets', label: 'Weighted Blankets', parentSubCategory: ['bedding'] },
  { option: 'Waterproof Bedding', label: 'Waterproof Bedding', parentSubCategory: ['bedding'] },
  { option: 'Silk Bedding', label: 'Silk Bedding', parentSubCategory: ['bedding'] },
  { option: 'Linen Bedding', label: 'Linen Bedding', parentSubCategory: ['bedding'] },
  { option: 'IKEA', label: 'IKEA', parentSubCategory: ['storage-organisation'] },
  { option: 'Amazon Basics', label: 'Amazon Basics', parentSubCategory: ['storage-organisation'] },
  { option: 'Curver', label: 'Curver', parentSubCategory: ['storage-organisation'] },
  {
    option: 'Really Useful Box',
    label: 'Really Useful Box',
    parentSubCategory: ['storage-organisation'],
  },
  { option: 'Sterilite', label: 'Sterilite', parentSubCategory: ['storage-organisation'] },
  { option: 'Sistema', label: 'Sistema', parentSubCategory: ['storage-organisation'] },
  { option: 'Rubbermaid', label: 'Rubbermaid', parentSubCategory: ['storage-organisation'] },
  { option: 'Tupperware', label: 'Tupperware', parentSubCategory: ['storage-organisation'] },
  { option: 'mDesign', label: 'mDesign', parentSubCategory: ['storage-organisation'] },
  { option: 'HOMCOM', label: 'HOMCOM', parentSubCategory: ['storage-organisation'] },
  { option: 'Neatfreak', label: 'Neatfreak', parentSubCategory: ['storage-organisation'] },
  { option: 'Whitmor', label: 'Whitmor', parentSubCategory: ['storage-organisation'] },
  { option: 'Brabantia', label: 'Brabantia', parentSubCategory: ['storage-organisation'] },
  { option: 'Ezy Storage', label: 'Ezy Storage', parentSubCategory: ['storage-organisation'] },
  { option: 'VonHaus', label: 'VonHaus', parentSubCategory: ['storage-organisation'] },
  { option: 'Lifewit', label: 'Lifewit', parentSubCategory: ['storage-organisation'] },
  {
    option: 'House of Quirk',
    label: 'House of Quirk',
    parentSubCategory: ['storage-organisation'],
  },
  { option: 'IRIS USA', label: 'IRIS USA', parentSubCategory: ['storage-organisation'] },
  {
    option: 'Seville Classics',
    label: 'Seville Classics',
    parentSubCategory: ['storage-organisation'],
  },
  { option: 'JVL', label: 'JVL', parentSubCategory: ['storage-organisation'] },
  { option: 'Bathstore', label: 'Bathstore', parentSubCategory: ['bath'] },
  { option: 'Victoria Plum', label: 'Victoria Plum', parentSubCategory: ['bath'] },
  { option: 'Wickes', label: 'Wickes', parentSubCategory: ['bath'] },
  { option: 'B&Q', label: 'B&Q', parentSubCategory: ['bath'] },
  { option: 'Mira Showers', label: 'Mira Showers', parentSubCategory: ['bath'] },
  { option: 'Triton Showers', label: 'Triton Showers', parentSubCategory: ['bath'] },
  { option: 'Aqualisa', label: 'Aqualisa', parentSubCategory: ['bath'] },
  { option: 'Ideal Standard', label: 'Ideal Standard', parentSubCategory: ['bath'] },
  { option: 'Grohe', label: 'Grohe', parentSubCategory: ['bath'] },
  { option: 'Hansgrohe', label: 'Hansgrohe', parentSubCategory: ['bath'] },
  { option: 'Hudson Reed', label: 'Hudson Reed', parentSubCategory: ['bath'] },
  { option: 'Crosswater', label: 'Crosswater', parentSubCategory: ['bath'] },
  { option: 'Roca', label: 'Roca', parentSubCategory: ['bath'] },
  { option: 'Armitage Shanks', label: 'Armitage Shanks', parentSubCategory: ['bath'] },
  { option: 'Burlington Bathrooms', label: 'Burlington Bathrooms', parentSubCategory: ['bath'] },
  { option: 'Heritage Bathrooms', label: 'Heritage Bathrooms', parentSubCategory: ['bath'] },
  { option: 'Twyford', label: 'Twyford', parentSubCategory: ['bath'] },
  { option: 'Duravit', label: 'Duravit', parentSubCategory: ['bath'] },
  { option: 'Jacuzzi', label: 'Jacuzzi', parentSubCategory: ['bath'] },
  { option: 'Kohler', label: 'Kohler', parentSubCategory: ['bath'] },
  { option: 'Rolex', label: 'Rolex', parentSubCategory: ['watches'] },
  { option: 'Omega', label: 'Omega', parentSubCategory: ['watches'] },
  { option: 'TAG Heuer', label: 'TAG Heuer', parentSubCategory: ['watches'] },
  { option: 'Seiko', label: 'Seiko', parentSubCategory: ['watches'] },
  { option: 'Casio', label: 'Casio', parentSubCategory: ['watches'] },
  { option: 'Citizen', label: 'Citizen', parentSubCategory: ['watches'] },
  { option: 'Timex', label: 'Timex', parentSubCategory: ['watches'] },
  { option: 'Fossil', label: 'Fossil', parentSubCategory: ['watches'] },
  { option: 'Tissot', label: 'Tissot', parentSubCategory: ['watches'] },
  { option: 'Breitling', label: 'Breitling', parentSubCategory: ['watches'] },
  { option: 'Bulova', label: 'Bulova', parentSubCategory: ['watches'] },
  { option: 'Michael Kors', label: 'Michael Kors', parentSubCategory: ['watches'] },
  { option: 'Swatch', label: 'Swatch', parentSubCategory: ['watches'] },
  { option: 'Invicta', label: 'Invicta', parentSubCategory: ['watches'] },
  { option: 'Cartier', label: 'Cartier', parentSubCategory: ['watches'] },
  { option: 'Patek Philippe', label: 'Patek Philippe', parentSubCategory: ['watches'] },
  { option: 'Audemars Piguet', label: 'Audemars Piguet', parentSubCategory: ['watches'] },
  { option: 'Longines', label: 'Longines', parentSubCategory: ['watches'] },
  { option: 'Hublot', label: 'Hublot', parentSubCategory: ['watches'] },
  { option: 'Daniel Wellington', label: 'Daniel Wellington', parentSubCategory: ['watches'] },
  { option: 'Funko', label: 'Funko', parentSubCategory: ['collectibles'] },
  { option: 'LEGO', label: 'LEGO', parentSubCategory: ['collectibles'] },
  { option: 'Hot Wheels', label: 'Hot Wheels', parentSubCategory: ['collectibles'] },
  { option: 'Pokémon', label: 'Pokémon', parentSubCategory: ['collectibles'] },
  { option: 'Marvel', label: 'Marvel', parentSubCategory: ['collectibles'] },
  { option: 'DC Comics', label: 'DC Comics', parentSubCategory: ['collectibles'] },
  { option: 'Star Wars', label: 'Star Wars', parentSubCategory: ['collectibles'] },
  { option: 'Yu-Gi-Oh!', label: 'Yu-Gi-Oh!', parentSubCategory: ['collectibles'] },
  { option: 'Matchbox', label: 'Matchbox', parentSubCategory: ['collectibles'] },
  { option: 'Transformers', label: 'Transformers', parentSubCategory: ['collectibles'] },
  { option: 'Barbie', label: 'Barbie', parentSubCategory: ['collectibles'] },
  { option: 'Disney', label: 'Disney', parentSubCategory: ['collectibles'] },
  { option: 'McFarlane Toys', label: 'McFarlane Toys', parentSubCategory: ['collectibles'] },
  { option: 'NECA', label: 'NECA', parentSubCategory: ['collectibles'] },
  { option: 'Hasbro', label: 'Hasbro', parentSubCategory: ['collectibles'] },
  { option: 'Mattel', label: 'Mattel', parentSubCategory: ['collectibles'] },
  { option: 'Bandai', label: 'Bandai', parentSubCategory: ['collectibles'] },
  {
    option: 'Sideshow Collectibles',
    label: 'Sideshow Collectibles',
    parentSubCategory: ['collectibles'],
  },
  { option: 'Kotobukiya', label: 'Kotobukiya', parentSubCategory: ['collectibles'] },
  { option: 'Tamashii Nations', label: 'Tamashii Nations', parentSubCategory: ['collectibles'] },
  { option: 'Mezco', label: 'Mezco', parentSubCategory: ['collectibles'] },
  { option: 'Playmobil', label: 'Playmobil', parentSubCategory: ['collectibles'] },
  { option: 'Corgi', label: 'Corgi', parentSubCategory: ['collectibles'] },
  { option: 'Eaglemoss', label: 'Eaglemoss', parentSubCategory: ['collectibles'] },
  { option: 'Gentle Giant', label: 'Gentle Giant', parentSubCategory: ['collectibles'] },
  { option: 'Weta Workshop', label: 'Weta Workshop', parentSubCategory: ['collectibles'] },
  { option: 'Funko Pop', label: 'Funko Pop', parentSubCategory: ['collectibles'] },
  { option: 'Mego', label: 'Mego', parentSubCategory: ['collectibles'] },
  { option: 'Super7', label: 'Super7', parentSubCategory: ['collectibles'] },
  {
    option: 'The North Face',
    label: 'The North Face',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Berghaus',
    label: 'Berghaus',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Columbia',
    label: 'Columbia',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Patagonia',
    label: 'Patagonia',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Jack Wolfskin',
    label: 'Jack Wolfskin',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Regatta',
    label: 'Regatta',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Salomon',
    label: 'Salomon',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Mountain Warehouse',
    label: 'Mountain Warehouse',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  { option: 'Rab', label: 'Rab', parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'] },
  {
    option: 'Mammut',
    label: 'Mammut',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: "Arc'teryx",
    label: "Arc'teryx",
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Helly Hansen',
    label: 'Helly Hansen',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Osprey',
    label: 'Osprey',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Quechua',
    label: 'Quechua',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Vaude',
    label: 'Vaude',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Montane',
    label: 'Montane',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: "O'Neill",
    label: "O'Neill",
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Karrimor',
    label: 'Karrimor',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Trespass',
    label: 'Trespass',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Scarpa',
    label: 'Scarpa',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Fjallraven',
    label: 'Fjallraven',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Merrell',
    label: 'Merrell',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Craghoppers',
    label: 'Craghoppers',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Vango',
    label: 'Vango',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Keen',
    label: 'Keen',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Dakine',
    label: 'Dakine',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Black Diamond',
    label: 'Black Diamond',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Petzl',
    label: 'Petzl',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  {
    option: 'Garmin',
    label: 'Garmin',
    parentSubCategory: ['outdoor-sports', 'team-sports', 'fitness'],
  },
  { option: 'Mothercare', label: 'Mothercare', parentSubCategory: ['baby-clothing'] },
  { option: 'Next', label: 'Next', parentSubCategory: ['baby-clothing'] },
  { option: 'Mamas & Papas', label: 'Mamas & Papas', parentSubCategory: ['baby-clothing'] },
  { option: 'Gap', label: 'Gap', parentSubCategory: ['baby-clothing'] },
  { option: 'H&M', label: 'H&M', parentSubCategory: ['baby-clothing'] },
  { option: 'George (Asda)', label: 'George (Asda)', parentSubCategory: ['baby-clothing'] },
  { option: 'F&F (Tesco)', label: 'F&F (Tesco)', parentSubCategory: ['baby-clothing'] },
  { option: 'John Lewis', label: 'John Lewis', parentSubCategory: ['baby-clothing'] },
  { option: 'Marks & Spencer', label: 'Marks & Spencer', parentSubCategory: ['baby-clothing'] },
  { option: 'Zara', label: 'Zara', parentSubCategory: ['baby-clothing'] },
  { option: 'Primark', label: 'Primark', parentSubCategory: ['baby-clothing'] },
  { option: "Carter's", label: "Carter's", parentSubCategory: ['baby-clothing'] },
  { option: 'Baby Boden', label: 'Baby Boden', parentSubCategory: ['baby-clothing'] },
  { option: 'JoJo Maman Bébé', label: 'JoJo Maman Bébé', parentSubCategory: ['baby-clothing'] },
  { option: 'Ralph Lauren', label: 'Ralph Lauren', parentSubCategory: ['baby-clothing'] },
  { option: 'Adidas', label: 'Adidas', parentSubCategory: ['baby-clothing'] },
  { option: 'Nike', label: 'Nike', parentSubCategory: ['baby-clothing'] },
  { option: 'Puma', label: 'Puma', parentSubCategory: ['baby-clothing'] },
  { option: 'Converse', label: 'Converse', parentSubCategory: ['baby-clothing'] },
  { option: 'Disney', label: 'Disney', parentSubCategory: ['baby-clothing'] },
];

//Phone Accessories ,Accessories,Consoles,Games,Replacement Parts & Tools,Other
