import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconCollection from '../IconCollection/IconCollection';
import css from './OrderPanel.module.css';

export default function TokensAndProtectionCard(props) {
  const { icon, title, description } = props;
  return (
    <div className={css.tokensAndProtectionCard}>
      <div>
        <IconCollection icon={icon} />
      </div>
      <div className={css.tokensAndProtectionContent}>
        <FormattedMessage id={title} />
        <FormattedMessage id={description.id} values={description.values} />
      </div>
    </div>
  );
}
