import React, { useEffect, useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { FREE_PLAN_PRICE_LIMIT, MEMBER_PLAN_PRICE_LIMIT, SUBSCRIPTION_MEMBER_PLAN, propTypes } from '../../../util/types';
import { required } from '../../../util/validators'

import {
  AvatarSmall,
  Form,
  InlineTextButton,
  Modal,
  PrimaryButton,
} from '../../../components';


import css from './AuctionOrderForm.module.css';
import IconCollection from '../../IconCollection/IconCollection';
import { checkCountLimit, checkPriceLimit } from '../../../util/dataExtractor';
import { useSelector } from 'react-redux';
import { ensureUser } from '../../../util/data';
import { postGetBids } from '../../../util/api';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

// Browsers can't render huge number of select options.
// (stock is shown inside select element)
// Note: input element could allow ordering bigger quantities
const MAX_QUANTITY_FOR_DROPDOWN = 100;

const handleFetchLineItems = ({
  quantity,
  deliveryMethod,
  displayDeliveryMethod,
  listingId,
  isOwnListing,
  fetchLineItemsInProgress,
  onFetchTransactionLineItems,
}) => {
  const stockReservationQuantity = Number.parseInt(quantity, 10);
  const deliveryMethodMaybe = deliveryMethod ? { deliveryMethod } : {};
  const isBrowser = typeof window !== 'undefined';
  if (
    isBrowser &&
    stockReservationQuantity &&
    (!displayDeliveryMethod || deliveryMethod) &&
    !fetchLineItemsInProgress
  ) {
    onFetchTransactionLineItems({
      orderData: { stockReservationQuantity, ...deliveryMethodMaybe },
      listingId,
      isOwnListing,
    });
  }
};


const renderForm = formRenderProps => {
  const [mounted, setMounted] = useState(false);
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    currentStock,
    displayDeliveryMethod,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    price,
    values,
    listing,
    makeABidForm,
    onManageDisableScrolling,
  } = formRenderProps;

  const {
    listingsSoldPrice,
    listingsSoldCount,
    currentPlanData,
    freePlanData,
    businessListingUnavailable,
  } = (!!listing?.author?.id && listing?.author.attributes.profile.publicData) || {};

  const { auctionExpiry } = listing?.attributes?.publicData || {}
  const [totalBids, setTotalBids] = useState([])
  const [viewBids, setViewBids] = useState(false)
  const isExceedPriceLimit = checkPriceLimit(listingsSoldPrice, freePlanData, currentPlanData);
  const isExceedCountLimit = checkCountLimit(listingsSoldCount, freePlanData, currentPlanData);
  const totalLimit = freePlanData?.isFreePlanActive ? FREE_PLAN_PRICE_LIMIT : currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN ? MEMBER_PLAN_PRICE_LIMIT : null;
  const isFreePlanActive = freePlanData?.isFreePlanActive;
  const isSubscriptionMember = currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN;
  const remainingLimit = totalLimit - listingsSoldPrice;
  const itemPrice = price?.amount / 100;
  // Note: don't add custom logic before useEffect
  useEffect(() => {
    setMounted(true);

    // Side-effect: fetch line-items after mounting if possible
    const { quantity, deliveryMethod } = values;
    if (quantity && !formRenderProps.hasMultipleDeliveryMethods) {
      handleFetchLineItems({
        quantity,
        deliveryMethod,
        displayDeliveryMethod,
        listingId,
        isOwnListing,
        fetchLineItemsInProgress,
        onFetchTransactionLineItems,
      });
    }
  }, []);

  const getBidsByListingId = async (listingId) => {
    try {
      const bids = await postGetBids({ listingId })
      setTotalBids(bids.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (listingId) {
      getBidsByListingId(listingId.uuid)
    }
  }, [listingId])

  const { currentUser } = useSelector(state => state.user)
  const ensuredUser = ensureUser(currentUser)
  const { rewardsWallet: points = 0 } = ensuredUser?.attributes?.profile?.publicData ?? {}



  // If form values change, update line-items for the order breakdown
  const handleOnChange = formValues => {
    const { quantity, deliveryMethod } = formValues.values;
    if (mounted) {
      handleFetchLineItems({
        quantity,
        deliveryMethod,
        listingId,
        isOwnListing,
        fetchLineItemsInProgress,
        onFetchTransactionLineItems,
      });
    }
  };
  const biddingExpired = new Date(auctionExpiry) < new Date()

  const handleFormSubmit = e => {
    if (!biddingExpired) {
      handleSubmit(e);
    } else {
      e.preventDefault()
    }
  };


  // Listing is out of stock if currentStock is zero.
  // Undefined/null stock means that stock has never been set.
  const hasStock = currentStock && currentStock > 0;


  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = !hasStock;

  return (
    <>
      <div className={css.mobileOnly}>
        {makeABidForm}
      </div>
      <div className={css.desktopOnly}>
        <Form onSubmit={handleFormSubmit}>
          <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
          <div className={css.submitButton}>
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={
                submitDisabled ||
                isExceedPriceLimit ||
                isExceedCountLimit ||
                (!currentPlanData?.isActive && !freePlanData?.isFreePlanActive) ||
                businessListingUnavailable || (isFreePlanActive || isSubscriptionMember) && totalLimit && remainingLimit < itemPrice
                || biddingExpired
              }
            >
              {hasStock && !biddingExpired ? (
                <FormattedMessage id="AuctionOrderForm.ctaButton" />
              ) : (
                <FormattedMessage id="AuctionOrderForm.ctaButtonNoStock" />
              )}
            </PrimaryButton>
          </div>
          <span className={css.auctionExpiry}>
            <FormattedMessage id="AuctionOrderForm.auctionExpiry" values={{ auctionExpiry: new Date(auctionExpiry).toLocaleString() }} />
          </span>
          <span className={css.bidCount} onClick={() => totalBids.length > 0 && setViewBids(true)}>
            <FormattedMessage id="AuctionOrderForm.bidCount" values={{ bidCount: totalBids.length }} />
          </span>
        </Form>
        <Modal
          isOpen={viewBids}
          onClose={() => setViewBids(false)}
          onManageDisableScrolling={onManageDisableScrolling}
        //  className={css.reportUserModal}
        >
          {totalBids.map(bid => {
            return bid?.bidAmount?.amount && <div className={css.bidCard}>
              <AvatarSmall user={bid?.customer} disableProfileLink />
              <div className={css.bidInfo}>
                <span>{bid.customer?.attributes.profile.displayName}</span>
                <span className={css.bidAmount}>{formatMoney(intl, new Money(bid?.bidAmount.amount, bid?.bidAmount.currency))}</span>
              </div>
            </div>
          })}
        </Modal>
      </div>
    </>

  );
};

const AuctionOrderForm = props => {
  const intl = useIntl();
  const {
    price,
    currentStock,
    pickupEnabled,
    shippingEnabled,
    displayDeliveryMethod,
    allowOrdersOfMultipleItems,
    listing,
    makeABidForm,
  } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (displayDeliveryMethod && !pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="AuctionOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  const hasOneItemLeft = currentStock && currentStock === 1;
  const hasOneItemMode = !allowOrdersOfMultipleItems && currentStock > 0;
  const quantityMaybe = hasOneItemLeft || hasOneItemMode ? { quantity: '1' } : {};
  const deliveryMethodMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
        ? { deliveryMethod: 'pickup' }
        : !shippingEnabled && !pickupEnabled
          ? { deliveryMethod: 'none' }
          : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = { ...quantityMaybe, ...deliveryMethodMaybe };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      displayDeliveryMethod={displayDeliveryMethod}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

AuctionOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  pickupEnabled: false,
  shippingEnabled: false,
  displayDeliveryMethod: false,
  lineItems: null,
  fetchLineItemsError: null,
};

AuctionOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,
  pickupEnabled: bool,
  shippingEnabled: bool,
  displayDeliveryMethod: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default AuctionOrderForm;
