import React, { useEffect, useRef, useState } from 'react';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import css from './AlogoliaSearchCard.module.css';
import IconCollection from '../IconCollection/IconCollection';
import NamedLink from '../NamedLink/NamedLink';
import { checkCountLimit, checkPriceLimit, getLabel } from '../../util/dataExtractor';
import { listingColors, listingConditions } from '../../config/configListing';
import { listingBrands } from '../../config/configBrand';
import { listingFieldTypes } from '../../config/configTypes';
import Overlay from '../../containers/ManageListingsPage/ManageListingCard/Overlay';
import { fetchAuthorData, fetchListing } from '../../containers/AlgoliaSearchPage/AlgoliaSearchPage.duck';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { showListing } from '../../containers/ListingPage/ListingPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import { FREE_PLAN_PRICE_LIMIT, MEMBER_PLAN_PRICE_LIMIT, SUBSCRIPTION_MEMBER_PLAN } from '../../util/types';

const AlogoliaSearchCard = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const config = useConfiguration();
  const debounceTimer = useRef(null);
  const [authorData, setAuthorData] = useState(null);

  const { hit, currentUser, onUpdateProfile, routeConfiguration, location } = props;
  const { title, listingType, listingImagesUrl, price, currentBid, condition, brand, type, color, objectID, size, user } =
    hit || {};

  const { listingsSoldPrice, listingsSoldCount, currentPlanData, freePlanData } =
    (!!authorData?.id && authorData.attributes.profile.publicData) || {};
  const { wishListItems = [] } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
  const likeIndex = wishListItems && wishListItems.findIndex(e => e == objectID);
  const isOwnListing = !!currentUser?.id && user?.userId === currentUser?.id?.uuid;
  const isAuction = listingType == "auction-product"
  const bidPriceMaybe = isAuction && currentBid ? currentBid : price

  useEffect(() => {
    if (user?.userId) {
      // Clear any existing timer
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      // Set a new timer
      debounceTimer.current = setTimeout(() => {
        dispatch(fetchAuthorData({ userId: user.userId }))
          .then(res => {
            setAuthorData(res);
          })
          .catch(err => {
            console.error('Failed to fetch author data', err);
          });
      }, 500); // Adjust the delay as necessary
    }

    // Cleanup timer on unmount
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [user?.userId, dispatch]);

  const handleFavouriteItems = async () => {
    if (isOwnListing) {
      return null;
    }
    if (!currentUser?.id) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };
      history.push(createResourceLocatorString('SignupPage', routeConfiguration, {}, {}), state);
    } else {
      if (likeIndex > -1) {
        wishListItems.splice(likeIndex, 1);
      } else {
        wishListItems.push(objectID);
      }
      const profile = {
        protectedData: {
          wishListItems,
        },
      };
      await onUpdateProfile(profile);
    }
  };

  const isExceedPriceLimit = checkPriceLimit(listingsSoldPrice, freePlanData, currentPlanData);
  const isExceedCountLimit = checkCountLimit(listingsSoldCount, freePlanData, currentPlanData);
  const totalLimit = freePlanData?.isFreePlanActive ? FREE_PLAN_PRICE_LIMIT : currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN ? MEMBER_PLAN_PRICE_LIMIT : null;

  const isFreePlanActive = freePlanData?.isFreePlanActive;
  const isSubscriptionMember = currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN;
  const remainingLimit = totalLimit - listingsSoldPrice;
  const itemPrice = price?.amount / 100;


  const handleSubmit = async values => {
    const { price } = values;
    // const listing = await dispatch(fetchListing({ listingId: objectID }));
    const listingResponse = await dispatch(showListing(objectID, config));
    const listing = listingResponse[0];
    // const listing =await sdk.listings.show({id: objectID})
    const initialValues = {
      listing: listing,
      orderData: {
        price,
        deliveryMethod: 'shipping',
      },
      confirmPaymentError: null,
    };
    const saveToSessionStorage = !currentUser;

    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routeConfiguration);

    dispatch(setInitialValues(initialValues, saveToSessionStorage));
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routeConfiguration,
        { id: listing?.id?.uuid, slug: createSlug(listing?.attributes?.title) },
        {}
      )
    );
  };

  return (
    <div className={css.root}>
      <NamedLink
        name="ListingPage"
        params={{ id: objectID, slug: createSlug(title) }}
        className={css.link}
        isSearchPage={true}
      >
        <div>
          {listingImagesUrl?.length > 0 ? (
            <div className={css.imageWrapper}>
              {isOwnListing ? <p className={css.onproduct}>Own Product</p> : null}
              <img src={listingImagesUrl[0]?.url} alt="listing-img" />
            </div>
          ) : (
            <div className={css.imageWrapper}>
              <IconCollection icon={'NoImage'} />{' '}
            </div>
          )}
          <p className={css.title}>{title}</p>
        </div>
      </NamedLink>
      {/* {loginRequriedModal} */}
      {!isOwnListing && (
        <div
          onClick={e => handleFavouriteItems(e)} // Invoke handleFavouriteItems when likeIcon is clicked
          className={css.likeIcon}
        >
          {likeIndex == -1 ? (
            <span>
              <IconCollection icon="likeIcon" />
            </span>
          ) : (
            <span>
              <IconCollection icon="disLikeIcon" />
            </span>
          )}
        </div>
      )}
      <div className={css.category}>
        <span>{condition && getLabel(listingConditions, condition)}</span>{' '}
        <span>{color && getLabel(listingColors, color)}</span>{' '}
        <span>
          {brand ? getLabel(listingBrands, brand) : type ? getLabel(listingFieldTypes, type) : null}
        </span>
      </div>
      <div className={css.emptySpace}></div>
      <div className={css.cardFooter}>
        <div className={css.priceWrapper}>
          <p>£{price?.amount / 100}.00</p>
          {/* {size && (
            <span>
              <FormattedMessage id="ListingCard.size" values={{ size: size?.label ?? size }} />
            </span>
          )} */}
        </div>
        {((!currentPlanData?.isActive && !freePlanData?.isFreePlanActive) ||
          ((isExceedPriceLimit || isExceedCountLimit) &&
            (currentPlanData?.isActive || freePlanData?.isFreePlanActive))) || ((isFreePlanActive || isSubscriptionMember) && totalLimit && remainingLimit < itemPrice) ? <NamedLink className={css.linkbuyNow} name="ListingPage"
              params={{ id: objectID, slug: createSlug(title) }}>
          <button
            className={css.linkbuyNow}
          >
            <FormattedMessage id="ListingCard.viewOnly" />
          </button>
        </NamedLink> : <NamedLink
          name="ListingPage"
          params={{ id: objectID, slug: createSlug(title) }}
          className={css.linkbuyNow}
           isSearchPage={true}
        ><button
          className={css.linkbuyNow}
          onClick={(e) => {
            if (!isAuction) {
              e.preventDefault();
              handleSubmit({ price });
            }
          }}
        >
            <FormattedMessage id={isAuction ? "ListingCard.bidNow" : "ListingCard.buyNow"} />
          </button>
        </NamedLink>}
      </div>
    </div>
  );
};

export default AlogoliaSearchCard;
