export const FacebookShareButton = ({ url, quote }) => {
	const handleClick = () => {
		window.open(
			`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
				url
			)}&quote=${encodeURIComponent(quote)}`,
			'facebook-share-dialog',

		);
	};

	return (<button onClick={handleClick} style={{ border: "none", cursor: "pointer" }}>
		<svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M63 31.5789C63 14.1474 48.888 0 31.5 0C14.112 0 0 14.1474 0 31.5789C0 46.8632 10.836 59.5895 25.2 62.5263V41.0526H18.9V31.5789H25.2V23.6842C25.2 17.5895 30.1455 12.6316 36.225 12.6316H44.1V22.1053H37.8C36.0675 22.1053 34.65 23.5263 34.65 25.2632V31.5789H44.1V41.0526H34.65V63C50.5575 61.4211 63 47.9684 63 31.5789Z" fill="black" />
		</svg>
	</button>);
};

export const EmailShare = ({ url }) => {
	const handleEmailShare = () => {
		const subject = 'Check out this awesome website!';
		const body = `Hey! I found this interesting website: ${url}. Thought you might like it!`;

		// Construct the mailto link
		const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

		// Open the default email client with the mailto link
		window.location.href = mailtoUrl;
	}

	return (
		<div>
			<button onClick={handleEmailShare} style={{ border: "none", cursor: "pointer" }}>
				<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M31.9268 7.19336H6.59342C4.85176 7.19336 3.44259 8.61836 3.44259 10.36L3.42676 29.36C3.42676 31.1017 4.85176 32.5267 6.59342 32.5267H31.9268C33.6684 32.5267 35.0934 31.1017 35.0934 29.36V10.36C35.0934 8.61836 33.6684 7.19336 31.9268 7.19336ZM31.2934 13.9225L20.0993 20.9209C19.5926 21.2375 18.9276 21.2375 18.4209 20.9209L7.22676 13.9225C7.06799 13.8334 6.92896 13.713 6.81808 13.5686C6.7072 13.4242 6.62677 13.2588 6.58167 13.0824C6.53656 12.906 6.52772 12.7223 6.55567 12.5423C6.58362 12.3624 6.64779 12.1901 6.74429 12.0357C6.84078 11.8813 6.9676 11.7481 7.11707 11.6441C7.26654 11.5401 7.43555 11.4676 7.61388 11.4308C7.7922 11.3941 7.97612 11.3939 8.15452 11.4303C8.33292 11.4667 8.50208 11.5389 8.65176 11.6425L19.2601 18.2767L29.8684 11.6425C30.0181 11.5389 30.1873 11.4667 30.3657 11.4303C30.5441 11.3939 30.728 11.3941 30.9063 11.4308C31.0846 11.4676 31.2536 11.5401 31.4031 11.6441C31.5526 11.7481 31.6794 11.8813 31.7759 12.0357C31.8724 12.1901 31.9366 12.3624 31.9645 12.5423C31.9925 12.7223 31.9836 12.906 31.9385 13.0824C31.8934 13.2588 31.813 13.4242 31.7021 13.5686C31.5912 13.713 31.4522 13.8334 31.2934 13.9225Z" fill="white" />
				</svg>
			</button>
		</div>
	);
};

export const TwitterShareButton = ({ url, text }) => {
	const handleClick = () => {
		window.open(
			`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
			'twitter-share-dialog',
			'width=800,height=600'
		);
	};

	return (
		<button onClick={handleClick} style={{ border: 'none', cursor: 'pointer' }}>
			<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_2072_4458)">
					<path d="M29.6295 6.9885C29.7592 6.84017 29.8584 6.66775 29.9215 6.48107C29.9846 6.29439 30.0103 6.09712 29.9971 5.90052C29.984 5.70391 29.9322 5.51183 29.8448 5.33522C29.7574 5.15862 29.6361 5.00096 29.4878 4.87125C29.3394 4.74154 29.167 4.64231 28.9803 4.57923C28.7936 4.51616 28.5964 4.49047 28.3998 4.50363C28.2032 4.51679 28.0111 4.56855 27.8345 4.65595C27.6579 4.74335 27.5002 4.86467 27.3705 5.013L19.7055 13.773L13.2 5.1C13.0603 4.91371 12.8791 4.7625 12.6708 4.65836C12.4625 4.55422 12.2329 4.5 12 4.5H6C5.72143 4.5 5.44837 4.57757 5.2114 4.72402C4.97444 4.87048 4.78294 5.08002 4.65836 5.32918C4.53378 5.57834 4.48104 5.85727 4.50606 6.13471C4.53108 6.41215 4.63286 6.67715 4.8 6.9L14.4555 19.773L6.3705 29.013C6.24079 29.1613 6.14156 29.3338 6.07848 29.5204C6.01541 29.7071 5.98972 29.9044 6.00288 30.101C6.01604 30.2976 6.0678 30.4897 6.1552 30.6663C6.2426 30.8429 6.36392 31.0005 6.51225 31.1302C6.66058 31.26 6.833 31.3592 7.01968 31.4223C7.20636 31.4853 7.40363 31.511 7.60023 31.4979C7.79684 31.4847 7.98892 31.433 8.16553 31.3456C8.34213 31.2582 8.49979 31.1368 8.6295 30.9885L16.2945 22.227L22.8 30.9C22.9397 31.0863 23.1209 31.2375 23.3292 31.3416C23.5375 31.4458 23.7671 31.5 24 31.5H30C30.2786 31.5 30.5516 31.4224 30.7886 31.276C31.0256 31.1295 31.2171 30.92 31.3416 30.6708C31.4662 30.4217 31.519 30.1427 31.4939 29.8653C31.4689 29.5878 31.3671 29.3229 31.2 29.1L21.5445 16.227L29.6295 6.9885Z" fill="white" />
				</g>
				<defs>
					<clipPath id="clip0_2072_4458">
						<rect width="36" height="36" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</button>
	)
}